import { createContext, Dispatch, SetStateAction } from "react";
import { MixedWorkoutDetail } from "../../../../api/trainer/schedules/schedulesTypes";
import { WorkoutParts } from "../../../../api/common/commonEnumType";
import { WorkoutCategoryItemlDTO } from "../../../../api/trainer/workouts/workoutsTypes";

interface WorkoutSelectContext {
  editedWorkoutList?: MixedWorkoutDetail[];
  setEditedWorkoutList: Dispatch<SetStateAction<MixedWorkoutDetail[]>>;
  workout?: WorkoutCategoryItemlDTO;
  handleCheckboxChange?: (
    workout: WorkoutCategoryItemlDTO,
    isChecked: boolean,
  ) => void;
}

export default createContext<WorkoutSelectContext>({
  editedWorkoutList: undefined,
  setEditedWorkoutList: () => {},
  workout: undefined,
  handleCheckboxChange: undefined,
});
