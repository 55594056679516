import React, { Suspense } from "react";
import { css, Global } from "@emotion/react";
import { globalStyles } from "./resource/global";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { TraineeApp } from "./trainee/TraineeApp";
import { TrainerApp } from "./trainer/TrainerApp";
import { CrmHome } from "./crm/CrmHome";
import ReactQueryClientProvider from "./provider/ReactQueryClientProvider";
import GlobalErrorBoundary from "./GlobalErrorBoundary";
import { Auth } from "./Auth";
import Loading from "design-system/components/Loading/Loading";
import { ReportListPage } from "./crm/report/list/Page";
import { ReportDetailPage } from "./crm/report/Page";
import { CrmReportWritePage } from "./crm/report/create/Page";
import { ErrorPage } from "./ErrorPage/ErrorPage";
import useResponsiveRedirect from "./styles/mediaQueries";
import { useAmplitude } from "./hoock/useAmplitude";
import { FitsyouAdminReportPage } from "./crm/fitsyou-admin/report/Page";
import { FitsyouAdminTraineePage } from "./crm/fitsyou-admin/trainee/Page";
import { FitsyouAdminTrainerPage } from "./crm/fitsyou-admin/trainer/Page";
import { FitsyouAdminPage } from "./crm/fitsyou-admin/Page";
import { AdminReportDetailPage } from "./crm/fitsyou-admin/report/detail/Page";
import { UserManagePage } from "./crm/user-manage/Page";
import { CreateTicketPage } from "./crm/user-manage/create/Page";
import { CreateGuestTicket } from "./crm/user-manage/create/guest/Page";
import { OnboardingPage } from "./onboarding/Page";
import { ReportDirectWritePage } from "./crm/report/create/direct-create/Page";
import { SchedulePage } from "crm/schedule/Page";
import { WorkoutListPage } from "./crm/workout-list/Page";
import { AdminTrainerDetailPage } from "./crm/fitsyou-admin/trainer/detail/Page";
import { TrainerSearchListPage } from "trainer/list/Page";
import { TrainerDetailPage } from "trainer/[trainerId]/Page";
import { TrainerMangePage } from "./trainer/manage/Page";

function AppRoutes() {
  useResponsiveRedirect();
  const amplitude = useAmplitude();
  amplitude.init();

  return (
    <GlobalErrorBoundary>
      <Suspense fallback={<Loading />}>
        <Routes>
          {/*핏츠유 어드민 */}
          <Route path="crm/fitsyou-admin" element={<FitsyouAdminPage />} />
          <Route
            path="crm/fitsyou-admin/report"
            element={<FitsyouAdminReportPage />}
          />
          <Route
            path="crm/fitsyou-admin/trainee"
            element={<FitsyouAdminTraineePage />}
          />
          <Route
            path="crm/fitsyou-admin/trainer"
            element={<FitsyouAdminTrainerPage />}
          />
          <Route
            path="crm/fitsyou-admin/report/detail"
            element={<AdminReportDetailPage />}
          />
          <Route
            path="crm/fitsyou-admin/trainer/detail"
            element={<AdminTrainerDetailPage />}
          />
          {/*일반 CRM*/}
          <Route path="/" element={<TrainerApp />}></Route>
          <Route
            path="/trainer/list"
            element={<TrainerSearchListPage />}
          ></Route>
          <Route path="/trainer/manage" element={<TrainerMangePage />}></Route>
          <Route
            path="/trainer/:trainerId"
            element={<TrainerDetailPage />}
          ></Route>
          <Route path="/trainer/*" element={<TrainerApp />}></Route>
          <Route path="/trainee/*" element={<TraineeApp />}></Route>
          <Route path="/crm/*" element={<CrmHome />}></Route>
          <Route path="/auth/*" element={<Auth />}></Route>
          <Route path="*" element={<TraineeApp />}></Route>
          <Route path="crm/report/list" element={<ReportListPage />} />
          <Route path="crm/report" element={<ReportDetailPage />} />
          <Route path="error" element={<ErrorPage />} />
          <Route path="crm/report/create" element={<CrmReportWritePage />} />
          <Route path="crm/user-manage" element={<UserManagePage />} />
          <Route path="crm/user-manage/create" element={<CreateTicketPage />} />
          <Route
            path="crm/user-manage/create/guest"
            element={<CreateGuestTicket />}
          />
          <Route path="crm/schedule" element={<SchedulePage />} />
          <Route path="onboarding" element={<OnboardingPage />} />
          <Route
            path="crm/report/create/direct-create"
            element={<ReportDirectWritePage />}
          />
          <Route path="crm/workout-list" element={<WorkoutListPage />} />
          {/*<Route path="test" element={<TestPage />} />*/}
        </Routes>
      </Suspense>
    </GlobalErrorBoundary>
  );
}

function App() {
  return (
    <div
      className="App"
      css={css`
        height: auto;
        min-height: 100vh;
      `}
    >
      <Global styles={globalStyles} />
      <ReactQueryClientProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ReactQueryClientProvider>
    </div>
  );
}

export default App;
