import { createContext, Dispatch, SetStateAction } from "react";
import { TicketTicketInfoDTO } from "../../../../api/trainer/schedules/schedulesTypes";

interface LessonListDialogContext {
  userId?: number;
  isDialogOpen: boolean;
  setIsDialogOpen?: Dispatch<SetStateAction<boolean>>;
  ticket?: TicketTicketInfoDTO;
}

export default createContext<LessonListDialogContext>({
  userId: undefined,
  isDialogOpen: false,
  setIsDialogOpen: undefined,
  ticket: undefined,
});
