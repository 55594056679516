import { css } from "@emotion/react";

export const fontFamily = {
  pretendard: "Pretendard",
  suit: "SUIT",
  natomPro: "Natom Pro",
} as const;

export const fontWeight = {
  Heavy: 900,
  ExtraBold: 800,
  Bold: 700,
  SemiBold: 600,
  Medium: 500,
  Regular: 400,
} as const;

// TODO 새롭게 정의 필요
export const typography = {
  mobile: {
    heading1: css`
      font-weight: ${fontWeight["Bold"]};
      font-size: 24px;
      line-height: 28px;
    `,
    heading2: css`
      font-weight: ${fontWeight["Bold"]};
      font-size: 20px;
      line-height: 28px;
    `,
    heading3: css`
      font-weight: ${fontWeight["Bold"]};
      font-size: 18px;
      line-height: 25px;
    `,
    heading4: css`
      font-weight: ${fontWeight["Bold"]};
      font-size: 16px;
      line-height: 22px;
    `,

    body1: css`
      font-weight: ${fontWeight["SemiBold"]};
      font-size: 16px;
      line-height: 140%;
    `,

    body2: css`
      font-weight: ${fontWeight["Medium"]};
      font-size: 14px;
      line-height: 20px;
    `,
    body3: css`
      font-weight: ${fontWeight["Medium"]};
      font-size: 12px;
      line-height: 140%;
    `,
    body4: css`
      font-weight: ${fontWeight["Medium"]};
      font-size: 10px;
      line-height: 140%;
    `,

    button2: css`
      font-weight: ${fontWeight["Medium"]};
      font-size: 14px;
      line-height: 110%;
    `,
  },

  Display1: css`
    font-weight: ${fontWeight["Bold"]};
    font-size: 150px;
    line-height: 195px;
  `,
  Heading1: css`
    font-weight: ${fontWeight["Heavy"]};
    font-size: 80px;
    line-height: 104;
  `,
  Heading2: css`
    font-weight: ${fontWeight["ExtraBold"]};
    font-size: 60px;
    line-height: 84%;
  `,
  Body1: css`
    font-weight: ${fontWeight["SemiBold"]};
    font-size: 40px;
    line-height: 52px;
  `,
  Body2: css`
    font-weight: ${fontWeight["ExtraBold"]};
    font-size: 30px;
    line-height: 48px;
  `,
  Body3: css`
    font-weight: ${fontWeight["SemiBold"]};
    font-size: 25px;
    line-height: 180%;
  `,
  Body4: css`
    font-weight: ${fontWeight["SemiBold"]};
    font-size: 18px;
    line-height: 160%;
  `,

  Button1: css`
    font-weight: ${fontWeight["Bold"]};
    font-size: 16px;
    line-height: 140%;
  `,

  Button2: css`
    font-weight: ${fontWeight["SemiBold"]};
    font-size: 14px;
    line-height: 110%;
  `,
  Tab: css`
    font-weight: ${fontWeight["SemiBold"]};
    line-height: 150%;
    font-size: 18px;
    letter-spacing: -0.72px;
  `,

  //KLIP
  Caption_B: css`
    font-weight: ${fontWeight["Bold"]};
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.12px;
  `,
  Caption: css`
    font-weight: ${fontWeight["Medium"]};
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.12px;
  `,
  Caption_Underline: css`
    font-weight: ${fontWeight["Medium"]};
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.12px;
    text-decoration: underline;
  `,
} as const;
