import {
  DirectLessonWriteResult,
  GetLessonsListRequest,
  GetLessonsListResponse,
  PostLessonsWriteRequest,
} from "./lessonsTypes";
import { customFetch } from "../../../libs/fetch/fetch";

export async function getLessonList({
  filter,
}: GetLessonsListRequest): Promise<GetLessonsListResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/lessons/list`,
    params: { filter },
  });
}

export async function postLessonsWrite({
  ...request
}: PostLessonsWriteRequest): Promise<DirectLessonWriteResult> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/lessons/write`,
    },

    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...request }),
    },
  );
}

export async function putLessonsWrite({
  ...request
}: PostLessonsWriteRequest): Promise<DirectLessonWriteResult> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/lessons/write`,
    },

    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...request }),
    },
  );
}
