import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";
import { UserInfoDTO } from "../../../../api/trainer/users/usersTypes";
import { colors } from "design-system/styles/colors";
import maleImage from "design-system/components/atom/IconResource/man.png";
import femaleImage from "design-system/components/atom/IconResource/woman.png";
import { ticketStatusInKR } from "../../../../api/common/commonEnumType";
import { TicketTicketInfoDTO } from "../../../../api/trainer/schedules/schedulesTypes";
import Button from "design-system/components/Button/Button";
import React, { useState } from "react";
import LessonListDialogContext from "../LessonListDialog/lessonListDialogContext";
import { useNavigate } from "react-router";
import { LessonListDialog } from "../LessonListDialog/LessonListDialog";

interface UserInfoListItemProps {
  userInfo: UserInfoDTO;
  ticket: TicketTicketInfoDTO;
}

export function UserInfoListItem({ userInfo, ticket }: UserInfoListItemProps) {
  const [isLessonListDialogOpen, setIsLessonListDialogOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <LessonListDialogContext.Provider
      value={{
        userId: userInfo.userId,
        isDialogOpen: isLessonListDialogOpen,
        setIsDialogOpen: setIsLessonListDialogOpen,
        ticket: ticket,
      }}
    >
      <div css={itemContainerCSS}>
        <div css={upperContainerCSS}>
          <div css={upperRightContainerCSS}>
            <div css={nameTextCSS}>{userInfo.name}</div>
            <img
              src={userInfo.gender == "male" ? maleImage : femaleImage}
              css={css`
                height: 19px;
                width: 19px;
              `}
            />
            {userInfo.birthdate && (
              <div css={birthTextCSS}>
                {userInfo.birthdate.replaceAll("-", ".")}
              </div>
            )}
          </div>
          <div css={upperRightContainerCSS}>
            <div css={countTagCSS}>
              {ticket?.usedCount}/{ticket?.totalCount}회
            </div>
            {ticket && (
              <div css={statusTagCSS}>{ticketStatusInKR(ticket?.status)}</div>
            )}
          </div>
        </div>
        <div css={bottomContainerCSS}>
          {bottomItem({ title: "연락처", content: userInfo.phone })}
          {ticket &&
            ticket.startAt &&
            bottomItem({
              title: "기간",
              content: `${ticket.startAt} ~ ${ticket?.endAt || "종료기간 없음"}`,
            })}
          {ticket &&
            ticket.centerName &&
            bottomItem({ title: "센터명", content: ticket.centerName })}
          {ticket &&
            (!!ticket.price || ticket.price === 0) &&
            bottomItem({
              title: "결제액",
              content: `${ticket.price.toLocaleString()}원`,
            })}
          {ticket &&
            ticket.code &&
            bottomItem({
              title: "수강코드",
              content: ticket.code,
            })}
          {ticket &&
            ticket.otherInfo &&
            bottomItem({
              title: "기타내용",
              content: ticket.otherInfo,
            })}
        </div>
        <div css={buttonContainerCSS}>
          <Button
            css={buttonCSS}
            onClick={() =>
              navigate("/crm/report/create/direct-create", {
                state: {
                  userId: userInfo.userId,
                  ticketId: ticket.id,
                },
              })
            }
          >
            일지 쓰기
          </Button>
          <Button
            css={buttonCSS}
            onClick={() => {
              setIsLessonListDialogOpen?.(true);
            }}
          >
            수업 기록
          </Button>
        </div>
        <LessonListDialog />
      </div>
    </LessonListDialogContext.Provider>
  );
}

const buttonCSS = css`
  border-radius: 4px;
  border: 1px solid ${colors.gray100};
  width: 100%;
  background: white;
  height: 40px;
  ${typography.mobile.button2};
  color: ${colors.gray800};
`;

const buttonContainerCSS = css`
  margin-top: 10px;
  display: flex;
  gap: 20px;
`;

interface bottomItemProps {
  title: string;
  content: string;
}

const bottomItem = ({ title, content }: bottomItemProps) => {
  return (
    <div css={infoContentContainerCSS}>
      <div css={infoContentTitleCSS}>{title}</div>
      <div css={infoContentContentCSS}>{content}</div>
    </div>
  );
};

const countTagCSS = css`
  padding: 4px 6px;
  ${typography.mobile.body2};
  background: ${colors.gray50};
`;

const statusTagCSS = css`
  padding: 4px 6px;
  ${typography.mobile.body2}
  border-radius: 2px;
  border: 1px solid ${colors.gray50};
`;

const bottomContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
`;

const infoContentContainerCSS = css`
  display: flex;
  gap: 10px;
  align-items: flex-start;
`;

const infoContentTitleCSS = css`
  ${typography.mobile.body3};
  color: ${colors.gray600};
  width: 42px;
`;

const infoContentContentCSS = css`
  ${typography.mobile.body2};
  display: flex;
  flex: 1;
`;

const birthTextCSS = css`
  ${typography.mobile.body2};
  color: ${colors.gray600};
`;

const nameTextCSS = css`
  ${typography.mobile.heading4}
`;

const itemContainerCSS = css`
  padding: 16px 12px;
  border-radius: 8px;
  background: ${colors.gray25};
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
`;

const upperContainerCSS = css`
  display: flex;
  justify-content: space-between;
`;

const upperRightContainerCSS = css`
  display: flex;
  gap: 8px;
  align-items: center;
`;
