import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import Button from "design-system/components/Button/Button";
import exerciseIcon from "design-system/components/atom/IconResource/ic_exercise.svg";
import calendarIcon from "design-system/components/atom/IconResource/ic_calender.svg";
import reportIcon from "design-system/components/atom/IconResource/ic_list.svg";
import memberManageIcon from "design-system/components/atom/IconResource/ic_membership_manegement.svg";
import { typography } from "design-system/styles/typography/typography";
import { useContext, useEffect } from "react";
import SidebarContext from "./sidebarContext";
import { ReportWrittenType } from "../../../api/common/commonEnumType";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { getMe } from "../../../api/trainer/me/me";
import { queryKeys } from "../../../libs/react-query";

export type SidebarState =
  | ReportWrittenType
  | "all"
  | "none"
  | "manage"
  | "schedule"
  | "workout-list";

export function Sidebar() {
  const { sidebarState, setSidebarState } = useContext(SidebarContext);

  const navigate = useNavigate();

  const { data } = useQuery({
    queryFn: () => getMe(),
    queryKey: queryKeys.getMe(),
  });

  useEffect(() => {
    if (data && data?.isVerified !== true) {
      navigate("/onboarding");
    }
  }, [data]);

  return (
    <div css={containerCSS}>
      <div css={sidebarContainerCSS}>
        <SidebarItem iconSrc={reportIcon} title={"PT 일지"} />
        <SidebarSubItem
          title={`전체`}
          isSelected={sidebarState == "all"}
          onClick={() => {
            if (sidebarState === "none") {
              navigate(`/crm/report/list?state=all`);
            } else {
              setSidebarState("all");
            }
          }}
        />
        <SidebarSubItem
          title={`미작성`}
          isSelected={sidebarState == "not_written"}
          onClick={() => {
            if (sidebarState === "none") {
              navigate(`/crm/report/list?state=not_written`);
            } else {
              setSidebarState("not_written");
            }
          }}
        />
        <SidebarSubItem
          title={`작성중`}
          isSelected={sidebarState == "writing"}
          onClick={() => {
            if (sidebarState === "none") {
              navigate(`/crm/report/list?state=writing`);
            }
            setSidebarState("writing");
          }}
        />
        <SidebarSubItem
          title={`작성완료`}
          isSelected={sidebarState == "completed"}
          onClick={() => {
            if (sidebarState === "none") {
              navigate(`/crm/report/list?state=completed`);
              return;
            }
            setSidebarState("completed");
          }}
        />
        <SidebarItem
          iconSrc={memberManageIcon}
          title={"회원관리"}
          onClick={() => {
            setSidebarState("manage");
            navigate(`/crm/user-manage`);
          }}
        />
        <SidebarItem
          iconSrc={calendarIcon}
          title={"스케줄"}
          onClick={() => {
            setSidebarState("schedule");
            navigate(`/crm/schedule`);
          }}
        />
        <SidebarItem
          iconSrc={exerciseIcon}
          title={"운동법"}
          onClick={() => {
            setSidebarState("workout-list");
            navigate(`/crm/workout-list`);
          }}
        />
      </div>
      <div css={footerContainerCSS}>
        <a
          css={footerGuidTextCSS}
          href="https://fitsyou.notion.site/a556e709df3042f2bbd0f46f8ab122a1"
          target="_blank"
          rel="noopener noreferrer"
        >
          핏츠유 가이드
        </a>
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div css={footerSubTextCSS}>프시케랩</div>
          <div css={footerSubTextCSS}>070-7954-6956</div>
          <div css={footerSubTextCSS}>contact@fitsyou.co.kr</div>
        </div>
      </div>
    </div>
  );
}

interface SidebarItemProps {
  iconSrc: string;
  title: string;
  onClick?: () => void;
}

const containerCSS = css`
  width: 250px;
  min-width: 230px;
  border-right: 1px solid ${colors.Black_a10};
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
`;

const itemTextCSS = css`
  ${typography.Button2};
  height: 100%;
  display: flex;
  text-align: start;
  align-items: center; /* 세로 가운데 정렬 */
  line-height: normal;
`;

const itemContainerCSS = css`
  padding: 16px 0;
  align-items: flex-start;
  display: flex;
  gap: 4px;
  background: none;
  width: 100%;
`;
const buttonCSS = (isSelected: boolean) => css`
  border: none;
  background: ${isSelected ? colors.gray50 : "transparent"};
  color: ${isSelected ? colors.gray800 : colors.gray500};
  padding: 8px 24px;
  width: 100%;
  justify-content: flex-start;
  border-radius: 4px;
`;

const footerContainerCSS = css`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  height: 200px;
  gap: 25px;
`;

const footerGuidTextCSS = css`
  ${typography.mobile.heading3};
  color: ${colors.gray700};
`;
const footerSubTextCSS = css`
  ${typography.mobile.body2};
  color: ${colors.gray400};
`;

const sidebarContainerCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* 요소들을 상단으로 */
  align-items: flex-start; /* 가로 축도 상단 정렬 */
`;

interface SidebarSubItemProps {
  title: string;
  isSelected: boolean;
  onClick: () => void;
}

function SidebarItem({ iconSrc, title, onClick }: SidebarItemProps) {
  return (
    <div
      css={[
        itemContainerCSS,
        css`
          cursor: ${onClick ? "pointer" : "default"};
        `,
      ]}
      onClick={onClick}
    >
      <img src={iconSrc} alt={"icon"} css={itemCSS} />
      <div css={itemTextCSS}>{title}</div>
    </div>
  );
}

function SidebarSubItem({ title, isSelected, onClick }: SidebarSubItemProps) {
  return (
    <Button variant={"tertiary"} css={buttonCSS(isSelected)} onClick={onClick}>
      <div>
        <span css={itemTextCSS}>{title}</span>
      </div>
    </Button>
  );
}

const itemCSS = css`
  width: 24px;
  height: 24px;
`;
