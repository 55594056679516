import { useContext } from "react";
import {
  workoutEquipments,
  WorkoutEquipments,
  workoutEquipmentsInKR,
  workoutParts,
  WorkoutParts,
  workoutPartsInKR,
} from "api/common/commonEnumType";

import { Textarea } from "design-system/components/Textarea/Textarea";
import * as RadioGroup from "design-system/components/RadioGroup/RadioGroup";
import Button from "design-system/components/Button/Button";

import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import searchIcon from "design-system/components/atom/IconResource/ic_search.svg";
import bookmarkIcon from "design-system/components/atom/IconResource/ic_bookmark_vari_lime.svg";
import workoutListContext from "crm/workout-list/workoutListContext";

export function WorkoutSearchFilter() {
  const {
    workoutName,
    setWorkoutName,
    isBookmarked,
    setIsBookmarked,
    isDescriptionRequired,
    setIsDescriptionRequired,
    isCustom,
    setIsCustom,
    workoutPart,
    setWorkoutPart,
    workoutEquipment,
    setWorkoutEquipment,
  } = useContext(workoutListContext);

  return (
    <div css={searchContainerCSS}>
      <div css={searchBarContainerCSS}>
        <img src={searchIcon} css={searchIconCSS} />
        <Textarea
          placeholder={"찾고자 하는 운동을 검색해주세요"}
          onChange={(event) => {
            setWorkoutName(event.target.value);
          }}
          value={workoutName}
          isAutoHeight={false}
          rows={1}
          isPreventNewline
        />
      </div>
      <div css={searchFilterContainerCSS}>
        <div css={searchFilterItemContainerCSS}>
          <div css={searchFilterItemTitleCSS}>{"분류"}</div>
          <div css={searchFilterItemListCSS}>
            <Button
              variant="secondary"
              styles={searchFilterItemCSS}
              data-state={isBookmarked ? "checked" : ""}
              onClick={() => setIsBookmarked(!isBookmarked)}
            >
              <img src={bookmarkIcon} css={bookmarkIconCSS} />
            </Button>
            <Button
              variant="secondary"
              styles={searchFilterItemCSS}
              data-state={isDescriptionRequired ? "checked" : ""}
              onClick={() => setIsDescriptionRequired(!isDescriptionRequired)}
            >
              {"운동법 미작성"}
            </Button>
            <Button
              variant="secondary"
              styles={searchFilterItemCSS}
              data-state={isCustom ? "checked" : ""}
              onClick={() => setIsCustom(!isCustom)}
            >
              {"커스텀"}
            </Button>
          </div>
        </div>
        <div css={searchFilterItemContainerCSS}>
          <div css={searchFilterItemTitleCSS}>{"부위"}</div>
          <RadioGroup.Root
            value={workoutPart}
            onValueChange={(part: WorkoutParts | "total") =>
              setWorkoutPart(part)
            }
            styles={searchFilterItemListCSS}
          >
            <RadioGroup.Chips value="total">{"전체"}</RadioGroup.Chips>
            {workoutParts
              .filter((workoutPart) => workoutPart !== "custom")
              .map((item) => (
                <RadioGroup.Chips value={item} key={item}>
                  {workoutPartsInKR(item)}
                </RadioGroup.Chips>
              ))}
          </RadioGroup.Root>
        </div>
        <div css={searchFilterItemContainerCSS}>
          <div css={searchFilterItemTitleCSS}>{"기구"}</div>
          <RadioGroup.Root
            value={workoutEquipment}
            onValueChange={(part: WorkoutEquipments | "total") =>
              setWorkoutEquipment(part)
            }
            styles={searchFilterItemListCSS}
          >
            <RadioGroup.Chips value="total">{"전체"}</RadioGroup.Chips>
            {workoutEquipments.map((item) => (
              <RadioGroup.Chips value={item} key={item}>
                {workoutEquipmentsInKR(item)}
              </RadioGroup.Chips>
            ))}
          </RadioGroup.Root>
        </div>
        <hr css={separatorCSS} />
      </div>
    </div>
  );
}

const searchContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const searchBarContainerCSS = css`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const searchIconCSS = css`
  width: 24px;
  height: 24px;
`;

const searchFilterContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 7px;
  color: ${colors.gray700};
  ${typography.mobile.body1};
`;

const searchFilterItemContainerCSS = css`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const searchFilterItemTitleCSS = css`
  flex-shrink: 0;
`;

const searchFilterItemListCSS = css`
  display: flex;
  gap: 8px;
  overflow-x: auto;
`;

const searchFilterItemCSS = css`
  flex-shrink: 0;
  border-radius: 100px;
  padding: 4px 12px;
  ${typography.mobile.body2};

  &[data-state="checked"] {
    background: ${colors.black};
    color: ${colors.gray25};
  }
`;

const bookmarkIconCSS = css`
  width: 20px;
  height: 20px;
`;

const separatorCSS = css`
  width: 100%;
  border: 1px solid ${colors.gray100};
`;
