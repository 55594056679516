import { customFetch } from "../../../libs/fetch/fetch";
import { GetListRequest, GetListResponse } from "./listTypes";

export async function getList({
  address,
}: GetListRequest): Promise<GetListResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/list`,
    params: { address },
  });
}
