import { CrmHeader } from "../../common/Header/CrmHeader";
import { Sidebar } from "../component/Sidebar/Sidebar";
import { useEffect, useMemo, useState } from "react";
import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { ScehduleTitle } from "./component/ScehduleTitle/ScehduleTitle";
import { ScheduleWeekSelector } from "./component/ScheduleWeekSelector/ScheduleWeekSelector";
import { TimeLine } from "./component/TimeLine/TimeLine";
import { CreateSidebar } from "./component/CreateSidebar/CreateSidebar";
import ScheduleContext, { SidebarState } from "./scheduleContext";
import { ScheduleType } from "api/common/commonEnumType";
import { ReadSidebar } from "./component/ReadSidebar/ReadSidebar";
import { UpdateSidebars } from "./component/UpdateSidebars/UpdateSidebars";

export function SchedulePage() {
  const today = new Date();
  const [scheduleType, setScheduleType] = useState<ScheduleType>("ETC");
  const [sidebarState, setSidebarState] = useState<SidebarState>();
  const [selectedDate, setSelectedDate] = useState<Date | null>(today);
  const [selectedWeek, setSelectedWeek] = useState<Date[] | null>([]);
  const [scheduleSlotId, setscheduleSlotId] = useState<number | undefined>();

  useEffect(() => {
    const tempDay = selectedDate?.getDay() ?? today.getDay();
    setSelectedWeek(
      Array.from({ length: 7 }, (_, i) => {
        const tempDate = new Date(selectedDate ?? today);
        tempDate.setDate(
          (selectedDate?.getDate() ?? today.getDate()) + i - tempDay,
        );
        return tempDate;
      }),
    );
  }, [selectedDate]);

  return (
    <div css={containerCSS}>
      <ScheduleContext.Provider
        value={{
          scheduleType,
          setScheduleType,
          sidebarState,
          setSidebarState,
          selectedDate,
          setSelectedDate,
          selectedWeek,
          setSelectedWeek,
          scheduleSlotId,
          setscheduleSlotId,
        }}
      >
        <CrmHeader />
        <div css={contentsContainerCSS}>
          <Sidebar />
          <div css={contentContainerCSS}>
            <div css={titleContainerCSS}>
              <ScehduleTitle />
              <ScheduleWeekSelector />
            </div>
            <TimeLine />
          </div>
          {sidebarState === "create" && (
            <CreateSidebar date={selectedDate ?? today} />
          )}
          {sidebarState === "read" && <ReadSidebar />}
          {sidebarState === "update" && <UpdateSidebars />}
        </div>
      </ScheduleContext.Provider>
    </div>
  );
}

const containerCSS = css`
  background: ${colors.gray25};
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;

const contentsContainerCSS = css`
  display: flex;
  width: 100%;
  min-height: 100vh;
`;

const contentContainerCSS = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
  /* position: relative; */
  /* align-items: end; */
`;

const titleContainerCSS = css`
  display: flex;
  flex-direction: column;
  padding: 20px 60px 10px;
  gap: 40px;
`;
