import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import calendarIcon from "design-system/components/atom/IconResource/ic_calender.svg";
import leftArrowIcon from "design-system/components/atom/IconResource/ic_arrow_left.svg";
import rightArrowIcon from "design-system/components/atom/IconResource/ic_arrow_right.svg";

import { useContext, useMemo, useState } from "react";
import scheduleContext from "crm/schedule/scheduleContext";
import { FitsYouDatePicker } from "design-system/components/FitsYouDatePicker/FitsYouDatePicker";
import { isFirstDayOfMonth, lastDayOfMonth } from "date-fns";

export function ScheduleWeekSelector() {
  const today = new Date();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { selectedDate, setSelectedDate, selectedWeek } =
    useContext(scheduleContext);

  const selectedWeekNumber = useMemo(() => {
    const tempDate = new Date(selectedDate ?? today);
    const firstDay = new Date(tempDate.setDate(1)).getDay();

    return Math.ceil(
      ((selectedDate?.getDate() ?? today.getDate()) + firstDay) / 7,
    );
  }, [selectedDate]);

  const weekDay = [
    "일요일",
    "월요일",
    "화요일",
    "수요일",
    "목요일",
    "금요일",
    "토요일",
  ];

  // TODO: 월 이동 후 날짜 선택시 해당 월로 이동하도록 @sello

  return (
    <div css={containerCSS}>
      <div css={selectorContainerCSS}>
        <button
          css={weekButtonCSS}
          onClick={() => {
            if (
              selectedWeek?.some((date) => date.getDate() === 1) &&
              (selectedDate?.getDate() ?? 0) <= 7
            ) {
              selectedDate?.setMonth(selectedDate.getMonth() - 1);
              selectedDate?.setDate(lastDayOfMonth(selectedDate).getDate());
              const tempDate = new Date(selectedDate ?? today);
              setSelectedDate(tempDate);
            } else {
              selectedDate?.setDate(selectedDate.getDate() - 7);
              const tempDate = new Date(selectedDate ?? today);
              setSelectedDate(tempDate);
            }
          }}
        >
          <img src={leftArrowIcon} alt={"left arrow"} css={iconCSS} />
          <span>저번주</span>
        </button>
        <FitsYouDatePicker
          isCalendarOpen={isCalendarOpen}
          setIsCalendarOpen={setIsCalendarOpen}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          onChange={setSelectedDate}
          customInput={
            <button css={monthButtonCSS}>
              <span>{`${(selectedDate?.getMonth() ?? 0) + 1}월 ${selectedWeekNumber}주차`}</span>
              <img src={calendarIcon} alt={"calendar"} css={monthIconCSS} />
            </button>
          }
        />
        <button
          css={weekButtonCSS}
          onClick={() => {
            if (
              selectedWeek?.some((date) => date.getDate() === 1) &&
              (selectedDate?.getDate() ?? 0) > 7
            ) {
              selectedDate?.setMonth(selectedDate.getMonth() + 1);
              selectedDate?.setDate(1);
              const tempDate = new Date(selectedDate ?? today);
              setSelectedDate(tempDate);
            } else {
              selectedDate?.setDate(selectedDate.getDate() + 7);
              const tempDate = new Date(selectedDate ?? today);
              setSelectedDate(tempDate);
            }
          }}
        >
          <span>다음주</span>
          <img src={rightArrowIcon} alt={"right arrow"} css={iconCSS} />
        </button>
      </div>
      <div css={weekContainerCSS}>
        {selectedWeek?.map((date, i) => {
          return (
            <div key={i} css={dayContainerCSS}>
              {date.getMonth() === selectedDate?.getMonth() ? (
                <>
                  <div>{weekDay[i]}</div>
                  <div css={dateCSS}>{`${date.getDate()}일`}</div>
                </>
              ) : (
                <div css={dateCSS} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const selectorContainerCSS = css`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const monthButtonCSS = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  ${typography.Tab};
  gap: 8px;
`;

const monthIconCSS = css`
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  color: ${colors.black};
`;

const weekButtonCSS = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  ${typography.mobile.body3};
  color: ${colors.gray600};
  gap: 4px;
`;

const iconCSS = css`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: ${colors.black};
`;

const weekContainerCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  //overflow-x: scroll;
  gap: 2px;
`;

const dayContainerCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  ${typography.mobile.body1};
  color: ${colors.gray900};
  min-width: 124px;
  width: 100%;
`;

const dateCSS = css`
  ${typography.mobile.body3};
  color: ${colors.gray400};
`;
