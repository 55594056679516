import React from "react";
import * as DataTable from "design-system/components/DataTable/DataTable";
import { useQuery } from "@tanstack/react-query";
import { getTrainers } from "../../../api/admin/admin";
import { useNavigate } from "react-router";
import { queryKeys } from "../../../libs/react-query";

export function TrainerList() {
  const navigate = useNavigate();

  const { data: trainerList } = useQuery({
    queryFn: () => getTrainers(),
    queryKey: queryKeys.getTrainers(),
  });

  return (
    <div>
      <DataTable.Root>
        <DataTable.Table
          onRowClick={(original) => {
            navigate(`/crm/fitsyou-admin/trainer/detail?id=${original.id}`);
          }}
          data={trainerList?.content.filter((trainer) => !!trainer.phone) || []}
          columns={[
            {
              cell: ({ row }) => row.original.id,
              header: "ID",
              size: 120,
            },
            {
              cell: ({ row }) => row.original.name,
              header: "이름",
              size: 150,
            },
            {
              cell: ({ row }) => row.original.email,
              header: "이메일",
              size: 150,
            },
            {
              cell: ({ row }) => row.original.phone,
              header: "휴대전화",
              size: 170,
            },
            {
              cell: ({ row }) => row.original.yearsOfExperience,
              header: "경력",
              size: 150,
            },
            {
              cell: ({ row }) => row.original.birthdate,
              header: "생년월일",
              size: 150,
            },
          ]}
        ></DataTable.Table>
      </DataTable.Root>
    </div>
  );
}
