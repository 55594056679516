import { CrmHeader } from "../../common/Header/CrmHeader";
import { Sidebar } from "../component/Sidebar/Sidebar";
import { useState } from "react";
import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";

import { typography } from "design-system/styles/typography/typography";
import Button from "design-system/components/Button/Button";
import {
  CrmContentHorizontalPadding,
  CrmContentVerticalPadding,
} from "../component/crmCSS";
import { AddCustomWorkoutDialog } from "../report/create/component/AddCustomWorkoutDialog/AddCustomWorkoutDialog";
import { WorkoutDetailSidebar } from "./component/WorkoutDetailSidbar/WorkoutDetailSidbar";
import { WorkoutSearchFilter } from "./component/WorkoutSearchFilter/WorkoutSearchFilter";
import { WorkoutEquipments, WorkoutParts } from "api/common/commonEnumType";
import WorkoutListContext from "./workoutListContext";
import { WorkoutList } from "./component/WorkoutList/WorkoutList";
import { WorkoutRequestDialog } from "./component/WorkoutRequestDialog/WorkoutRequestDialog";

export function WorkoutListPage() {
  const [workoutId, setWorkoutId] = useState<number>();
  const [userId, setUserId] = useState<number>();
  const [workoutName, setWorkoutName] = useState<string>();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isDescriptionRequired, setIsDescriptionRequired] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [workoutPart, setWorkoutPart] = useState<WorkoutParts | "total">(
    "total",
  );
  const [workoutEquipment, setWorkoutEquipment] = useState<
    WorkoutEquipments | "total"
  >("total");

  return (
    <WorkoutListContext.Provider
      value={{
        workoutId,
        setWorkoutId,
        userId,
        setUserId,
        workoutName,
        setWorkoutName,
        isBookmarked,
        setIsBookmarked,
        isDescriptionRequired,
        setIsDescriptionRequired,
        isCustom,
        setIsCustom,
        workoutPart,
        setWorkoutPart,
        workoutEquipment,
        setWorkoutEquipment,
      }}
    >
      <div css={containerCSS}>
        <CrmHeader />
        <div css={contentsContainerCSS}>
          <Sidebar />
          <div css={contentContainerCSS}>
            <div css={titleContainerCSS}>
              <div css={titleTextCSS}>운동법</div>
              <div css={addWorkoutButtonContainerCSS}>
                <AddCustomWorkoutDialog
                  trigger={
                    <Button variant="outline" size={46}>
                      커스텀 운동 추가
                    </Button>
                  }
                />

                <WorkoutRequestDialog />
              </div>
            </div>
            <WorkoutSearchFilter />
            <WorkoutList />
          </div>
          <WorkoutDetailSidebar />
        </div>
      </div>
    </WorkoutListContext.Provider>
  );
}

const addWorkoutButtonContainerCSS = css`
  display: flex;
  gap: 8px;
`;

const contentContainerCSS = css`
  padding: ${CrmContentVerticalPadding}px ${CrmContentHorizontalPadding}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
  position: relative;
  gap: 30px;
`;

const titleContainerCSS = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const titleTextCSS = css`
  ${typography.mobile.heading1}
`;

const containerCSS = css`
  background: ${colors.gray25};
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;

const contentsContainerCSS = css`
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 100vh;
`;
