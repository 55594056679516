import React, { Suspense } from "react";
import { CrmHeader } from "../../../common/Header/CrmHeader";
import Loading from "design-system/components/Loading/Loading";
import { CrmTitle } from "../../component/CrmTitle";
import { css } from "@emotion/react";
import {
  CrmContentHorizontalPadding,
  CrmContentVerticalPadding,
} from "../../component/crmCSS";
import { colors } from "design-system/styles/colors";
import { AdminReportListContent } from "../component/AdminReportListContent";

export function FitsyouAdminReportPage() {
  return (
    <div css={containerCSS} key={"report"}>
      <CrmHeader />
      <div css={contentsContainerCSS}>
        <Suspense fallback={<Loading />}>
          <div css={contentInnerContainerCSS}>
            <CrmTitle title={"일지 리스트"} />
            <AdminReportListContent />
          </div>
        </Suspense>
      </div>
    </div>
  );
}

const contentInnerContainerCSS = css`
  padding: ${CrmContentVerticalPadding}px ${CrmContentHorizontalPadding}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const contentsContainerCSS = css`
  display: flex;
  width: 100%;
  min-height: 100vh;
`;

const containerCSS = css`
  background: ${colors.gray25};
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;
