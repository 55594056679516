import { useState } from "react";
import DirectCreateContext from "./component/directCreateContext";
import { useLocation, useNavigate } from "react-router";
import CreateContext from "../createContext";
import {
  EventName,
  ScreenName,
  useAmplitude,
} from "../../../../hoock/useAmplitude";
import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import {
  MixedWorkoutDetail,
  RequestSaveLessonRequestDTO,
} from "../../../../api/trainer/schedules/schedulesTypes";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getUsersUserIdLessonsLessonId } from "../../../../api/trainer/users/users";
import { queryClient, queryKeys } from "../../../../libs/react-query";
import { useSearchParams } from "react-router-dom";
import { ScheduleAddPopup } from "./component/ScheduleAddPopup";
import { CrmHeader } from "../../../../common/Header/CrmHeader";
import Loading from "design-system/components/Loading/Loading";
import { Sidebar } from "../../../component/Sidebar/Sidebar";
import { ReportWriteTemplate } from "../component/ReportWriteTemplete/ReportWriteTemplate";
import { ReportShareDialog } from "../../component/ReportShareDialog/ReportShareDialog";
import {
  postLessonsWrite,
  putLessonsWrite,
} from "../../../../api/trainer/lessons/lessons";
import { getReportResponse } from "../component/useWriteReport";
import { SendErrorDialog } from "../component/SendErrorDialog/SendErrorDialog";
import {
  AttachFileDTO,
  PostUploadsPresignedRequest,
} from "../../../../api/common/commonTypes";
import { postUploadsPresigned } from "../../../../api/common/common";
import { customFetch } from "../../../../libs/fetch/fetch";
import { extractVideoThumbnailFile } from "../../../../hoock/useUrlToFile";
import { format } from "date-fns";

/**
 * TODO(JUN):스케줄 관련 부분 겹칠 예정이라 추후 작업 예정
 */
export function ReportDirectWritePage() {
  const loc = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { userId: userIdInitial, ticketId: ticketIdInitial } = loc.state || {};
  const { sendClickEvent } = useAmplitude();
  const [isProcessing, setIsProcessing] = useState(false);
  /**
   * 스케줄 지정 관련 부분
   */
  const [isScheduleDialogOpen, setIsScheduleDialogOpen] = useState(true);
  const [userId, setUserId] = useState<number | undefined>(
    userIdInitial ?? undefined,
  );
  const [ticketId, setTicketId] = useState<number | undefined>(
    ticketIdInitial ?? undefined,
  );
  const [startAt, setStartAt] = useState<Date | undefined>(undefined);
  const [endAt, setEndAt] = useState<Date | undefined>(undefined);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  /**
   * 일지 작성 관련 부분
   */
  const [lessonId, setLessonId] = useState(
    parseInt(searchParams.get("lessonId") ?? "0", 10),
  );
  const [reportData, setReportData] = useState<
    RequestSaveLessonRequestDTO & {
      mixedWorkoutDetail?: MixedWorkoutDetail[];
    }
  >({
    userId: userId ?? 0,
    ticketId: ticketId ?? 0,
    lessonResultType: "feedback_workout_set_weight",
    workoutTime: 50,
    totalFeedback: "",
    mixedWorkoutDetail: [],
    workoutDetails: [],
  });

  // const [editedWorkoutList, setEditedWorkoutList] = useState<
  //   MixedWorkoutDetail[]
  // >(reportData?.mixedWorkoutDetail || []);

  /**
   * 일지 작성 관련 에러 부분
   */
  const [isNoSetError, setIsNoSetError] = useState(false);
  const [isNoWorkoutError, setIsNoWorkoutError] = useState(false);
  const [isRequireDataMissingError, setIsRequireDataMissingError] =
    useState(false);
  const [isFutureReportError, setIsFutureReportError] = useState(false);
  const [scheduleSlotId, setScheduleSlotId] = useState<number | undefined>(
    undefined,
  );

  const { data: lessonData } = useQuery({
    queryFn: () =>
      getUsersUserIdLessonsLessonId({
        lessonId,
        userId: userId ?? 0,
      }),
    queryKey: queryKeys.getUsersUserIdLessonsLessonId({
      lessonId,
      userId: userId ?? 0,
    }),
    enabled: lessonId !== 0,
  });

  const sendLog = (eventName: EventName) => {
    sendClickEvent({
      eventName: eventName,
      screenName: ScreenName.web_pt_report_write,
      eventProperties: {
        feedback_type: reportData.lessonResultType,
        image_feedback_count:
          reportData.mixedWorkoutDetail?.reduce(
            (acc, item) => {
              const imageCount = item?.imagesDetail?.length ?? 0;
              return (parseInt(acc) + imageCount).toString();
            },
            "0", // 초기값을 문자열로 설정
          ) || "0",
        video_feedback_count:
          reportData.mixedWorkoutDetail?.reduce(
            (acc, item) => {
              const videoCount = item?.videosDetail?.length ?? 0;
              return (parseInt(acc) + videoCount).toString();
            },
            "0", // 초기값을 문자열로 설정
          ) || "0",
        is_prefer_feedback_matched:
          reportData.lessonResultType == lessonData?.lessonResultType
            ? "true"
            : "false",
      },
    });
  };

  const { mutateAsync: preSigned } = useMutation<
    AttachFileDTO,
    Error,
    PostUploadsPresignedRequest
  >({
    mutationFn: postUploadsPresigned,
  });

  const resourceUpload = async (
    reportData: RequestSaveLessonRequestDTO & {
      mixedWorkoutDetail?: MixedWorkoutDetail[];
    },
  ) => {
    for (const workoutsDetail of reportData.mixedWorkoutDetail || []) {
      for (const image of workoutsDetail.imagesDetail) {
        if (!image.id.includes("placeholder-")) continue;

        try {
          const response: AttachFileDTO = await preSigned({
            type: "user_workout_image",
            contentType: "png",
          });

          await customFetch(
            {
              app: "common",
              version: "v1",
              path: `/uploads/presigned`,
            },
            {
              method: "PUT",
              headers: {
                "Content-Type": "image/png",
              },
              body: image.file, // 파일 객체를 body로 직접 전달
            },
            response.url,
          );

          image.id = response.id;
        } catch (error) {
          console.error("Pre-signed URL 요청 실패:", error);
        }
      }

      for (const video of workoutsDetail.videosDetail) {
        if (!video.id.includes("placeholder-")) continue;

        try {
          const response = await preSigned({
            type: "user_workout_video",
            contentType: video.file?.type.includes("mp4") ? "mp4" : "mov",
            thumbnailContentType: "png",
          });

          await fetch(response.url, {
            method: "PUT",
            headers: {
              "Content-Type": video.file?.type ?? "video/mp4",
            },
            body: video.file, // 파일 객체를 body로 직접 전달
          });

          if (response.thumbnail && video.file) {
            const thumbnailFile = await extractVideoThumbnailFile(video.file);
            await fetch(response.thumbnail, {
              method: "PUT",
              headers: {
                "Content-Type": "image/png",
              },
              body: thumbnailFile, // 파일 객체를 body로 직접 전달
            });
          }
          video.id = response.id;
        } catch (error) {
          console.error("Pre-signed URL 요청 실패:", error);
        }
      }
    }
  };
  const checkError = () => {
    if (
      reportData.totalFeedback?.length == 0 ||
      reportData.totalFeedback == undefined ||
      reportData.workoutTime == undefined
    ) {
      setIsRequireDataMissingError(true);
      return true;
    }

    if (reportData.lessonResultType !== "feedback_only") {
      if (
        !reportData.mixedWorkoutDetail ||
        reportData.mixedWorkoutDetail?.length == 0
      ) {
        setIsNoWorkoutError(true);
        return true;
      }
    }

    if (reportData.lessonResultType == "feedback_workout_set_weight") {
      const hasEmptySets = reportData.mixedWorkoutDetail?.some(
        (detail) => detail.sets.length === 0,
      );

      if (hasEmptySets) {
        setIsNoSetError(true);
        return true;
      }
    }
    if (startAt && startAt.getTime() > new Date().getTime()) {
      setIsFutureReportError(true);
      return true;
    }

    return false;
  };

  const { mutate: sendReportMutate } = useMutation({
    mutationFn: postLessonsWrite,
    onSuccess: async ({ lessonId: responseLessonId, scheduleSlotId }) => {
      queryClient.invalidateQueries(queryKeys.getLessonsList({}));
      sendLog(EventName.web_send_pt_diary_successed);
      // `lessonData`를 다시 가져오기
      setLessonId(responseLessonId);
      setScheduleSlotId(scheduleSlotId);
      setIsShareDialogOpen(true);
    },
  });

  const { mutate: saveTempReportMutate } = useMutation({
    mutationFn: putLessonsWrite,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getLessonsList({}));
      queryClient.invalidateQueries(
        queryKeys.getUsersUserIdLessonsLessonId({
          userId: userId ?? 0,
          lessonId,
        }),
      );
      navigate("/crm");
    },
  });

  const saveTempReport = async () => {
    sendLog(EventName.web_temporary_save_pt_diary_button_clicked);
    setIsProcessing(true);
    await resourceUpload(reportData);
    saveTempReportMutate({
      lesson: getReportResponse(reportData),
      schedule: {
        startAt: `${format(startAt ?? "", "yyyy-MM-dd'T'HH:mm:ss")}`,
        endAt: `${format(endAt ?? "", "yyyy-MM-dd'T'HH:mm:ss")}`,
        scheduleType: "PT",
        userId,
        ticketId,
      },
    });
    setIsProcessing(false);
  };

  const sendReport = async () => {
    sendLog(EventName.web_send_pt_diary_button_clicked);
    if (checkError()) {
      return;
    }
    setIsProcessing(true);
    await resourceUpload(reportData);
    sendReportMutate({
      lesson: getReportResponse(reportData),
      schedule: {
        startAt: `${format(startAt ?? "", "yyyy-MM-dd'T'HH:mm:ss")}`,
        endAt: `${format(endAt ?? "", "yyyy-MM-dd'T'HH:mm:ss")}`,
        scheduleType: "PT",
        userId,
        ticketId,
      },
    });
    setIsProcessing(false);
  };

  return (
    <DirectCreateContext.Provider
      value={{
        isScheduleDialogOpen,
        setIsScheduleDialogOpen,
        scheduleDataContextProps: {
          userId,
          setUserId,
          ticketId,
          setTicketId,
          startAt,
          setStartAt,
          endAt,
          setEndAt,
        },
      }}
    >
      <div>
        <CreateContext.Provider
          value={{
            data: reportData,
            setData: setReportData,
            saveTempRetort: saveTempReport,
            sendTempRetort: sendReport,
          }}
        >
          <ScheduleAddPopup />
          {isProcessing && <Loading />}
          {!isProcessing && (
            <div css={containerCSS}>
              <CrmHeader />
              <div css={contentsContainerCSS}>
                <Sidebar />
                <ReportWriteTemplate />
              </div>
              <SendErrorDialog
                isNoSetError={isNoSetError}
                setIsNoSetError={setIsNoSetError}
                isNoWorkoutError={isNoWorkoutError}
                setIsNoWorkoutError={setIsNoWorkoutError}
                isRequireDataMissingError={isRequireDataMissingError}
                setIsRequireDataMissingError={setIsRequireDataMissingError}
                isFutureReportError={isFutureReportError}
                setIsFutureReportError={setIsFutureReportError}
              />
              <ReportShareDialog
                isOpen={isShareDialogOpen}
                setIsOpen={(state) => {
                  setIsShareDialogOpen(state);
                }}
                lessonData={lessonData}
                userId={userId}
                scheduleSlotId={scheduleSlotId}
                screenName={ScreenName.web_pt_report_write}
                onClose={() => navigate("crm/")}
              />
            </div>
          )}
        </CreateContext.Provider>
      </div>
    </DirectCreateContext.Provider>
  );
}

const contentsContainerCSS = css`
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: auto;
`;

const containerCSS = css`
  background: ${colors.gray25};
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: auto;
`;
