import { useContext, useMemo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient, queryKeys } from "libs/react-query";
import {
  deleteWorkoutsWorkoutIdBookmarks,
  getWorkouts,
  postWorkoutsWorkoutIdBookmarks,
} from "api/trainer/workouts/workouts";
import { mapPartToKey } from "api/trainer/workouts/workoutsTypes";
import { workoutParts } from "api/common/commonEnumType";
import workoutListContext from "crm/workout-list/workoutListContext";

import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import customImage from "design-system/components/atom/IconResource/customImage.svg";
import bookmarkIcon from "design-system/components/atom/IconResource/ic_bookmark_vari.svg";
import bookmarkBlackIcon from "design-system/components/atom/IconResource/ic_bookmark_vari_black.svg";
import Button from "design-system/components/Button/Button";

export function WorkoutList() {
  const {
    setWorkoutId,
    workoutName,
    isBookmarked,
    isDescriptionRequired,
    isCustom,
    workoutPart,
    workoutEquipment,
  } = useContext(workoutListContext);

  const { data: workouts } = useQuery({
    queryFn: getWorkouts,
    queryKey: queryKeys.getWorkouts(),
  });

  const { mutate: addBookmark, isLoading: isAddBookmarkLoading } = useMutation({
    mutationFn: postWorkoutsWorkoutIdBookmarks,
    onSuccess: ({}) => {
      queryClient.invalidateQueries(queryKeys.getWorkouts());
    },
  });

  const { mutate: removeBookmark, isLoading: isRemoveBookmarkLoading } =
    useMutation({
      mutationFn: deleteWorkoutsWorkoutIdBookmarks,
      onSuccess: ({}) => {
        queryClient.invalidateQueries(queryKeys.getWorkouts());
      },
    });

  const filteredWorkouts = useMemo(
    () =>
      workoutPart === "total"
        ? isCustom
          ? workouts?.custom
          : workoutParts.flatMap(
              (workoutPart) => workouts?.[mapPartToKey(workoutPart)],
            )
        : [
            ...((!isCustom && workouts?.[mapPartToKey(workoutPart)]) || []),
            ...(workouts?.custom.filter(
              (workout) => workout.part === workoutPart,
            ) || []),
          ],
    [workoutPart, isCustom, workouts],
  );

  return (
    <div css={listContainerCSS}>
      {filteredWorkouts
        ?.filter(
          (filteredWorkout) =>
            (!isBookmarked || filteredWorkout?.isBookmarked) &&
            (!isDescriptionRequired ||
              !filteredWorkout?.isWorkoutDescriptionCreated) &&
            (workoutEquipment === "total" ||
              filteredWorkout?.equipment === workoutEquipment) &&
            (!workoutName ||
              filteredWorkout?.name
                .replace(/\s/g, "")
                .toLowerCase()
                .includes(workoutName.replace(/\s/g, "").toLowerCase())),
        )
        .map((item) => {
          return (
            <Button
              variant="text"
              key={item?.id}
              css={listItemContainerCSS}
              onClick={() => setWorkoutId(item?.id)}
            >
              {item &&
                (item.isBookmarked ? (
                  <Button
                    variant="icon"
                    onClick={(event) => {
                      event.stopPropagation();
                      removeBookmark(item.id);
                    }}
                    disabled={isRemoveBookmarkLoading}
                  >
                    <img src={bookmarkBlackIcon} css={bookmarkIconCSS} />
                  </Button>
                ) : (
                  <Button
                    variant="icon"
                    onClick={(event) => {
                      event.stopPropagation();
                      addBookmark(item.id);
                    }}
                    disabled={isAddBookmarkLoading}
                  >
                    <img src={bookmarkIcon} css={bookmarkIconCSS} />
                  </Button>
                ))}
              <img
                src={item?.thumbnail ?? customImage}
                css={thumnailImageCSS}
              />
              <div>{item?.name}</div>
            </Button>
          );
        })}
    </div>
  );
}

const listContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const listItemContainerCSS = css`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  color: ${colors.gray800};
  ${typography.mobile.body2};
`;

const bookmarkIconCSS = css`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;

const thumnailImageCSS = css`
  width: 80px;
  height: 80px;
`;
