import {
  DeleteSchedulesScheduleSlotIdFollowingRequest,
  DeleteSchedulesScheduleSlotIdRequest,
  GetSchedulesRequest,
  GetSchedulesResponse,
  GetSchedulesScheduleSlotIdRequest,
  GetSchedulesScheduleSlotIdResponse,
  GetSchedulesScheduleSlotIdUsersUserIdTicketRequest,
  GetSchedulesScheduleSlotIdUsersUserIdTicketResponse,
  PostSchedulesCheckRequest,
  PostSchedulesRequest,
  PostSchedulesScheduleSlotIdLessonsRequest,
  PostSchedulesScheduleSlotIdLessonsResponse,
  PostSchedulesScheduleSlotIdNoshowRequest,
  PutSchedulesScheduleSlotIdRequest,
} from "./schedulesTypes";
import { customFetch } from "../../../libs/fetch/fetch";

export async function getSchedules({
  year,
  month,
}: GetSchedulesRequest): Promise<GetSchedulesResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/schedules`,
    params: { year, month },
  });
}

export async function getSchedulesScheduleSlotId({
  scheduleSlotId,
}: GetSchedulesScheduleSlotIdRequest): Promise<GetSchedulesScheduleSlotIdResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/schedules/${scheduleSlotId}`,
  });
}

export async function postSchedules({
  ...request
}: PostSchedulesRequest): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/schedules`,
    },

    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...request }),
    },
  );
}

export async function putSchedulesScheduleSlotId({
  scheduleSlotId,
  ...request
}: PutSchedulesScheduleSlotIdRequest): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/schedules/${scheduleSlotId}`,
    },

    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...request }),
    },
  );
}

export async function deleteSchedulesScheduleSlotId({
  scheduleSlotId,
}: DeleteSchedulesScheduleSlotIdRequest): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/schedules/${scheduleSlotId}`,
    },
    { method: "DELETE" },
  );
}

export async function deleteSchedulesScheduleSlotIdFollowing({
  scheduleSlotId,
}: DeleteSchedulesScheduleSlotIdFollowingRequest): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/schedules/${scheduleSlotId}/following`,
    },
    { method: "DELETE" },
  );
}

export async function postSchedulesScheduleSlotIdNoshow({
  scheduleSlotId,
  ...request
}: PostSchedulesScheduleSlotIdNoshowRequest): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/schedules/${scheduleSlotId}/noshow`,
    },

    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...request }),
    },
  );
}

export async function postSchedulesScheduleSlotIdLessons({
  scheduleSlotId,
  ...request
}: PostSchedulesScheduleSlotIdLessonsRequest): Promise<PostSchedulesScheduleSlotIdLessonsResponse> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/schedules/${scheduleSlotId}/lessons`,
    },

    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...request }),
    },
  );
}

export async function putSchedulesScheduleSlotIdLessons({
  scheduleSlotId,
  ...request
}: PostSchedulesScheduleSlotIdLessonsRequest): Promise<PostSchedulesScheduleSlotIdLessonsResponse> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/schedules/${scheduleSlotId}/lessons`,
    },

    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...request }),
    },
  );
}

export async function getSchedulesScheduleSlotIdUsersUserIdTicket({
  scheduleSlotId,
  userId,
}: GetSchedulesScheduleSlotIdUsersUserIdTicketRequest): Promise<GetSchedulesScheduleSlotIdUsersUserIdTicketResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/schedules/${scheduleSlotId}/users/${userId}/ticket`,
  });
}

export async function postSchedulesCheck({
  ...request
}: PostSchedulesCheckRequest): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/schedules/check`,
    },

    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...request }),
    },
  );
}
