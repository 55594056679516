import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  workoutEquipments,
  WorkoutEquipments,
  workoutEquipmentsInKR,
  workoutParts,
  WorkoutParts,
  workoutPartsInKR,
} from "api/common/commonEnumType";
import { postWorkoutsRequest } from "api/trainer/workouts/workouts";

import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";

import Button from "design-system/components/Button/Button";
import { Textarea } from "design-system/components/Textarea/Textarea";
import * as Dialog from "design-system/components/Dialog/Dialog";
import * as Select from "design-system/components/Select/Select";
import { RedDot } from "design-system/components/RedDot/RedDot";

export function WorkoutRequestDialog() {
  const [workoutName, setWorkoutName] = useState<string>();
  const [workoutPart, setWorkoutPart] = useState<WorkoutParts>();
  const [equipment, setEquipment] = useState<WorkoutEquipments>();

  const { mutate: requestWorkout } = useMutation({
    mutationFn: postWorkoutsRequest,
  });

  const reset = () => {
    setWorkoutName(undefined);
    setWorkoutPart(undefined);
    setEquipment(undefined);
  };

  return (
    <Dialog.Root variant="page">
      <Dialog.Trigger asChild>
        <Button variant="outline" size={46}>
          운동 추가 요청하기
        </Button>
      </Dialog.Trigger>
      <Dialog.Content
        onAnimationEndCapture={() => {
          reset();
        }}
      >
        <Dialog.Header>
          <Dialog.Title>{"운동 추가 요청"}</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body styles={dialogBodyCSS}>
          <div css={requireInputTextCSS}>
            <RedDot />
            {"는 필수 입력칸입니다"}
          </div>
          <div css={contentContainerCSS}>
            <div>
              {"운동 이름"}
              <RedDot />
            </div>
            <Textarea
              placeholder="추가 요청할 운동 이름을 써주세요"
              value={workoutName}
              onChange={(e) => setWorkoutName(e.target.value)}
              isAutoHeight={false}
              rows={1}
              isPreventNewline
              styles={textareaCSS}
            />
          </div>
          <div css={contentRowContainerCSS}>
            <div css={contentContainerCSS}>
              <div>
                {"운동 부위"}
                <RedDot />
              </div>
              <Select.Root
                value={workoutPart}
                onValueChange={(part: WorkoutParts) => setWorkoutPart(part)}
              >
                <Select.Trigger>
                  <Select.Value placeholder="운동 부위를 선택해주세요" />
                </Select.Trigger>
                <Select.Content>
                  {workoutParts
                    .filter((workoutPart) => workoutPart !== "custom")
                    .map((item) => (
                      <Select.Item value={item} key={item}>
                        {workoutPartsInKR(item)}
                      </Select.Item>
                    ))}
                </Select.Content>
              </Select.Root>
            </div>
            <div css={contentContainerCSS}>
              <div>
                {"운동 기구"}
                <RedDot />
              </div>
              <Select.Root
                value={equipment}
                onValueChange={(part: WorkoutEquipments) => setEquipment(part)}
              >
                <Select.Trigger>
                  <Select.Value placeholder="운동 기구를 선택해주세요" />
                </Select.Trigger>
                <Select.Content>
                  {workoutEquipments.map((item) => (
                    <Select.Item value={item} key={item}>
                      {workoutEquipmentsInKR(item)}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </div>
          </div>
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.Close
            variant="primary"
            disabled={!workoutName || !workoutPart || !equipment}
            onClick={() =>
              workoutName &&
              workoutPart &&
              requestWorkout({ workoutName, workoutPart, equipment })
            }
          >
            {"요청하기"}
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
}

const dialogBodyCSS = css`
  padding-top: 16px;
  padding-bottom: 31px;
  gap: 16px;
  align-items: flex-start;
`;

const requireInputTextCSS = css`
  ${typography.mobile.body3}
`;

const contentContainerCSS = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  ${typography.mobile.body1};
`;

const contentRowContainerCSS = css`
  display: flex;
  width: 100%;
  gap: 16px;
`;

const textareaCSS = css`
  height: 46px;
  justify-content: center;
`;
