import { css } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import { MainHeader } from "../common/Header/MainHeader";
import { useSearchParams } from "react-router-dom";
import { customFetch } from "../libs/fetch/fetch";
import { EventName, ScreenName, useAmplitude } from "../hoock/useAmplitude";
import Button from "design-system/components/Button/Button";
import LoginDialogContext from "../common/login-dialog/loginDialogContext";
import { LoginDialog } from "../common/login-dialog/LoginDialog";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { getWidthMediaQuery } from "../styles/mediaQueries";
import { useNavigate } from "react-router";
import { HomeTrainerList } from "./component/HomeTrainerList";

export function TrainerApp() {
  const [searchParams] = useSearchParams();
  const state = searchParams.get("state");
  const hasSentLog = useRef(false);
  const { sendClickEvent, sendScreenViewEvent } = useAmplitude();

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasSentLog.current) {
      sendScreenViewEvent({
        eventName: EventName.web_home_main_screen_viewed,
        screenName: ScreenName.web_home_main,
      });
    }
  });

  useEffect(() => {
    if (state == "logout") {
      customFetch(
        {
          app: "user",
          version: "v1",
          path: `/logout`,
        },
        { method: "POST" },
      );
    }
  }, [state]);

  const onsStartWebButtonClick = () => {
    sendClickEvent({
      eventName: EventName.web_search_trainer_button_clicked,
      screenName: ScreenName.web_home_main,
    });
    navigate("/trainer/list");
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        background: black;
        padding-bottom: 0;
        width: 100%;
        overflow: hidden;
      `}
    >
      <MainHeader />
      <div css={containerCSS}>
        <div css={frame1containerCSS}>
          <div css={emphasisTitleCSS}>
            실패 없는 PT의 시작
            <br />
            핏츠유
          </div>
          <div css={webButtonContainerCSS}>
            <Button css={webButtonCSS} onClick={onsStartWebButtonClick}>
              트레이너 찾기
            </Button>
          </div>
          <LoginDialogContext.Provider value={{ isOpen, setIsOpen }}>
            <LoginDialog />
          </LoginDialogContext.Provider>
        </div>
        <HomeTrainerList />
      </div>
    </div>
  );
}

const webButtonContainerCSS = css`
  width: 100%;
  display: flex;
  justify-content: center;
  border: none;
`;

const webButtonCSS = css`
  border-radius: 2000px;
  padding: 16px 36px;
  background: ${colors.lime600};
  ${typography.mobile.heading2};
  color: black;
`;

const frame1containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  z-index: 2;
`;

const logoCSS = css`
  height: 22px;
  width: 22px;
`;

const buttonContainerCSS = css`
  display: flex;
  width: 100%;
  gap: 17px;
  justify-content: center;
  z-index: 2;
`;

const buttonCSS = css`
  display: flex;
  gap: 14px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  color: white;
  padding: 16px 24px;
  font-size: 12px;

  ${getWidthMediaQuery("pc")} {
    font-size: 16px;
  }
`;

const emphasisTitleCSS = css`
  background: linear-gradient(109deg, #83f9e9 -2.12%, #e0f569 111.08%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 77px;
  font-size: 45px;

  ${getWidthMediaQuery("pc")} {
    font-size: 100px;
    margin-top: 137px;
  }
`;

const BottomDecoContainerCSS = css`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const upperContentCSS = css`
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  height: 600px;
  width: 100%;
  overflow: hidden;

  ${getWidthMediaQuery("pc")} {
    width: 2800px;
    height: 1609px;
  }
`;

const containerCSS = css`
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
  background-color: black;
`;

const upperImageCSS = css`
  object-fit: cover;
  opacity: 0.3;
  width: 1000px;
  overflow: hidden;

  ${getWidthMediaQuery("pc")} {
    width: 2800px;
    height: 1609px;
  }

  z-index: 1;
`;
