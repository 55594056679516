import { css } from "@emotion/react";
import { useContext } from "react";
import LessonListDialogContext from "./lessonListDialogContext";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { ticketStatusInKR } from "../../../../api/common/commonEnumType";
import { useQuery } from "@tanstack/react-query";
import { DialogMobilePage } from "design-system/components/DialogMobilePage/DialogMobilePage";
import { getUsersUserIdTicketsTicketIdLessonsList } from "../../../../api/trainer/users/users";
import { LessonTrainerLessonSummaryDTO } from "../../../../api/trainer/lessons/lessonsTypes";
import { getKoreanWeekday } from "../../../../hoock/useDate";
import { useNavigate } from "react-router";
import Button from "design-system/components/Button/Button";
import IcArrow from "design-system/components/atom/IconResource/Icon/IcArrow";
import { ImageView } from "design-system/components/ImageView/ImageView";

export function LessonListDialog() {
  const { ticket, setIsDialogOpen, isDialogOpen, userId } = useContext(
    LessonListDialogContext,
  );

  const { data: lessonList } = useQuery({
    enabled: !!ticket && !!ticket?.id && !!userId,
    queryKey: ["getUsersUserIdTicketsTicketIdLessonsList", ticket?.id],
    queryFn: () => {
      if (!ticket || !userId) {
        return;
      }
      return getUsersUserIdTicketsTicketIdLessonsList({
        ticketId: ticket.id,
        userId: userId,
      });
    },
  });

  const LessonCard = ({ lesson }: LessonCardProps) => {
    const navigate = useNavigate();
    const dayString = getKoreanWeekday(lesson.info.startAt);
    const startDate = new Date(lesson.info.startAt);
    const endDate = new Date(lesson.info.endAt);
    const headerCSS = css`
      display: flex;
      justify-content: space-between;
    `;

    return (
      <>
        <div css={lessonCardCSS}>
          <div css={headerCSS}>
            <div css={tagCSS("black", "white")}>
              {lesson.traineeSignImageUrl ? "사인완료" : "사인미완료"}
            </div>
            {lesson.status == "completed" && (
              <Button
                css={reportDetailButtonCSS}
                onClick={() =>
                  navigate(
                    `/crm/report?userId=${userId}&lessonId=${lesson.info.lessonId}&scheduleSlotId=${lesson.info.scheduleSlotId}&&signImage=${lesson.traineeSignImageUrl}`,
                  )
                }
              >
                <div css={detailButtonTextCSS}>일지보기</div>
                <IcArrow css={iconCSS} />
              </Button>
            )}
          </div>
          <div css={descriptionContainerCSS}>
            <div>
              <div css={lessonCardText}>{lesson.info.orders}번째 수업</div>
              <div css={lessonCardText}>
                {formatNumber(startDate.getMonth() + 1)}.
                {formatNumber(startDate.getDate())}.{dayString} /
                {formatNumber(startDate.getHours())}:
                {formatNumber(startDate.getMinutes())} ~{" "}
                {formatNumber(endDate.getHours())}:
                {formatNumber(endDate.getMinutes())}
              </div>
            </div>
            <div
              css={css`
                margin-right: 20px;
              `}
            >
              {lesson.traineeSignImageUrl && (
                <ImageView
                  src={lesson.traineeSignImageUrl}
                  css={signImageCSS}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <DialogMobilePage
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        title={"수업 기록"}
      >
        <div css={bodyContainerCSS}>
          <div css={bodyHeaderContainerCSS}>
            <div css={bodyHeaderUpperContainerCSS}>
              <span css={bodyHeaderUpperContainerTextCSS}>
                {ticket?.traineeName}
              </span>
              {ticket?.status && (
                <div css={tagCSS()}>{ticketStatusInKR(ticket.status)}</div>
              )}
            </div>
            <div css={bodyHeaderLowerContainerTextCSS}>
              <div
                css={css`
                  display: flex;
                  gap: 8px;
                  align-items: center;
                `}
              >
                <span
                  css={css`
                    ${typography.mobile.body2};
                    color: ${colors.gray300};
                  `}
                >
                  기간
                </span>
                <span
                  css={css`
                    ${typography.mobile.body1};
                    color: ${colors.gray100};
                  `}
                >
                  {ticket?.startAt?.replaceAll("-", ".")} ~{" "}
                  {ticket?.endAt?.replaceAll("-", ".") ?? "종료 기간 없음"}
                </span>
              </div>
              <div
                css={css`
                  display: flex;
                  gap: 8px;
                  align-items: center;
                `}
              >
                <span
                  css={css`
                    ${typography.mobile.body2};
                    color: ${colors.gray300};
                  `}
                >
                  회차
                </span>
                <span
                  css={css`
                    ${typography.mobile.body1};
                    color: ${colors.gray100};
                  `}
                >
                  {ticket?.usedCount} / {ticket?.totalCount} 회
                </span>
              </div>
            </div>
          </div>
          <div
            css={css`
              width: 100%;
              height: 10px;
              background: black;
            `}
          />
          <div css={bodyMiddleContainerCSS}>
            {lessonList?.content.map((lesson) => {
              return (
                <div>
                  <LessonCard lesson={lesson} />
                </div>
              );
            })}
            {lessonList?.content.length === 0 && (
              <div css={noneLessonContainerCSS}>아직 진행한 수업이 없어요</div>
            )}
          </div>
        </div>
      </DialogMobilePage>
    </>
  );
}

const descriptionContainerCSS = css`
  display: flex;
  justify-content: space-between;
`;

const noneLessonContainerCSS = css`
  ${typography.mobile.heading3};
  color: ${colors.gray600};
  width: 100%;
  padding: 35px;
  justify-content: center;
  display: flex;
`;

const reportDetailButtonCSS = css`
  background: transparent;
`;

const iconCSS = css`
  color: ${colors.gray600};
  width: 20px;
  height: 20px;
`;

interface LessonCardProps {
  lesson: LessonTrainerLessonSummaryDTO;
}

const formatNumber = (num: number): string => {
  return num.toString().padStart(2, "0");
};

const lessonCardText = css`
  ${typography.mobile.body1};
  color: ${colors.gray600};
`;

const detailButtonTextCSS = css`
  ${typography.mobile.body1};
  color: ${colors.gray600};
`;
const lessonCardCSS = css`
  display: flex;
  flex-direction: column;
  padding: 14px;
  border-radius: 8px;
  gap: 12px;
  background: #fff;
  /* Small */
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.05);
`;

const contentCardContainerCSS = css`
  padding: 14px;
  background: white;
  border-radius: 8px;
`;

const bodyMiddleContainerCSS = css`
  padding: 24px 16px;
  background: ${colors.lightGray};
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 500px;
  overflow: auto;
`;

export const tagCSS = (backgroundColor?: string, textColor?: string) => css`
  ${typography.mobile.body2}
  color: ${textColor || "black"};
  width: fit-content;
  height: fit-content;
  background: ${backgroundColor || colors.lime600};
  border-radius: 4px;
  padding: 4px 6px;
`;

const bodyHeaderLowerContainerTextCSS = css`
  background: ${colors.gray800};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 8px;
`;

const bodyHeaderUpperContainerTextCSS = css`
  ${typography.mobile.heading2};
  color: ${colors.gray25};
`;

const bodyHeaderUpperContainerCSS = css`
  display: flex;
  gap: 8px;
`;

const bodyHeaderContainerCSS = css`
  display: flex;
  background: black;
  padding: 8px 16px;
  flex-direction: column;
  gap: 8px;
`;

const headerTitleCSS = css`
  margin: 0 auto; /* 가운데 정렬 */
  color: ${colors.gray25};
`;

const closeIconCSS = css`
  width: 38px;
  height: 38px;
  color: ${colors.gray25};
`;

const bodyContainerCSS = css`
  display: flex;
  background: black;
  flex-direction: column;
`;

const headerContainerCSS = css`
  display: flex;
  width: 100%;
  background: black;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

const signImageCSS = css`
  border: solid 1px ${colors.gray200};
  width: 60px;
  height: 60px;
  padding: 5px;
`;
