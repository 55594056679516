import React, { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { css, Interpolation, Theme } from "@emotion/react";
import PoDone from "design-system/components/atom/IconResource/Icon/PoDone";
import { colors } from "design-system/styles/colors";
import { CheckboxVariantType } from "design-system/components/Checkbox/Checkbox";

const ListCheckBox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    variant?: CheckboxVariantType;
    align?: "top" | "center" | "bottom";
    styles?: Interpolation<Theme>;
  }
>(
  (
    {
      variant = "default",
      align = "top",
      styles,
      onCheckedChange,
      children,
      checked, // 상위 컴포넌트에서 전달된 `checked` 사용
      ...props
    },
    ref,
  ) => {
    const handleDivClick = () => {
      if (onCheckedChange) {
        // 부모 컴포넌트로 상태 변경 알림
        onCheckedChange(!checked);
      }
    };

    return (
      <div css={checkboxWrapperCSS}>
        <CheckboxPrimitive.Root
          ref={ref}
          {...props}
          onCheckedChange={onCheckedChange}
          checked={checked}
          onClick={handleDivClick}
          css={[
            checkboxStyles[variant].checkbox,
            css`
              width: 24px;
              height: 24px;
            `,
          ]}
        >
          <div>
            <CheckboxPrimitive.Indicator css={checkboxIndicatorCSS} forceMount>
              <PoDone css={checkboxStyles[variant].icon} />
            </CheckboxPrimitive.Indicator>
          </div>
        </CheckboxPrimitive.Root>
        <div
          onClick={handleDivClick}
          css={css`
            cursor: pointer;
            width: 100%;
          `}
        >
          {children}
        </div>
      </div>
    );
  },
);

ListCheckBox.displayName = CheckboxPrimitive.Root.displayName;

export { ListCheckBox };

const checkboxWrapperCSS = css`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
`;

const checkboxAlignTopCSS = css`
  //margin-bottom: auto;
`;

const checkboxAlignCenterCSS = css`
  margin-top: auto;
  margin-bottom: auto;
`;

const checkboxAlignBottomCSS = css`
  //margin-top: auto;
`;

const checkboxDefaultCSS = css`
  width: 100%;
  height: 100%;
  padding: 0px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid ${colors.gray200};
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
    border: 1px solid ${colors.gray200};
  }

  &[data-state="checked"] {
    border: 0;
    background-color: black;

    :disabled {
      background-color: ${colors.default.Gray900};
    }
  }
`;

const checkboxCheckCSS = css`
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  color: ${colors.gray200};

  &[data-state="checked"] {
    color: ${colors.gray200};
  }
`;

const checkboxIndicatorCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const iconDefaultCSS = css`
  color: ${colors.default.White};
  width: 18.3px;
  height: 18.3px;
`;

const iconCheckCSS = css`
  width: 24px;
  height: 24px;
`;

export const checkboxStyles: Record<
  CheckboxVariantType,
  Record<"checkbox" | "icon", Interpolation<Theme>>
> = {
  default: {
    checkbox: checkboxDefaultCSS,
    icon: iconDefaultCSS,
  },
  check: {
    checkbox: checkboxCheckCSS,
    icon: iconCheckCSS,
  },
};
