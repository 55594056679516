import {
  MixedWorkoutDetail,
  RequestSaveLessonRequestDTO,
} from "../../../../api/trainer/schedules/schedulesTypes";

export const getReportResponse = (
  reportData: RequestSaveLessonRequestDTO & {
    mixedWorkoutDetail?: MixedWorkoutDetail[];
  },
  scheduleSlotId?: number,
) => {
  reportData.mixedWorkoutDetail?.map((item) => console.log(item));

  if (reportData.lessonResultType == "feedback_only") {
    return {
      scheduleSlotId,
      ...reportData,
      workoutDetails: undefined,
    };
  } else if (reportData.lessonResultType == "feedback_workout") {
    return {
      scheduleSlotId,
      ...reportData,
      workoutDetails: reportData.mixedWorkoutDetail?.map((detail) => ({
        id: detail.id,
        isCustom: detail.isCustom,
        workoutName: detail.workoutName,
        part: detail.part,
        sets: [],
        images: detail.imagesDetail.map((image) => image.id), // imagesDetail의 url 변환
        videos: detail.videosDetail.map((video) => video.id), // videosDetail의 url 변환
        feedback: detail.feedback,
      })),
    };
  } else {
    return {
      scheduleSlotId,
      ...reportData,
      workoutDetails: reportData.mixedWorkoutDetail?.map((detail) => ({
        id: detail.id,
        isCustom: detail.isCustom,
        workoutName: detail.workoutName,
        part: detail.part,
        sets: detail.sets,
        images: detail.imagesDetail.map((image) => image.id), // imagesDetail의 url 변환
        videos: detail.videosDetail.map((video) => video.id), // videosDetail의 url 변환
        feedback: detail.feedback,
      })),
    };
  }
};
