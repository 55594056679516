import { ScheduleType } from "api/common/commonEnumType";
import { createContext, Dispatch, SetStateAction } from "react";

export type SidebarState = "create" | "read" | "update";

interface ScheduleContext {
  scheduleType: ScheduleType;
  setScheduleType: Dispatch<SetStateAction<ScheduleType>>;
  sidebarState?: SidebarState;
  setSidebarState: Dispatch<SetStateAction<SidebarState | undefined>>;
  selectedDate?: Date | null;
  setSelectedDate: Dispatch<SetStateAction<Date | null>>;
  selectedWeek?: Date[] | null;
  setSelectedWeek?: Dispatch<SetStateAction<Date[] | null>>;
  scheduleSlotId?: number;
  setscheduleSlotId: Dispatch<SetStateAction<number | undefined>>;
}

export default createContext<ScheduleContext>({
  scheduleType: "ETC",
  setScheduleType: () => {},
  sidebarState: undefined,
  setSidebarState: () => {},
  selectedDate: undefined,
  setSelectedDate: () => {},
  selectedWeek: undefined,
  setSelectedWeek: () => {},
  scheduleSlotId: undefined,
  setscheduleSlotId: () => {},
});
