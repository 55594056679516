import { css } from "@emotion/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteSchedulesScheduleSlotId,
  deleteSchedulesScheduleSlotIdFollowing,
  getSchedulesScheduleSlotId,
} from "api/trainer/schedules/schedules";
import scheduleContext from "crm/schedule/scheduleContext";
import Button from "design-system/components/Button/Button";
import * as Dialog from "design-system/components/Dialog/Dialog";
import * as RadioGroup from "design-system/components/RadioGroup/RadioGroup";
import { colors } from "design-system/styles/colors";
import { queryClient, queryKeys } from "libs/react-query";
import { useContext, useState } from "react";
import infoIcon from "design-system/components/atom/IconResource/info.png";

export function DeleteScheduleDialog() {
  const { setSidebarState, scheduleSlotId = 0 } = useContext(scheduleContext);
  const [deleteScheduleType, setDeleteScheduleType] = useState("all");

  const { data: schedule } = useQuery({
    queryFn: () => getSchedulesScheduleSlotId({ scheduleSlotId }),
    queryKey: queryKeys.getSchedulesScheduleSlotId({ scheduleSlotId }),
    enabled: scheduleSlotId !== undefined,
  });

  const { mutate: deleteSchedule } = useMutation({
    mutationFn: deleteSchedulesScheduleSlotId,
    onSuccess: () => {
      setSidebarState(undefined);

      const selectedDate = new Date(schedule?.scheduleAt ?? "");

      queryClient.invalidateQueries([
        queryKeys.getSchedules({
          year: selectedDate?.getFullYear() ?? 0,
          month: selectedDate?.getMonth() ?? +1,
        })[0],
      ]);
    },
  });

  const { mutate: deleteFollowingSchedule } = useMutation({
    mutationFn: deleteSchedulesScheduleSlotIdFollowing,
    onSuccess: () => {
      setSidebarState(undefined);

      const selectedDate = new Date(schedule?.scheduleAt ?? "");

      queryClient.invalidateQueries([
        queryKeys.getSchedules({
          year: selectedDate?.getFullYear() ?? 0,
          month: selectedDate?.getMonth() ?? +1,
        })[0],
      ]);
    },
  });

  return (
    <Dialog.Root variant="page">
      <Dialog.Trigger asChild>
        <Button variant="text">삭제하기</Button>
      </Dialog.Trigger>
      {schedule?.repeatType ? (
        <Dialog.Content styles={repeatContentCSS}>
          <Dialog.Header styles={headerCSS}>
            <img src={infoIcon} css={infoIconCSS} />
            <div>
              <Dialog.Title>스케줄을 삭제하시겠습니까?</Dialog.Title>
              <Dialog.Description>
                반복일정으로 등록된 스케줄이에요
              </Dialog.Description>
            </div>
          </Dialog.Header>
          <Dialog.Body>
            <RadioGroup.Root
              onValueChange={setDeleteScheduleType}
              styles={radioGropRootCSS}
            >
              <RadioGroup.ItemWithLabel value="all">
                선택 일정 및 향후 일정
              </RadioGroup.ItemWithLabel>
              <RadioGroup.ItemWithLabel value="">
                선택 일정
              </RadioGroup.ItemWithLabel>
            </RadioGroup.Root>
          </Dialog.Body>
          <Dialog.Footer styles={footerCSS}>
            <Dialog.Close
              variant="secondary"
              onClick={() => {
                deleteScheduleType === "all"
                  ? deleteFollowingSchedule({ scheduleSlotId })
                  : deleteSchedule({ scheduleSlotId });
              }}
            >
              삭제
            </Dialog.Close>
            <Dialog.Close variant="primary">취소</Dialog.Close>
          </Dialog.Footer>
        </Dialog.Content>
      ) : (
        <Dialog.Content styles={contentCSS}>
          <Dialog.Header styles={headerCSS}>
            <img src={infoIcon} css={infoIconCSS} />
            <Dialog.Title>해당 스케줄을 삭제하시겠습니까?</Dialog.Title>
          </Dialog.Header>
          <Dialog.Body />
          <Dialog.Footer styles={footerCSS}>
            <Dialog.Close
              variant="secondary"
              onClick={() => {
                deleteSchedule({ scheduleSlotId });
              }}
            >
              삭제
            </Dialog.Close>
            <Dialog.Close variant="primary">취소</Dialog.Close>
          </Dialog.Footer>
        </Dialog.Content>
      )}
    </Dialog.Root>
  );
}

const repeatContentCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const contentCSS = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const headerCSS = css`
  align-items: center;
  gap: 16px;
`;

const footerCSS = css`
  gap: 10px;
`;

const radioGropRootCSS = css`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: ${colors.lightGray};
`;

const infoIconCSS = css`
  height: 40px;
  width: 40px;
  object-fit: contain;
`;
