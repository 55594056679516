import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { getProfilesDetail } from "api/trainer/profiles/profiles";
import { queryKeys } from "libs/react-query";

import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { InfoBadge } from "../InfoBadge/InfoBadge";
import {
  fontWeight,
  typography,
} from "design-system/styles/typography/typography";

export function PriceInfo() {
  const { trainerId } = useParams();

  const { data: trainerDetail } = useQuery({
    queryFn: () => getProfilesDetail({ trainerId: Number(trainerId) }),
    queryKey: queryKeys.getProfilesDetail({ trainerId: Number(trainerId) }),
  });

  return (
    <div css={containerCSS}>
      <div css={titleContainerCSS}>
        <InfoBadge text="레슨 이용 가격" />
        {"1:1 PT 이용권"}
      </div>
      <div>
        {trainerDetail?.trainer.prices.prices.map(({ cycle, price }, i) => {
          return (
            <div key={i} css={priceItemContainerCSS}>
              <div>{`${cycle}회`}</div>
              <div css={priceContainerCSS}>
                <span>
                  {"회당 "}
                  <span css={priceCSS}>{price.toLocaleString()}</span>
                  {"원"}
                </span>
                <div>{`${(price * cycle).toLocaleString()}원`}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const containerCSS = css`
  display: flex;
  flex-direction: column;
  color: ${colors.gray25};
  gap: 40px;
`;

const titleContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${typography.Body1};
`;

const priceItemContainerCSS = css`
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  border-top: 1px solid ${colors.gray800};
  border-bottom: 1px solid ${colors.gray800};
  color: ${colors.gray100};
  ${typography.Body3};
  font-weight: ${fontWeight.Medium};
  align-items: center;
`;

const priceContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const priceCSS = css`
  color: ${colors.gray25};
  ${typography.Body2};
`;
