import { css } from "@emotion/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getSchedulesScheduleSlotId,
  postSchedulesScheduleSlotIdNoshow,
} from "api/trainer/schedules/schedules";
import { getUsersUserId } from "api/trainer/users/users";
import scheduleContext from "crm/schedule/scheduleContext";
import Button from "design-system/components/Button/Button";
import * as Dialog from "design-system/components/Dialog/Dialog";
import { Textarea } from "design-system/components/Textarea/Textarea";
import { queryClient, queryKeys } from "libs/react-query";
import { useContext, useState } from "react";
import infoIcon from "design-system/components/atom/IconResource/info.png";

export function NoshowDialog() {
  const { scheduleSlotId = 0 } = useContext(scheduleContext);
  const [message, setMessage] = useState("");

  const { data: schedule } = useQuery({
    queryFn: () => getSchedulesScheduleSlotId({ scheduleSlotId }),
    queryKey: queryKeys.getSchedulesScheduleSlotId({ scheduleSlotId }),
    enabled: scheduleSlotId !== undefined,
  });

  const { data: user } = useQuery({
    queryFn: () => getUsersUserId(schedule?.userId ?? 0),
    queryKey: queryKeys.getUsersUserId(schedule?.userId ?? 0),
    enabled:
      schedule !== undefined && schedule.scheduleType.toUpperCase() === "PT",
  });

  const { mutate: noshowSchedule } = useMutation({
    mutationFn: postSchedulesScheduleSlotIdNoshow,
    onSuccess: () => {
      queryClient.invalidateQueries(
        queryKeys.getSchedulesScheduleSlotId({ scheduleSlotId }),
      );
    },
  });

  return (
    <>
      {user && schedule?.scheduleType.toUpperCase() === "PT" && (
        <Dialog.Root variant="page">
          <Dialog.Trigger asChild>
            <Button variant="text">결석처리</Button>
          </Dialog.Trigger>
          <Dialog.Content styles={contentCSS}>
            <Dialog.Header styles={headerCSS}>
              <img src={infoIcon} css={infoIconCSS} />
              <div>
                <Dialog.Title>결석 처리를 진행하시겠습니까?</Dialog.Title>
                <Dialog.Description>
                  수강권 횟수 차감 안내 문자가 회원님에게 전송돼요.
                </Dialog.Description>
              </div>
            </Dialog.Header>
            <Dialog.Body>
              <Textarea
                placeholder="사유를 입력해주세요."
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                maxLength={20}
                isShowCount
              />
            </Dialog.Body>
            <Dialog.Footer styles={footerCSS}>
              <Dialog.Close
                variant="secondary"
                onClick={() => {
                  noshowSchedule({
                    scheduleSlotId,
                    userId: user?.userId,
                    message,
                  });
                }}
              >
                확인
              </Dialog.Close>
              <Dialog.Close variant="primary">취소</Dialog.Close>
            </Dialog.Footer>
          </Dialog.Content>
        </Dialog.Root>
      )}
    </>
  );
}

const contentCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const headerCSS = css`
  align-items: center;
  gap: 16px;
`;

const footerCSS = css`
  gap: 10px;
`;

const infoIconCSS = css`
  height: 40px;
  width: 40px;
  object-fit: contain;
`;
