import React from "react";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../libs/react-query";
import { EventName, ScreenName, useAmplitude } from "../../hoock/useAmplitude";
import { ImageView } from "design-system/components/ImageView/ImageView";
import avatarImage from "design-system/components/atom/IconResource/avatar_image.svg";
import { centerDistanceWithUnit } from "../../api/admin/adminTypes";
import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { getList } from "../../api/trainer/list/list";
import { getWidthMediaQuery } from "../../styles/mediaQueries";

export function HomeTrainerList() {
  const navigate = useNavigate();
  const { sendClickEvent } = useAmplitude();

  const { data: trainers } = useQuery({
    queryFn: () => getList({ address: undefined }),
    queryKey: queryKeys.getList({ address: undefined }),
  });

  return (
    <>
      <div css={titleCSS}>핏츠유 추천 트레이너</div>
      <div css={listContainerCSS}>
        {trainers?.content.slice(0, 4).map(({ trainer, distance }) => (
          <button
            key={trainer.id}
            css={trainerItemButtonCSS}
            onClick={() => {
              sendClickEvent({
                eventName: EventName.web_search_trainer_detail_clicked,
                screenName: ScreenName.web_home_main,
              });
              navigate(`/trainer/${trainer.id}?dist=${distance}`);
            }}
          >
            <>
              <div css={imageWrapperCSS}>
                <ImageView
                  src={trainer.image?.url ?? avatarImage}
                  styles={trainerImageCSS}
                  isClickable={false}
                />
              </div>
              <div css={trainerItemInfoContainerCSS}>
                <div css={nameContainerCSS}>
                  {`${trainer.name} 트레이너`}
                  {trainer.profilePercent > 70 && (
                    <div css={recommendBadgeCSS}>{"추천"}</div>
                  )}
                </div>
                <div css={centerCSS}>
                  <span>{trainer.centerInfo?.name}</span>
                  {distance && (
                    <span>{` | ${centerDistanceWithUnit(distance)}`}</span>
                  )}
                </div>
              </div>
            </>
          </button>
        ))}
      </div>
    </>
  );
}

const titleCSS = css`
  display: flex;
  color: white;
  width: 100%;
  margin-top: 70px;
  justify-content: center;

  ${typography.Body2}
  ${getWidthMediaQuery("pc")} {
    justify-content: flex-start;
    padding-left: 10vw;
  }
`;

const listContainerCSS = css`
  max-width: 100%;
  white-space: nowrap;
  display: flex;
  margin-top: 10px;
  gap: 10px;
  overflow-x: scroll;
  padding: 0 15px;

  ${getWidthMediaQuery("pc")} {
    overflow-x: hidden;
    justify-content: center;
    align-items: flex-start;
    grid-gap: 16px;
    display: grid;
    padding: 25px 10vw;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const trainerItemButtonCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  color: ${colors.gray25};
  border-radius: 8px;
  background: #22251e;
  padding: 0;

  min-width: 200px;
  height: 310px;

  ${getWidthMediaQuery("pc")} {
    width: 255px;
    height: 310px;
  }
`;

const imageWrapperCSS = css`
  width: 100%;
`;

const trainerImageCSS = css`
  width: 100%;
  height: 195px;
  border-radius: 8px 8px 0 0;
`;

const trainerItemInfoContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 17.5px 16px;
  gap: 10px;
  text-align: start;
`;

const nameContainerCSS = css`
  display: flex;
  gap: 8px;
  align-items: center;
  ${typography.mobile.heading2};
`;

const recommendBadgeCSS = css`
  display: flex;
  width: fit-content;
  height: fit-content;
  padding: 3.5px 5.5px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: ${colors.gray25};
  color: ${colors.gray900};
  ${typography.mobile.body3};
`;

const centerCSS = css`
  ${typography.mobile.body2};
`;
