import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import {
  fontWeight,
  typography,
} from "design-system/styles/typography/typography";
import leftArrowIcon from "design-system/components/atom/IconResource/ic_arrow_left.svg";
import rightArrowIcon from "design-system/components/atom/IconResource/ic_arrow_right.svg";

import DatePicker, { DatePickerProps } from "react-datepicker";
import { ko } from "date-fns/locale";
import Button from "design-system/components/Button/Button";

export type FitsYouDatePickerProps = DatePickerProps & {
  isCalendarOpen: boolean;
  setIsCalendarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDate?: Date | null;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
};

export function FitsYouDatePicker({
  isCalendarOpen,
  setIsCalendarOpen,
  selectedDate,
  setSelectedDate,
  ...props
}: FitsYouDatePickerProps) {
  const today = new Date();

  return (
    <DatePicker
      {...props}
      locale={ko}
      dateFormat="yyyy.MM.dd"
      selected={selectedDate}
      open={isCalendarOpen}
      onInputClick={() => {
        setIsCalendarOpen(!isCalendarOpen);
      }}
      onClickOutside={() => {
        setIsCalendarOpen(false);
      }}
      showPopperArrow={false}
      enableTabLoop={false}
      popperProps={{ strategy: "fixed" }}
      popperContainer={({ children }) => (
        <div css={popperContainerCSS}>{children}</div>
      )}
      renderCustomHeader={({ increaseMonth, decreaseMonth, date }) => (
        <div css={datePickerHeaderContainerCSS}>
          <span>{`${date.getFullYear()}년 ${date.getMonth() + 1}월`}</span>
          <div css={datePickerMonthChangeContainerCSS}>
            <Button variant="icon" onClick={decreaseMonth}>
              <img
                src={leftArrowIcon}
                alt={"decrease month button"}
                css={datePickerMonthChangeArrowCSS}
              />
            </Button>
            <Button variant="icon" onClick={increaseMonth}>
              <img
                src={rightArrowIcon}
                alt={"increase month button"}
                css={datePickerMonthChangeArrowCSS}
              />
            </Button>
          </div>
        </div>
      )}
      calendarContainer={({ children }) => (
        <div css={datePickerCalendarCSS}>
          {children}
          <div css={datePickerBottomButtonContainerCSS}>
            <button
              css={datePickerBottomButtonCSS}
              onClick={() => setSelectedDate(today)}
            >
              오늘날짜
            </button>
            <button
              css={datePickerBottomButtonCSS}
              onClick={() => {
                setIsCalendarOpen(false);
              }}
            >
              닫기
            </button>
          </div>
        </div>
      )}
    />
  );
}

const popperContainerCSS = css`
  position: absolute;
  z-index: 100 !important;
`;

const datePickerCalendarCSS = css`
  display: flex;
  width: 280px;
  padding: 16px 16px 0 16px;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  gap: 16px;
  border-radius: 2px;
  background-color: ${colors.White};
  box-shadow: 0px 0px 20px 0px rgba(114, 138, 157, 0.24);

  .react-datepicker-wrapper {
    width: inherit;
  }

  .react-datepicker__aria-live {
    display: none;
  }

  .react-datepicker__month-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15.56px;
  }

  .react-datepicker__header {
    display: flex;
    flex-direction: column;
    gap: 15.56px;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    text-align: center;
    color: ${colors.gray600};
    ${typography.mobile.body3};
    padding-bottom: 4.667px;
    border-bottom: 0.467px solid ${colors.gray50};
  }

  .react-datepicker__month {
    display: flex;
    flex-direction: column;
    gap: 15.56px;
    text-align: center;
    color: ${colors.gray800};
    ${typography.Button2};
    font-weight: ${fontWeight.Medium};
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__day {
    width: 18px;
    cursor: pointer;
  }

  .react-datepicker__day--selected {
    // TODO: 디자인 의견 물어보고 추후 수정 @sello
    /* border-radius: 50%;
    border: 1px solid ${colors.gray600}; */
    color: ${colors.lime900};
  }

  .react-datepicker__day--outside-month {
    color: ${colors.gray300};
    pointer-events: none;
  }
`;

const datePickerHeaderContainerCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: ${colors.gray900};
  ${typography.mobile.heading4};
`;

const datePickerMonthChangeContainerCSS = css`
  display: flex;
  align-items: center;
  gap: 12.445px;
`;

const datePickerMonthChangeArrowCSS = css`
  width: 18.667px;
  height: 18.667px;
`;

const datePickerBottomButtonContainerCSS = css`
  display: flex;
  flex-direction: row;
  width: 280px;
  padding: 10px;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  background: ${colors.lightGray};
`;

const datePickerBottomButtonCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  ${typography.mobile.body3};
  color: ${colors.gray600};
  border: 1px solid ${colors.gray600};
`;
