import React from "react";
import * as DataTable from "design-system/components/DataTable/DataTable";
import { useQuery } from "@tanstack/react-query";
import { getTrainees } from "../../../api/admin/admin";
import { queryKeys } from "../../../libs/react-query";

export function TraineeList() {
  const { data: traineeList } = useQuery({
    queryFn: () => getTrainees(),
    queryKey: queryKeys.getTrainee(),
  });

  return (
    <div>
      <DataTable.Root>
        <DataTable.Table
          data={traineeList?.content || []}
          columns={[
            {
              cell: ({ row }) => row.original.name,
              header: "이름",
              size: 150,
            },
            {
              cell: ({ row }) => row.original.email,
              header: "이메일",
              size: 150,
            },
            {
              cell: ({ row }) => row.original.phone,
              header: "휴대전화",
              size: 170,
            },
            {
              cell: ({ row }) => row.original.birthdate,
              header: "생년월일",
              size: 150,
            },
          ]}
        ></DataTable.Table>
      </DataTable.Root>
    </div>
  );
}
