import { customFetch } from "../../../libs/fetch/fetch";
import {
  GetProfilesDetailRequest,
  GetProfilesDetailResponse,
} from "./profilesTypes";

export async function getProfilesDetail({
  trainerId,
  address,
}: GetProfilesDetailRequest): Promise<GetProfilesDetailResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/profiles/${trainerId}/detail`,
    params: { address },
  });
}
