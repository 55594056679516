import { useContext, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient, queryKeys } from "libs/react-query";
import workoutListContext from "crm/workout-list/workoutListContext";
import {
  deleteWorkoutsWorkoutIdImages,
  getWorkoutsWorkoutId,
  postWorkoutsWorkoutIdImages,
  putWorkoutsWorkoutIdCustom,
  putWorkoutsWorkoutIdImages,
} from "api/trainer/workouts/workouts";
import {
  workoutEquipments,
  WorkoutEquipments,
  workoutEquipmentsInKR,
  workoutParts,
  WorkoutParts,
  workoutPartsInKR,
} from "api/common/commonEnumType";

import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";

import Button from "design-system/components/Button/Button";
import { Textarea } from "design-system/components/Textarea/Textarea";
import * as Dialog from "design-system/components/Dialog/Dialog";
import * as Select from "design-system/components/Select/Select";
import { ImageSelector } from "design-system/components/ImageSelector/ImageSelector";
import { RedDot } from "design-system/components/RedDot/RedDot";

export function CustomWorkoutUpdateDialog() {
  const { workoutId = 0 } = useContext(workoutListContext);

  const { data: workout } = useQuery({
    queryFn: () => getWorkoutsWorkoutId({ workoutId }),
    queryKey: queryKeys.getWorkoutsWorkoutId({ workoutId }),
    enabled: !!workoutId,
  });

  const { mutate: updateWorkout } = useMutation({
    mutationFn: putWorkoutsWorkoutIdCustom,
    onSuccess: () => {
      if (imageFile) {
        const image = new FormData();
        image.append("image", imageFile);
        if (workout?.workout.thumbnail) {
          editWorkoutImage({ workoutId, image });
        } else {
          createWorkoutImage({ workoutId, image });
        }
      } else if (workout?.workout.thumbnail && !thumbnail) {
        deleteWorkoutImage({ workoutId });
      } else {
        queryClient.invalidateQueries(
          queryKeys.getWorkoutsWorkoutId({ workoutId }),
        );
        queryClient.invalidateQueries(queryKeys.getWorkouts());
      }
    },
  });

  const { mutate: createWorkoutImage } = useMutation({
    mutationFn: postWorkoutsWorkoutIdImages,
    onSuccess: () => {
      queryClient.invalidateQueries(
        queryKeys.getWorkoutsWorkoutId({ workoutId }),
      );
      queryClient.invalidateQueries(queryKeys.getWorkouts());
    },
  });

  const { mutate: editWorkoutImage } = useMutation({
    mutationFn: putWorkoutsWorkoutIdImages,
    onSuccess: () => {
      queryClient.invalidateQueries(
        queryKeys.getWorkoutsWorkoutId({ workoutId }),
      );
      queryClient.invalidateQueries(queryKeys.getWorkouts());
    },
  });

  const { mutate: deleteWorkoutImage } = useMutation({
    mutationFn: deleteWorkoutsWorkoutIdImages,
    onSuccess: () => {
      queryClient.invalidateQueries(
        queryKeys.getWorkoutsWorkoutId({ workoutId }),
      );
      queryClient.invalidateQueries(queryKeys.getWorkouts());
    },
  });

  const [workoutName, setWorkoutName] = useState<string>();
  const [workoutPart, setWorkoutPart] = useState<WorkoutParts>();
  const [workoutEquipment, setWorkoutEquipment] = useState<WorkoutEquipments>();
  const [thumbnail, setThumbnail] = useState<string>();
  const [imageFile, setImageFile] = useState<File | undefined>();

  const reset = () => {
    if (workout) {
      setWorkoutName(workout.workout.name);
      setWorkoutPart(workout.workout.part);
      setWorkoutEquipment(workout.workout.equipment);
      setThumbnail(workout.workout.thumbnail);
      setImageFile(undefined);
    }
  };

  return (
    <Dialog.Root variant="page">
      <Dialog.Trigger asChild>
        <Button variant="outline" size={"auto"}>
          {"커스텀 운동 수정"}
        </Button>
      </Dialog.Trigger>
      {workout && (
        <Dialog.Content onAnimationEndCapture={() => reset()}>
          <Dialog.Header>
            <Dialog.Title>{"커스텀 운동 수정"}</Dialog.Title>
          </Dialog.Header>
          <Dialog.Body styles={dialogBodyCSS}>
            <div css={requireInputTextCSS}>
              <RedDot />
              {"는 필수 입력칸입니다"}
            </div>
            <div css={contentContainerCSS}>
              <div>
                {"운동 이름"}
                <RedDot />
              </div>
              <Textarea
                placeholder="운동 이름 입력"
                value={workoutName}
                onChange={(e) => setWorkoutName(e.target.value)}
                isAutoHeight={false}
                rows={1}
                isPreventNewline
                styles={textareaCSS}
              />
            </div>
            <div css={contentContainerCSS}>
              {"운동 사진"}
              <ImageSelector
                variant="square"
                src={thumbnail}
                isWorkoutItem={false}
                onImageRemove={() => {
                  setThumbnail(undefined);
                  setImageFile(undefined);
                }}
                onChange={async (event) => {
                  if (!!event?.target?.files?.length && event.target.files[0]) {
                    setImageFile(event.target.files[0]);
                  }
                }}
              />
            </div>
            <div css={contentRowContainerCSS}>
              <div css={contentContainerCSS}>
                <div>
                  {"운동 부위"}
                  <RedDot />
                </div>
                <Select.Root
                  value={workoutPart}
                  onValueChange={(part: WorkoutParts) => setWorkoutPart(part)}
                >
                  <Select.Trigger>
                    <Select.Value />
                  </Select.Trigger>
                  <Select.Content>
                    {workoutParts
                      .filter((workoutPart) => workoutPart !== "custom")
                      .map((item) => (
                        <Select.Item value={item} key={item}>
                          {workoutPartsInKR(item)}
                        </Select.Item>
                      ))}
                  </Select.Content>
                </Select.Root>
              </div>
              <div css={contentContainerCSS}>
                <div>
                  {"운동 기구"}
                  <RedDot />
                </div>
                <Select.Root
                  value={workoutEquipment}
                  onValueChange={(part: WorkoutEquipments) =>
                    setWorkoutEquipment(part)
                  }
                >
                  <Select.Trigger>
                    <Select.Value />
                  </Select.Trigger>
                  <Select.Content>
                    {workoutEquipments.map((item) => (
                      <Select.Item value={item} key={item}>
                        {workoutEquipmentsInKR(item)}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select.Root>
              </div>
            </div>
          </Dialog.Body>
          <Dialog.Footer>
            <Dialog.Close
              variant="primary"
              disabled={!workoutName || !workoutPart || !workoutEquipment}
              onClick={() =>
                workoutName &&
                workoutPart &&
                updateWorkout({
                  workoutId,
                  workoutName,
                  workoutPart,
                  workoutEquipment,
                })
              }
            >
              {"저장하기"}
            </Dialog.Close>
          </Dialog.Footer>
        </Dialog.Content>
      )}
    </Dialog.Root>
  );
}

const dialogBodyCSS = css`
  padding-top: 16px;
  padding-bottom: 31px;
  gap: 16px;
  align-items: flex-start;
`;

const requireInputTextCSS = css`
  ${typography.mobile.body3}
`;

const contentContainerCSS = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  ${typography.mobile.body1};
`;

const contentRowContainerCSS = css`
  display: flex;
  width: 100%;
  gap: 16px;
`;

const textareaCSS = css`
  height: 46px;
  justify-content: center;
`;
