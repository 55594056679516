import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  useContext,
} from "react";

import PoDone from "design-system/components/atom/IconResource/Icon/PoDone";
import arrowDownIcon from "design-system/components/atom/IconResource/ic_arrow_down.svg";
import { colors } from "design-system/styles/colors";
import {
  fontWeight,
  typography,
} from "design-system/styles/typography/typography";
import { Interpolation, Theme, css } from "@emotion/react";
import * as SelectPrimitive from "@radix-ui/react-select";
import SelectContext from "./selectContext";

export type SelectSizeType = "default" | "small";

const Group = SelectPrimitive.Group;

const Value = SelectPrimitive.Value;

const Root = forwardRef<
  ElementRef<typeof SelectPrimitive.Root>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Root> & {
    size?: SelectSizeType;
  }
>(({ size = "default", children, ...props }) => {
  return (
    <SelectContext.Provider value={{ size }}>
      <SelectPrimitive.Root {...props}>{children}</SelectPrimitive.Root>
    </SelectContext.Provider>
  );
});

Root.displayName = SelectPrimitive.Root.displayName;

const Trigger = forwardRef<
  ElementRef<typeof SelectPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, children, ...props }, ref) => {
  const selectContext = useContext(SelectContext);

  return (
    <SelectPrimitive.Trigger
      ref={ref}
      css={[
        selectTriggerCSS,
        styles,
        selectContext?.size && selectSizeStyles[selectContext.size].trigger,
      ]}
      {...props}
    >
      {children}
      {selectContext?.size === "small" && (
        <SelectPrimitive.Icon asChild>
          <img src={arrowDownIcon} css={selectTriggerIconSmallCSS} />
        </SelectPrimitive.Icon>
      )}
    </SelectPrimitive.Trigger>
  );
});

Trigger.displayName = SelectPrimitive.Trigger.displayName;

const Content = forwardRef<
  ElementRef<typeof SelectPrimitive.Content>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, children, position = "popper", ...props }, ref) => {
  const selectContext = useContext(SelectContext);

  return (
    <SelectPrimitive.Portal>
      <SelectPrimitive.Content
        ref={ref}
        css={[
          selectContentCSS,
          selectContext?.size && selectSizeStyles[selectContext.size].content,
          styles,
        ]}
        position={position}
        sideOffset={4}
        {...props}
      >
        <SelectPrimitive.Viewport
          css={position === "popper" && selectViewportPositionPopperCSS}
        >
          {children}
        </SelectPrimitive.Viewport>
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  );
});

Content.displayName = SelectPrimitive.Content.displayName;

const Label = forwardRef<
  ElementRef<typeof SelectPrimitive.Label>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Label> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => (
  <SelectPrimitive.Label ref={ref} css={[selectLabelCSS, styles]} {...props} />
));

Label.displayName = SelectPrimitive.Label.displayName;

const Item = forwardRef<
  ElementRef<typeof SelectPrimitive.Item>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, children, ...props }, ref) => {
  const selectContext = useContext(SelectContext);

  return (
    <SelectPrimitive.Item
      ref={ref}
      css={[
        selectItemCSS,
        selectContext?.size && selectSizeStyles[selectContext.size].item,
        styles,
      ]}
      {...props}
    >
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      <span css={selectItemIndicatorWrapperCSS}>
        <SelectPrimitive.ItemIndicator>
          <PoDone
            css={
              selectContext?.size &&
              selectSizeStyles[selectContext.size].itemCheckIcon
            }
          />
        </SelectPrimitive.ItemIndicator>
      </span>
    </SelectPrimitive.Item>
  );
});

Item.displayName = SelectPrimitive.Item.displayName;

const Separator = forwardRef<
  ElementRef<typeof SelectPrimitive.Separator>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Separator> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    css={[selectSeparatorCSS, styles]}
    {...props}
  />
));

Separator.displayName = SelectPrimitive.Separator.displayName;

export { Root, Group, Value, Trigger, Content, Label, Item, Separator };

const selectTriggerCSS = css`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background-color: ${colors.gray25};
  border: 1px solid ${colors.default.Gray600};
  cursor: pointer;

  span {
    text-align: start;
    width: 100%;
    color: ${colors.default.Gray};
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const selectTriggerDefaultCSS = css`
  height: 46px;
  padding: 16px 14px;
  border-radius: 8px;
  ${typography.mobile.body2};
  gap: 16px;
`;

const selectTriggerSmallCSS = css`
  width: 100px;
  height: 36px;
  padding: 8px 10px 8px 12px;
  border-radius: 6px;
  ${typography.mobile.body2};
  gap: 10px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const selectTriggerIconSmallCSS = css`
  width: 18px;
  height: 18px;
  color: ${colors.default.Gray};
`;

const selectContentCSS = css`
  overflow: hidden;
  position: relative;
  z-index: 50;
  border: 1px solid ${colors.gray100};
  background-color: ${colors.gray25};
  box-shadow: 0px 5px 10px 0px rgba(16, 16, 16, 0.2);
`;

const selectContentDefaultCSS = css`
  border-radius: 8px;
`;

const selectContentSmallCSS = css`
  border-radius: 6px;
  width: 100px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const selectViewportPositionPopperCSS = css`
  width: 100%;
  min-width: var(--radix-select-trigger-width);
  height: var(--radix-select-trigger-height);
`;

const selectLabelCSS = css`
  padding: 8px 12px;
  ${typography.mobile.body3};
  font-weight: ${fontWeight.SemiBold};
`;

const selectItemCSS = css`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  outline: 0;
  user-select: none;

  span {
    color: ${colors.default.Gray};
  }

  :focus {
    background-color: ${colors.gray100};
  }

  :hover {
    background-color: ${colors.gray100};
  }

  &[data-disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const selectItemDefaultCSS = css`
  height: 46px;
  padding: 16px 14px;
  ${typography.mobile.body3};
  gap: 16px;
`;

const selectItemSmallCSS = css`
  height: 26px;
  padding: 8px 12px;
  ${typography.mobile.body3};
  gap: 10px;
`;

const selectItemIndicatorWrapperCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const selectItemCheckIconDefaultCSS = css`
  width: 17px;
  height: 17px;
  color: ${colors.default.Gray};
`;

const selectItemCheckIconSmallCSS = css`
  width: 12px;
  height: 12px;
  color: ${colors.default.Gray};
`;

const selectSeparatorCSS = css`
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: -4px;
  margin-right: -4px;
  height: 1px;
  background-color: ${colors.default.Black_a10};
`;

export const selectSizeStyles: Record<
  SelectSizeType,
  Record<string, Interpolation<Theme>>
> = {
  default: {
    trigger: selectTriggerDefaultCSS,
    content: selectContentDefaultCSS,
    item: selectItemDefaultCSS,
    itemCheckIcon: selectItemCheckIconDefaultCSS,
  },
  small: {
    trigger: selectTriggerSmallCSS,
    content: selectContentSmallCSS,
    item: selectItemSmallCSS,
    itemCheckIcon: selectItemCheckIconSmallCSS,
  },
} as const;
