import React, { useState } from "react";
import { css } from "@emotion/react";
import Button from "design-system/components/Button/Button";
import { typography } from "design-system/styles/typography/typography";
import mobileEx1 from "resource/trainer/homeResource/mobile1.png";
import mobileEx2 from "resource/trainer/homeResource/mobile2-1.png";
import mobileEx2_2 from "resource/trainer/homeResource/mobile2-2.png";
import pc1img from "resource/trainer/homeResource/homepage_pc1.png";
import pc2img from "resource/trainer/homeResource/pc2.png";
import middleBG from "resource/trainer/homeResource/homepage_middle.png";
import { getWidthMediaQuery } from "../styles/mediaQueries";
import { EventName, ScreenName, useAmplitude } from "../hoock/useAmplitude";

export function DescriptionFrame() {
  const [isPC, setIsPC] = useState(true);
  const { sendScreenViewEvent } = useAmplitude();

  return (
    <div
      css={css`
        background-color: black;
        position: relative;
      `}
    >
      <div css={containerCSS}>
        <div css={buttonContainerCSS}>
          <Button
            css={[buttonCSS, isPC && activeButtonCSS]}
            onClick={() => {
              setIsPC(true);
              sendScreenViewEvent({
                eventName: EventName.web_home_contents_description_clicked,
                screenName: ScreenName.web_home_main,
                eventProperties: {
                  description_type: "pc",
                },
              });
            }}
          >
            PC
          </Button>
          <Button
            css={[buttonCSS, !isPC && activeButtonCSS]}
            onClick={() => {
              setIsPC(false);
              sendScreenViewEvent({
                eventName: EventName.web_home_contents_description_clicked,
                screenName: ScreenName.web_home_main,
                eventProperties: {
                  description_type: "mobile",
                },
              });
            }}
          >
            MOBILE
          </Button>
        </div>
        {isPC && (
          <div css={descriptionCSS}>
            <div css={titleCSS}>
              PC환경에서도 일지 조회 및 작성을 진행 할 수 있어요.
            </div>
            <div css={subTitleCSS}>
              수업 중 기록한 사진 및 동영상을 잠시 저장해두었다가
              <br />
              PC에서 이어서 작성해보세요!
            </div>
          </div>
        )}
        {!isPC && (
          <div css={descriptionCSS}>
            <div css={titleCSS}>
              일지 작성은 물론, 일정 관리까지 모바일로 쉽고 빠르게
            </div>
            <div css={subTitleCSS}>
              수업 중 기록한 사진 및 동영상을 잠시 저장해두었다가
              <br />
              PC에서 이어서 작성해보세요!
            </div>
          </div>
        )}
        {isPC && (
          <div css={pcImageContainerCSS}>
            <img src={pc1img} css={pcImage1CSS} />
            <img src={pc2img} css={pcImage2CSS} />
          </div>
        )}
        {!isPC && (
          <div css={mobileImageContainerCSS}>
            <div>
              <img src={mobileEx1} css={mobileImageCSS} />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                padding-top: 170px;
                gap: 200px;
              `}
            >
              <img src={mobileEx2} css={mobileImageCSS} />
              <img src={mobileEx2_2} css={mobileImageCSS} />
            </div>
          </div>
        )}
      </div>
      <div
        css={css`
          width: 100%;

          ${getWidthMediaQuery("pc")} {
            position: absolute;
            top: 1400px;
          }
        `}
      >
        <img
          src={middleBG}
          css={css`
            width: 100%;
            overflow: hidden;

            ${getWidthMediaQuery("pc")} {
              max-height: 700px;
            }
          `}
        />
      </div>
    </div>
  );
}

const pcImageContainerCSS = css`
  margin-top: 90px;
  width: 100%;
  display: flex;
  gap: 70px;
  flex-direction: column;
  align-items: center;

  ${getWidthMediaQuery("pc")} {
    gap: 256px;
  }
`;
const pcImage1CSS = css`
  width: 330px;
  object-fit: contain;

  ${getWidthMediaQuery("pc")} {
    width: 905px;
  }
`;
const pcImage2CSS = css`
  width: 90vw;
  object-fit: contain;

  ${getWidthMediaQuery("pc")} {
    margin-left: 400px;
    width: 1400px;
  }
`;

const mobileImageCSS = css`
  width: 250px;
  object-fit: contain;

  ${getWidthMediaQuery("pc")} {
    width: 300px;
  }
`;

const mobileImageContainerCSS = css`
  margin-top: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${getWidthMediaQuery("pc")} {
    flex-direction: row;
    align-items: normal;
    gap: 203px;
  }
`;

const descriptionCSS = css`
  padding: 50px 10px 0 10px;
  width: 100%;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 29px;

  ${getWidthMediaQuery("pc")} {
    padding: 135px 0 0 35px;
    gap: 49px;
  }
`;

const titleCSS = css`
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: -2.4px;

  ${getWidthMediaQuery("pc")} {
    font-size: 48px;
  }
`;

const subTitleCSS = css`
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 48.667px */
  letter-spacing: -1.521px;

  ${getWidthMediaQuery("pc")} {
    font-size: 30px;
  }
`;
const containerCSS = css`
  padding: 110px 0 150px 0;
  z-index: 2;
  position: relative;

  ${getWidthMediaQuery("pc")} {
  }
`;

const buttonContainerCSS = css`
  display: flex;
  gap: 137px;
  justify-content: center;

  ${getWidthMediaQuery("pc")} {
    padding-left: 270px;
    justify-content: normal;
  }
`;

const buttonCSS = css`
  color: #a5a5a5; /* 기본 글자색 */
  background: none;
  ${typography.Body3}
`;

const activeButtonCSS = css`
  color: white; /* 선택된 버튼의 글자색 */
  text-decoration: underline;
  background: none;
  ${typography.Body3}
  text-underline-offset: 10px;
`;
