import { css } from "@emotion/react";
import {
  fontFamily,
  fontWeight,
  typography,
} from "design-system/styles/typography/typography";
import { colors } from "design-system/styles/colors";
import Button from "design-system/components/Button/Button";
import { useNavigate } from "react-router";
import { EventName, ScreenName, useAmplitude } from "../../hoock/useAmplitude";

interface CrmHeaderProps {
  logoutDisable?: boolean;
  screenName?: ScreenName;
}

export function CrmHeader({
  logoutDisable = false,
  screenName = ScreenName.web_crm_home_main,
}: CrmHeaderProps) {
  const navigate = useNavigate();
  const redirectURL = process.env.REACT_APP_KAKAO_LOGOUT_REDIRECT_URI;
  const key = process.env.REACT_APP_KAKAO;
  const { sendClickEvent } = useAmplitude();
  const currentUrl = window.location.href;
  const onLogoutClick = async () => {
    sendClickEvent({
      eventName: EventName.web_logout_button_clicked,
      screenName: screenName,
    });
    window.location.href = `https://kauth.kakao.com/oauth/logout?client_id=${key}&logout_redirect_uri=${redirectURL}`;
  };

  return (
    <div css={gnbContainerCSS}>
      <div css={logoContainerCSS}>
        <div css={CrmLogoTextCSS} onClick={() => navigate("/crm")}>
          FitsYou
        </div>
        {currentUrl.includes("fitsyou-admin") && (
          <div css={adminNavigationContainerCSS}>
            <Button onClick={() => navigate(`/crm/fitsyou-admin/trainer`)}>
              트레이너
            </Button>
            <Button onClick={() => navigate(`/crm/fitsyou-admin/report`)}>
              일지
            </Button>
            <Button onClick={() => navigate(`/crm/fitsyou-admin/trainee`)}>
              회원
            </Button>
          </div>
        )}
      </div>
      {!logoutDisable && (
        <Button
          css={buttonCSS}
          onClick={async () => {
            await onLogoutClick();
          }}
        >
          로그아웃
        </Button>
      )}
    </div>
  );
}

const adminNavigationContainerCSS = css`
  display: flex;
  gap: 10px;
`;

const logoContainerCSS = css`
  display: flex;
  gap: 25px;
  align-items: center;
`;

const buttonCSS = css`
  background: transparent;
  color: white;
  ${typography.Button1}
`;

const gnbContainerCSS = css`
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 100%;
  padding-left: 29px;
  padding-right: 80px;
  align-items: center;
  background: ${colors.gray800};
`;

const CrmLogoTextCSS = css`
  color: ${colors.gray25};
  font-weight: ${fontWeight.Bold};
  font-family: ${fontFamily.natomPro}, sans-serif;
  line-height: 160%;
  font-size: 40px;
  cursor: pointer;
`;
