import { useSearchParams } from "react-router-dom";
import { getTrainersTrainerId } from "../../../../api/admin/admin";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../../libs/react-query";
import { css } from "@emotion/react";
import { ImageView } from "design-system/components/ImageView/ImageView";

export function AdminTrainerDetailPage() {
  const [searchParams] = useSearchParams();
  const id = parseInt(searchParams.get("id") || "0", 10);

  const { data: trainerInfo } = useQuery({
    enabled: !!id,
    queryFn: () => getTrainersTrainerId(id),
    queryKey: queryKeys.getTrainersTrainerId(id),
  });

  return (
    <div css={containerCSS}>
      <div>{trainerInfo?.name}</div>
      <div> {JSON.stringify(trainerInfo?.centerInfo)}</div>
      <div> {"[트레이너 소개]     " + trainerInfo?.selfIntroduction}</div>
      <div>
        {trainerInfo?.mainImage && (
          <ImageView src={trainerInfo.mainImage.url} />
        )}
      </div>
      <div css={imagesContainerCSS}>
        {trainerInfo?.images &&
          trainerInfo.images.length > 0 &&
          trainerInfo.images.map((image) => {
            return <ImageView src={image.url} />;
          })}
      </div>
      <div>자격사항</div>
      <div>
        {trainerInfo &&
          trainerInfo.qualifications.length > 0 &&
          trainerInfo.qualifications.map((qualification) => {
            return <div>{JSON.stringify(qualification)}</div>;
          })}
      </div>

      <div>경력</div>
      <div>
        {trainerInfo?.careers &&
          trainerInfo.careers?.length > 0 &&
          trainerInfo.careers.map((career) => {
            return <div>{JSON.stringify(career)}</div>;
          })}
      </div>
    </div>
  );
}

const imagesContainerCSS = css`
  display: flex;
  gap: 8px;
`;

const containerCSS = css`
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
