import { css } from "@emotion/react";
import { ImageView } from "design-system/components/ImageView/ImageView";
import img from "design-system/components/atom/IconResource/recommend_img.png";
import { typography } from "design-system/styles/typography/typography";
import { getWidthMediaQuery } from "../../../styles/mediaQueries";

export function RecommendDescription() {
  return (
    <div css={containerCSS}>
      <div>
        <ImageView src={img} css={iconCSS} isClickable={false} />
      </div>
      <div css={textCSS}>
        믿을 수 있는 PT 선생님 <br />
        이제 실제 수업 내용을 보고 판단하세요
      </div>
      <div>
        <div css={subtextCSS}>
          자격 검증, 인터뷰 과정을 통해 <br />
          우수한 트레이너들을 추천드리고 있어요
        </div>
      </div>
    </div>
  );
}

const textCSS = css`
  background: linear-gradient(109deg, #83f9e9 -2.12%, #e0f569 111.08%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 56px */
  letter-spacing: -2px;

  ${getWidthMediaQuery("pc")} {
    font-size: 40px;
  }
`;

const subtextCSS = css`
  color: white;
  opacity: 70%;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  font-size: 15px;

  ${getWidthMediaQuery("pc")} {
    ${typography.mobile.heading1};
  }
`;

const containerCSS = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 40px;
`;

const iconCSS = css`
  width: 66px;
  margin-top: 40px;
  height: 53px;
`;
