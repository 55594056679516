import { DialogPopup } from "design-system/components/DialogPopup/DialogPopup";
import React from "react";

export interface SendErrorDialogProps {
  isNoSetError: boolean;
  setIsNoSetError: (state: boolean) => void;
  isNoWorkoutError: boolean;
  setIsNoWorkoutError: (state: boolean) => void;
  isRequireDataMissingError: boolean;
  setIsRequireDataMissingError: (state: boolean) => void;
  isFutureReportError?: boolean;
  setIsFutureReportError?: (state: boolean) => void;
}

export function SendErrorDialog({
  isNoSetError,
  setIsNoSetError,
  isNoWorkoutError,
  setIsNoWorkoutError,
  isRequireDataMissingError,
  setIsRequireDataMissingError,
  isFutureReportError,
  setIsFutureReportError,
}: SendErrorDialogProps) {
  return (
    <>
      <DialogPopup
        isOpen={isNoSetError}
        setIsOpen={setIsNoSetError}
        title={"추가 입력이 필요한 값이 있어요"}
        body={
          "상세일지는 운동별 1개이상의 운동 세트가 추가되어야 해요\n세트 추가를 원치 않으시면 '기본일지' 또는 '간단일지'로 전환해주세요"
        }
      />
      <DialogPopup
        isOpen={isNoWorkoutError}
        setIsOpen={setIsNoWorkoutError}
        title={"추가 입력이 필요한 값이 있어요"}
        body={"상세일지 및 기본일지는 1개이상의 운동이 추가되어야 해요"}
      />

      <DialogPopup
        isOpen={isRequireDataMissingError}
        setIsOpen={setIsRequireDataMissingError}
        title={"필수 입력값을 입력하지 않았어요"}
        body={"필수 입력 값을 입력해주세요"}
      />
      {isFutureReportError && setIsFutureReportError && (
        <DialogPopup
          isOpen={isFutureReportError}
          setIsOpen={setIsFutureReportError}
          title={"미래 시간의 일지를 작성하셨어요"}
          body={"미래 일지는 임시저장만 가능해요"}
        />
      )}
    </>
  );
}
