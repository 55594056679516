import { SVGProps } from "react";

export default function IcArrow({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0809 11.9997L7.54053 5.45928L8.45977 4.54004L15.9194 11.9997L8.45977 19.4593L7.54053 18.54L14.0809 11.9997Z"
        stroke="currentColor"
      />
    </svg>
  );
}
