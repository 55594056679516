import React, { Suspense, useEffect, useState } from "react";
import { Sidebar, SidebarState } from "../../component/Sidebar/Sidebar";
import SidebarContext from "../../component/Sidebar/sidebarContext";
import Loading from "design-system/components/Loading/Loading";
import { CrmTitle } from "../../component/CrmTitle";
import { css } from "@emotion/react";
import {
  CrmContentHorizontalPadding,
  CrmContentVerticalPadding,
} from "../../component/crmCSS";
import { CrmHeader } from "../../../common/Header/CrmHeader";
import { colors } from "design-system/styles/colors";
import { ListTabContent } from "./ListTabContent/ListTabContent";
import { useSearchParams } from "react-router-dom";
import Button from "design-system/components/Button/Button";
import IcPlus from "design-system/components/atom/IconResource/Icon/IcPlus";
import { useNavigate } from "react-router";

export function ReportListPage() {
  const [sidebarState, setSidebarState] = useState<SidebarState>("all");
  const [searchParams] = useSearchParams();
  const state = searchParams.get("state");
  const navigate = useNavigate();

  useEffect(() => {
    console.log("리포트 리스트 페이지");
    if (state && (state as SidebarState)) {
      setSidebarState(state as SidebarState);
    }
  }, []);

  const onWriteReportButtonClick = () => {
    navigate("/crm/report/create/direct-create");
  };

  return (
    <div css={containerCSS}>
      <CrmHeader />
      <div css={contentsContainerCSS}>
        <SidebarContext.Provider value={{ sidebarState, setSidebarState }}>
          <Sidebar />
          <Suspense fallback={<Loading />}>
            <div css={contentInnerContainerCSS}>
              <CrmTitle title={"일지 리스트"} />
              <ListTabContent />
              <Button
                variant={"primary"}
                css={buttonCSS}
                size={46}
                onClick={onWriteReportButtonClick}
              >
                <IcPlus
                  css={css`
                    color: ${colors.black};
                  `}
                />
                일지 작성하기
              </Button>
            </div>
          </Suspense>
        </SidebarContext.Provider>
      </div>
    </div>
  );
}

const buttonCSS = css`
  position: sticky;
  bottom: 100px;
  border-radius: 100px;
  right: 100px;
  margin-left: auto;
  width: 140px;
`;

const contentInnerContainerCSS = css`
  padding: ${CrmContentVerticalPadding}px ${CrmContentHorizontalPadding}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  position: relative;
  overflow: visible;
`;

const contentsContainerCSS = css`
  display: flex;
  width: 100%;
  min-height: 100vh;
`;

const containerCSS = css`
  background: ${colors.gray25};
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;
