import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { queryClient, queryKeys } from "libs/react-query";
import { getList } from "api/trainer/list/list";
import { centerDistanceWithUnit } from "api/admin/adminTypes";
import { EventName, ScreenName, useAmplitude } from "../../hoock/useAmplitude";

import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { MainHeader } from "common/Header/MainHeader";
import { ImageView } from "design-system/components/ImageView/ImageView";
import { RecommendDescription } from "./component/RecommendDescription";
import Button from "design-system/components/Button/Button";
import * as Dialog from "design-system/components/Dialog/Dialog";
import DaumPostcode from "react-daum-postcode";
import avatarImage from "design-system/components/atom/IconResource/avatar_image.svg";
import closeIcon from "design-system/components/atom/IconResource/ic_close.svg";
import IcClose from "design-system/components/atom/IconResource/Icon/IcClose";
import TrainerSearchListContext from "./trainerSearchListContext";
import { getWidthMediaQuery } from "../../styles/mediaQueries";

export function TrainerSearchListPage() {
  const navigate = useNavigate();
  const [address, setAddress] = useState<string | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const { sendClickEvent } = useAmplitude();

  const { data: trainers } = useQuery({
    queryFn: () => getList({ address }),
    queryKey: queryKeys.getList({ address }),
  });

  useEffect(() => {
    queryClient.invalidateQueries([queryKeys.getList({ address })]);
  }, [address]);

  return (
    <div css={containerCSS}>
      <TrainerSearchListContext.Provider value={{ address, setAddress }}>
        <MainHeader />
        <RecommendDescription />
        <div css={locationButtonContainerCSS}>
          <Button
            css={locationButtonCSS}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            {address ? "위치 재설정하기" : "내 주변 트레이너로 찾기"}
          </Button>
          {address && <div css={locationTextCSS}>{address}</div>}
          {address && (
            <Button
              variant="icon"
              styles={locationDeleteButtonCSS}
              onClick={() => setAddress(undefined)}
            >
              <IcClose />
            </Button>
          )}
        </div>
        <div css={listContainerCSS}>
          {trainers?.content.map(({ trainer, distance }) => (
            <button
              key={trainer.id}
              css={trainerItemButtonCSS}
              onClick={() => {
                sendClickEvent({
                  eventName: EventName.web_search_trainer_detail_clicked,
                  screenName: ScreenName.web_search_trainer_main,
                });
                navigate(`/trainer/${trainer.id}?dist=${distance}`);
              }}
            >
              <>
                <div css={imageWrapperCSS}>
                  <ImageView
                    src={trainer.image?.url ?? avatarImage}
                    styles={trainerImageCSS}
                    isClickable={false}
                  />
                </div>
                <div css={trainerItemInfoContainerCSS}>
                  <div css={nameContainerCSS}>
                    {`${trainer.name} 트레이너`}
                    {trainer.profilePercent > 70 && (
                      <div css={recommendBadgeCSS}>{"추천"}</div>
                    )}
                  </div>
                  <div css={centerCSS}>
                    <span>{trainer.centerInfo?.name}</span>
                    {distance && (
                      <span>{` | ${centerDistanceWithUnit(distance)}`}</span>
                    )}
                  </div>
                </div>
              </>
            </button>
          ))}
        </div>
        <Dialog.Root variant={"page"} open={isOpen}>
          <Dialog.Content css={dialogCSS}>
            <div css={loginPopupContainerCSS}>
              <div css={loginPopupHeaderContainerCSS}>
                <img
                  src={closeIcon}
                  width={38}
                  height={38}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                />
              </div>
            </div>
            <DaumPostcode
              style={{ width: "100%", height: "100%" }}
              onComplete={(result) => {
                setAddress(result.roadAddress);
                setIsOpen(false);
              }}
            />
          </Dialog.Content>
        </Dialog.Root>
      </TrainerSearchListContext.Provider>
    </div>
  );
}

const dialogCSS = css`
  height: 750px;
  width: 600px;
  padding: 5px 0 15px 0;
`;

const loginPopupHeaderContainerCSS = css`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

const loginPopupContainerCSS = css`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const locationButtonContainerCSS = css`
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 50px 0 50px 0;
  justify-content: center;

  ${getWidthMediaQuery("pc")} {
    justify-content: flex-start;
    margin-left: 10vw;
  }
`;

const locationTextCSS = css`
  display: flex;
  align-items: center;
  color: ${colors.gray400};
  ${typography.mobile.body1}
`;

const locationDeleteButtonCSS = css`
  color: ${colors.gray400};
`;

const locationButtonCSS = css`
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  color: white;
  ${typography.mobile.heading2};
  width: fit-content;
  padding: 16px 24px;
`;

const containerCSS = css`
  display: flex;
  flex-direction: column;
  background: black;
  padding-bottom: 0;
  width: 100%;
  overflow: hidden;
`;

const listContainerCSS = css`
  display: grid;
  width: 100%;
  justify-content: center;
  grid-gap: 16px;
  padding: 25px 10vw;
  padding-bottom: 80px;

  ${getWidthMediaQuery("pc")} {
    align-items: flex-start;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const trainerItemButtonCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${colors.gray25};
  border-radius: 8px;
  background: #22251e;
  padding: 0;

  ${getWidthMediaQuery("pc")} {
    width: 255px;
    height: 310px;
  }
`;

const imageWrapperCSS = css`
  width: 100%;
`;

const trainerImageCSS = css`
  width: 100%;
  height: 195px;
  border-radius: 8px 8px 0 0;
`;

const trainerItemInfoContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 17.5px 16px;
  gap: 10px;
  text-align: start;
`;

const nameContainerCSS = css`
  display: flex;
  gap: 8px;
  align-items: center;
  ${typography.mobile.heading2};
`;

const recommendBadgeCSS = css`
  display: flex;
  width: fit-content;
  height: fit-content;
  padding: 3.5px 5.5px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: ${colors.gray25};
  color: ${colors.gray900};
  ${typography.mobile.body3};
`;

const centerCSS = css`
  ${typography.mobile.body2};
`;
