/** @jsxImportSource @emotion/react */

import * as DialogPrimitive from "@radix-ui/react-dialog";
import DialogContext from "./dialogContext";
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  HTMLAttributes,
  useContext,
} from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import { fadeIn, fadeOut } from "../../styles/keyframes";
import { colors } from "../../styles/colors";
import Button, {
  buttonSizes,
  buttonVariantStyles,
  defaultButtonCSS,
} from "../Button/Button";
import { typography } from "design-system/styles/typography/typography";

type DialogVariant = "alert" | "page" | "default";

export type DialogRootProps = DialogPrimitive.DialogProps & {
  variant?: DialogVariant;
  isAlertDialog?: boolean;
  isBorderRadius?: boolean;
  overlay?: number;
};

function Root({
  variant = "alert",
  isAlertDialog,
  children,
  isBorderRadius = true,
  overlay = 0,
  ...props
}: DialogRootProps) {
  return (
    <DialogContext.Provider
      value={{
        variant,
        isAlertDialog,
        isBorderRadius,
        overlay,
      }}
    >
      <DialogPrimitive.Root {...props}>
        <DialogOverlay styles={{ zIndex: `${50 + overlay}` }} />
        {children}
      </DialogPrimitive.Root>
    </DialogContext.Provider>
  );
}

const Trigger = DialogPrimitive.Trigger;

const DialogPortal = ({ ...props }: DialogPrimitive.DialogPortalProps) => (
  <DialogPrimitive.Portal {...props} />
);

const DialogOverlay = forwardRef<
  ElementRef<typeof DialogPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    css={[dialogOverlayCSS, styles]}
    {...props}
  />
));

DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const Content = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    styles?: Interpolation<Theme>;
  }
>(({ children, styles, onPointerDownOutside, ...props }, ref) => {
  const dialogContext = useContext(DialogContext);

  return (
    <DialogPortal>
      <DialogPrimitive.Content
        ref={ref}
        css={[
          dialogContentCSS,
          dialogContext?.variant === "page" && sizePage,
          // dialogContext?.variant === "alert" && sizeAlert,
          // dialogContext?.isBorderRadius ? borderRadius : noBorderRadius,
          styles,
          { zIndex: `${50 + (dialogContext?.overlay ?? 0)}` },
        ]}
        onPointerDownOutside={
          dialogContext?.isAlertDialog
            ? (event) => event.preventDefault()
            : onPointerDownOutside
        }
        {...props}
      >
        {children}
      </DialogPrimitive.Content>
    </DialogPortal>
  );
});

Content.displayName = DialogPrimitive.Content.displayName;

const Header = ({
  styles,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  styles?: Interpolation<Theme>;
}) => {
  const dialogContext = useContext(DialogContext);

  return (
    <div
      css={[
        dialogHeaderCSS,
        dialogContext?.variant === "page" && dialogHeaderVariantPageCSS,
        styles,
      ]}
      {...props}
      data-dialog-header
    />
  );
};

Header.displayName = "DialogHeader";

const Body = ({
  styles,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  styles?: Interpolation<Theme>;
}) => {
  const dialogContext = useContext(DialogContext);

  return (
    <div
      css={[
        dialogBodyCSS,
        dialogContext?.variant === "page" && dialogBodyVariantPageCSS,
        styles,
      ]}
      {...props}
      data-dialog-body
    />
  );
};

Body.displayName = "DialogBody";

const Footer = ({
  styles,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  styles?: Interpolation<Theme>;
}) => {
  const dialogContext = useContext(DialogContext);

  return (
    <div
      css={[
        dialogFooterCSS,
        dialogContext?.variant === "page" && dialogFooterVariantPageCSS,
        styles,
      ]}
      {...props}
      data-dialog-footer
    />
  );
};

Footer.displayName = "DialogFooter";

const Title = forwardRef<
  ElementRef<typeof DialogPrimitive.Title>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Title> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    css={[dialogTitleCSS, styles]}
    data-dialog-title
    {...props}
  />
));

Title.displayName = DialogPrimitive.Title.displayName;

const Description = forwardRef<
  ElementRef<typeof DialogPrimitive.Description>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Description> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    css={[dialogDescriptionCSS, styles]}
    data-dialog-description
    {...props}
  />
));

Description.displayName = DialogPrimitive.Description.displayName;

const Close = forwardRef<
  ElementRef<typeof DialogPrimitive.Close>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Close> & {
    variant?: ComponentProps<typeof Button>["variant"];
    size?: ComponentProps<typeof Button>["size"];
    styles?: Interpolation<Theme>;
  }
>(({ variant = "primary", size = "auto", styles, asChild, ...props }, ref) => (
  <DialogPrimitive.Close
    ref={ref}
    css={[
      !asChild && defaultButtonCSS,
      !asChild && variant && buttonVariantStyles[variant],
      !asChild && size && buttonSizes[size],
      !asChild && styles,
    ]}
    asChild={asChild}
    {...props}
  />
));

Close.displayName = DialogPrimitive.Close.displayName;

export {
  Root,
  Trigger,
  Content,
  Header,
  Body,
  Footer,
  Title,
  Description,
  Close,
};

const dialogOverlayCSS = css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: 50;
  background-color: rgba(28, 30, 34, 0.85);

  &[data-state="open"] {
    animation: ${fadeIn} 0.35s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }

  &[data-state="closed"] {
    animation: ${fadeOut} 0.35s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }
`;

const dialogContentCSS = css`
  position: fixed;
  z-index: 50;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  min-width: 312px;
  max-width: calc(100% - 48px);
  background-color: ${colors.gray25};
  border-radius: 16px;
  opacity: 0;
  overflow: hidden;

  &[data-state="open"] {
    animation: ${fadeIn} 0.35s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }

  &[data-state="closed"] {
    animation: ${fadeOut} 0.35s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }
`;

const sizePage = css`
  width: 450px;
`;

const dialogHeaderCSS = css`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 1px 24px;
`;

const dialogHeaderVariantPageCSS = css`
  padding: 24px 24px 1px 24px;
`;

const dialogBodyCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 24px;
`;

const dialogBodyVariantPageCSS = css`
  padding: 0 24px;
`;
const dialogFooterCSS = css`
  display: flex;
  padding: 0 24px 24px 24px;
  justify-content: flex-end;

  [data-dialog-header] + &[data-dialog-footer] {
    padding-top: 32px;
  }
`;

const dialogFooterVariantPageCSS = css`
  padding: 0 24px 24px 24px;
`;

const dialogTitleCSS = css`
  text-align: center;
  color: ${colors.default.Black};
  ${typography.mobile.heading3};

  &[data-dialog-title] ~ [data-dialog-description] {
    margin-top: 8px;
  }
`;

const dialogDescriptionCSS = css`
  text-align: center;
  color: ${colors.gray600};
  ${typography.mobile.body2};
`;
