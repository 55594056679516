import * as Dialog from "design-system/components/Dialog/Dialog";
import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import React from "react";
import IcClose from "design-system/components/atom/IconResource/Icon/IcClose";

interface DialogMobilePageProps {
  isOpen: boolean;
  setIsOpen?: (state: boolean) => void;
  isBlackHeader?: boolean;
  onCloseAction?: () => void;
  title?: string;
  children?: React.ReactNode; // children 타입 추가
}

export function DialogMobilePage({
  isOpen,
  setIsOpen,
  title,
  isBlackHeader = true,
  onCloseAction,
  children,
}: DialogMobilePageProps) {
  return (
    <Dialog.Root variant={"page"} open={isOpen}>
      <Dialog.Content
        css={css`
          width: 500px;
        `}
      >
        <div css={containerCSS}>
          <div css={headerContainerCSS(isBlackHeader)}>
            {title && <span css={headerTitleCSS(isBlackHeader)}>{title}</span>}
            <IcClose
              css={closeIconCSS(isBlackHeader)}
              onClick={() => {
                setIsOpen?.(false);
              }}
            />
          </div>
          <div>{children}</div>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}

const headerTitleCSS = (isBlackHeader: boolean) => css`
  margin: 0 auto; /* 가운데 정렬 */
  color: ${isBlackHeader ? colors.gray25 : colors.black};
`;

const closeIconCSS = (isBlackHeader: boolean) => css`
  width: 38px;
  height: 38px;
  color: ${isBlackHeader ? colors.gray25 : colors.black};
`;

const bodyContainerCSS = css`
  display: flex;
  background: black;
  flex-direction: column;
`;

const headerContainerCSS = (isBlackHeader: boolean) => css`
  display: flex;
  width: 100%;
  background: ${isBlackHeader ? colors.black : colors.gray25};
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

const containerCSS = css`
  display: flex;
  flex-direction: column;
`;
