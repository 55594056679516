import { css } from "@emotion/react";
import { useQuery } from "@tanstack/react-query";
import { getMe } from "api/trainer/me/me";
import { getSchedules } from "api/trainer/schedules/schedules";
import scheduleContext from "crm/schedule/scheduleContext";
import { colors } from "design-system/styles/colors";
import {
  fontWeight,
  typography,
} from "design-system/styles/typography/typography";
import { queryKeys } from "libs/react-query";
import { useContext } from "react";

export function TimeLine() {
  const {
    setScheduleType,
    sidebarState,
    setSidebarState,
    selectedDate,
    setSelectedDate,
    selectedWeek,
    scheduleSlotId,
    setscheduleSlotId,
  } = useContext(scheduleContext);
  const currentTime = new Date();
  const getMin = (date: Date) => {
    return (date.getHours() * 60 + date.getMinutes()) / 14.4;
  };

  const getMinDist = (startAt: string, endAt: string) => {
    return getMin(new Date(endAt)) - getMin(new Date(startAt));
  };

  const { data: trainer } = useQuery({
    queryFn: () => getMe(),
    queryKey: queryKeys.getMe(),
  });

  const { data: schedule } = useQuery({
    queryFn: () =>
      getSchedules({
        year: selectedDate?.getFullYear() ?? currentTime.getFullYear(),
        month: (selectedDate?.getMonth() ?? currentTime.getMonth()) + 1,
      }),
    queryKey: queryKeys.getSchedules({
      year: selectedDate?.getFullYear() ?? currentTime.getFullYear(),
      month: (selectedDate?.getMonth() ?? currentTime.getMonth()) + 1,
    }),
  });

  return (
    <div css={containerCSS}>
      <div css={timeLineListCSS}>
        <div
          css={css`
            position: relative;
            width: 100%;
            height: 100%;
          `}
        >
          <div css={timeListCSS}>
            {[...Array(144)].map((_, i) =>
              i % 6 ? (
                <div
                  key={i}
                  css={[
                    timeCSS,
                    css`
                      color: ${colors.lightGray};
                    `,
                  ]}
                >
                  -
                </div>
              ) : (
                <div key={i} css={timeCSS}>{`${i / 6}:00`}</div>
              ),
            )}
          </div>
          <div css={lineListCSS}>
            {[...Array(144)].map((_, i) =>
              i % 6 ? (
                <div key={i} css={subLineCSS}></div>
              ) : (
                <div key={i} css={primaryLineCSS}></div>
              ),
            )}
          </div>
          <div css={boxContainerCSS}>
            {selectedWeek?.map((date) =>
              date.getMonth() === selectedDate?.getMonth() ? (
                <div
                  key={date.getDate()}
                  css={boxListCSS}
                  onClick={() => {
                    setSelectedDate(date);
                    setSidebarState("create");
                    setScheduleType("PT");
                  }}
                >
                  {schedule?.content
                    .filter(
                      (item) =>
                        new Date(item.scheduleAt).getDate() === date.getDate(),
                    )
                    .map(
                      ({
                        scheduleType,
                        traineeName,
                        orders,
                        startAt,
                        endAt,
                        ...param
                      }) => (
                        <div onClick={(e) => e.stopPropagation()}>
                          <div
                            css={
                              sidebarState === "read" &&
                              param.scheduleSlotId === scheduleSlotId
                                ? selectedBoxCSS
                                : boxCSS
                            }
                            id={param.scheduleSlotId.toString()}
                            key={param.scheduleSlotId}
                            style={{
                              top: `${getMin(new Date(startAt))}%`,
                              height: `${getMinDist(startAt, endAt)}%`,
                            }}
                            onClick={() => {
                              setScheduleType(scheduleType);
                              setSidebarState("read");
                              setscheduleSlotId(param.scheduleSlotId);
                            }}
                          >
                            {scheduleType.toUpperCase() === "PT" ? (
                              <>
                                <span
                                  css={boxTitleCSS}
                                >{`${traineeName} ${orders}회차`}</span>
                                <span
                                  css={boxDescriptionCSS}
                                >{`${trainer?.name} 트레이너`}</span>
                              </>
                            ) : (
                              <span css={boxTitleCSS}>{param.title}</span>
                            )}
                          </div>
                        </div>
                      ),
                    )}
                </div>
              ) : (
                <div key={date.getDate()} css={boxListCSS} />
              ),
            )}
          </div>
        </div>
        <div
          id="timeline"
          css={currentTimeLineContainerCSS}
          style={{
            // 시간을 총분으로 환산 -> 10분당 24px 의 간격을 가짐
            top: `${((currentTime.getHours() * 60 + currentTime.getMinutes()) / 10) * 24 - 3}px`,
          }}
        >
          <div css={currentTimeCircleCSS}></div>
          <div css={currentTimeLineCSS}></div>
        </div>
      </div>
    </div>
  );
}

const containerCSS = css`
  background-color: ${colors.lightGray};
  display: flex;
  padding: 68px 60px 40px;
  overflow-y: scroll;
  height: 600px;
`;

const timeLineListCSS = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const timeListCSS = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: -32px;
  width: 100%;
  height: 100%;
  /* top: 21px; */
  top: -7px;
`;

const timeCSS = css`
  display: flex;
  width: 28px;
  height: 12px;
  color: ${colors.gray600};
  ${typography.mobile.body4};
`;

const lineListCSS = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: absolute;
  /* padding-top: 28px; */
`;

const primaryLineCSS = css`
  width: 100%;
  height: 24px;
  border-top: 1px solid rgba(43, 67, 90, 0.16);
  align-self: stretch;
`;

const subLineCSS = css`
  width: 100%;
  height: 24px;
  border-top: 1px solid rgba(43, 67, 90, 0.06);
  align-self: stretch;
`;

const boxContainerCSS = css`
  position: absolute;
  display: flex;
  gap: 2px;
  justify-content: space-between;
  align-self: stretch;
  overflow-x: scroll;
  height: 3456px;
  width: 100%;
  /* padding-top: 28px; */
`;

const boxListCSS = css`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  cursor: pointer;
  height: 100%;
`;

const boxCSS = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 124px;
  height: 111px;
  padding: 10px 33px 78px 12px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${colors.gray50};
  background: ${colors.gray25};
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.04);
`;

const selectedBoxCSS = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 124px;
  height: 111px;
  padding: 10px 33px 78px 12px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${colors.lime600};
  background: ${colors.lime50};
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.04);
`;

const boxTitleCSS = css`
  color: ${colors.gray900};
  ${typography.mobile.body2};
  font-weight: ${fontWeight.SemiBold};
`;

const boxDescriptionCSS = css`
  color: ${colors.gray400};
  ${typography.mobile.body3};
  /* white-space: nowrap;
                                                                                                                                                                                                                                        overflow: hidden;
                                                                                                                                                                                                                                        text-overflow: ellipsis; */
`;

const currentTimeLineContainerCSS = css`
  position: absolute;
  display: flex;
  width: 100%;
  overflow-x: scroll;
  padding-top: 3px;
`;

const currentTimeCircleCSS = css`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${colors.blue};
  flex-shrink: 0;
  margin-top: -3px;
`;

const currentTimeLineCSS = css`
  top: 3px;
  width: 100%;
  height: 24px;
  border-top: 1px solid ${colors.blue};
  align-self: stretch;
`;
