import {
  ChangeEvent,
  ComponentProps,
  forwardRef,
  InputHTMLAttributes,
  MouseEvent,
  useEffect,
  useState,
} from "react";

import Button from "design-system/components/Button/Button";
import PfDelete from "design-system/components/atom/IconResource/Icon/PfDelete";
import PfPhoto from "design-system/components/atom/IconResource/Icon/PfPhoto";
import {
  ImageView,
  imageViewVariants,
} from "design-system/components/ImageView/ImageView";
import { Z_INDEX } from "styles/styles";
import { colors } from "design-system/styles/colors";
import { css } from "@emotion/react";

interface ImageSelectorProps {
  variant?: ComponentProps<typeof ImageView>["variant"];
  src?: ComponentProps<typeof ImageView>["src"];
  onImageRemove?: () => void;
  isEditable?: boolean;
  isVideoProps?: boolean;
  isRemovable?: boolean;
  isShowTempFile?: boolean;
  isWorkoutItem: boolean; // 일지 작성용 workout 일 경우 requeset 에 담길 이미지를 보여주고 있어 2번 랜더링 되는 이슈가 있어 분기 됨
  videoThumbNailProps?: string;
  styles?: ComponentProps<typeof ImageView>["styles"];
}

export const ImageSelector = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & ImageSelectorProps
>(
  (
    {
      variant = "box",
      src,
      onChange,
      onImageRemove,
      isEditable = false,
      isRemovable = true,
      isShowTempFile = true,
      isVideoProps = false,
      isWorkoutItem = false,
      videoThumbNailProps,
      accept = "image/jpg,image/png,image/jpeg,image/gif,video/mp4,video/quicktime",
      styles,
      ...props
    },
    ref,
  ) => {
    const [srcState, setSrcState] = useState(src);
    const [videoThumbNail, setVideoThumbNail] = useState(videoThumbNailProps);
    const [isVideo, setIsVideo] = useState(isVideoProps); // New state for video detection
    const [isLoading, setIsLoading] = useState(false);

    const extractVideoThumbnail = (fileURL: string) => {
      const video = document.createElement("video");
      const canvas = document.createElement("canvas");

      video.src = fileURL;
      video.currentTime = 1; // 1초로 설정하여 첫 프레임 추출

      video.onloadeddata = () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext("2d");
        if (ctx) {
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const thumbnailURL = canvas.toDataURL("image/png");
          setVideoThumbNail(thumbnailURL); // 썸네일 URL 설정
        }
      };
    };

    const onChangeHandling = async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;
      const isFileVideo = file.type.startsWith("video/");
      setIsVideo(isFileVideo || isVideoProps); // 파일이 비디오인지 확인
      if (file && isFileVideo) {
        const fileURL = URL.createObjectURL(file); // Blob URL 생성
        if (!videoThumbNail) extractVideoThumbnail(fileURL);
        setSrcState(fileURL);
      } else if (isWorkoutItem && file) {
        // 이슈: 이미지가 두번 로딩 되는 이슈 존재(고용량일 경우 첫번째 로딩이후 전송하게될 경우 정상적으로 이미지 첨부 안됨)
        // 대응: 해당 부분에서 SetSrcState 하는 로직을 빼고 imageDetail에 의해 한번만 보여주게 수정
        // 기타: 대응한 방법에 의해서 섬네일이 보여지기까지 이미지가 보이지 않아 명시적으로 이미지뷰에 로딩 표시
        if (!srcState) {
          setIsLoading(true);
        }
      } else {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onloadend = () => {
          if (!fileReader.result || fileReader.result instanceof ArrayBuffer) {
            return setSrcState(undefined);
          }
          setSrcState(fileReader.result as string); // 이미지일 경우 처리
        };
      }
      onChange?.(event);
    };

    const onImageRemoveHandling = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onImageRemove?.();
      setIsVideo(false);
      setSrcState(undefined);
      setVideoThumbNail(undefined);
    };

    useEffect(() => {
      setSrcState(src);
    }, [src]);

    return (
      <div css={imageSelectorWrapperCSS}>
        {srcState && isRemovable && (
          <Button
            variant="icon"
            onClick={onImageRemoveHandling}
            styles={removeButtonCSS}
          >
            <PfDelete css={removeIconCSS} />
          </Button>
        )}
        <label
          css={[
            imageSelectorCSS,
            variant === "box" && imageViewVariants.box,
            variant === "box" && !srcState && imageSelectorLayoutGrayCSS,
            variant === "circle" && imageViewVariants.circle,
            variant === "circle" &&
              !srcState && [
                imageSelectorLayoutBlueCSS,
                imageSelectorVariantCircleBackgroundCSS,
              ],
            variant === "square" && imageViewVariants.square,
            variant === "square" && !srcState && imageSelectorLayoutGrayCSS,
            styles,
          ]}
        >
          <div
            css={[
              imageSelectorIndicatorCSS,
              variant === "box" && imageViewVariants.box,
              variant === "circle" && imageViewVariants.circle,
              variant === "square" && imageViewVariants.square,
              srcState && !isEditable && imageSelectorIndicatorImageExistCSS,
              srcState && isEditable && imageSelectorEditableCSS,
            ]}
          >
            <PfPhoto
              data-indicator
              css={[
                photoIconCSS,
                srcState && isEditable && photoIconImageExistCSS,
              ]}
            />
          </div>
          {(isEditable || !srcState) && (
            <input
              css={imageSelectorInputCSS}
              type="file"
              accept={accept}
              ref={ref}
              onChange={onChangeHandling}
              {...props}
            />
          )}
          {srcState && isVideo ? ( // 비디오일 경우 비디오 렌더링
            <div css={videoSelectorIndicatorCSS}>
              <ImageView
                styles={imageSelectorImageCSS}
                variant={variant}
                videoSrc={srcState}
                src={videoThumbNail}
                type={"video"}
              />
            </div>
          ) : (
            (srcState || isLoading) && (
              <ImageView
                styles={imageSelectorImageCSS}
                variant={variant}
                src={srcState}
                isSrcLoading={isLoading}
              />
            )
          )}
        </label>
      </div>
    );
  },
);

ImageSelector.displayName = "imageSelector";

const imageSelectorWrapperCSS = css`
  position: relative;
  width: fit-content;
`;

const imageSelectorCSS = css`
  position: relative;
  display: inline-flex;
  cursor: pointer;
`;

const videoSelectorIndicatorCSS = css`
  position: relative; /* 자식 요소들이 절대 위치를 사용할 수 있도록 설정 */
  display: inline-block; /* 필요한 경우 요소를 블록처럼 설정 */
`;

const imageSelectorIndicatorCSS = css`
  //position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: ${Z_INDEX.content};
`;

const imageSelectorLayoutGrayCSS = css`
  border: 1px dashed ${colors.default.Black_a10};
`;

const imageSelectorLayoutBlueCSS = css`
  border: 1px dashed ${colors.default.Gray800};
`;

const imageSelectorVariantCircleBackgroundCSS = css`
  background-color: ${colors.default.Gray800};
`;

const imageSelectorIndicatorImageExistCSS = css`
  opacity: 0;
`;

const imageSelectorEditableCSS = css`
  background: linear-gradient(
    ${colors.default.Black_a70} 100%,
    ${colors.default.Black_a70} 100%
  );

  opacity: 0;

  :hover {
    opacity: 1;
  }
`;

const imageSelectorImageCSS = css`
  //position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

const photoIconCSS = css`
  color: ${colors.default.Gray};
`;

const photoIconImageExistCSS = css`
  color: ${colors.default.White};
`;

const imageSelectorInputCSS = css`
  width: 0;
  opacity: 0;
`;

const removeButtonCSS = css`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 24px;
  height: 24px;
  z-index: 2;
`;

const removeIconCSS = css`
  width: 24px;
  height: 24px;
  color: ${colors.default.Black_a70};
`;
