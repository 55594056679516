import { createContext } from "react";

export interface DirectCreateContext {
  isScheduleDialogOpen: boolean;
  setIsScheduleDialogOpen?: (state: boolean) => void;
  scheduleDataContextProps: ScheduleDataContextProps;
}

export interface ScheduleDataContextProps {
  userId?: number;
  setUserId?: (id: number) => void;
  ticketId?: number;
  setTicketId?: (id: number) => void;
  startAt?: Date;
  setStartAt?: (at: Date) => void;
  endAt?: Date;
  setEndAt?: (at: Date) => void;
}

export default createContext<DirectCreateContext>({
  isScheduleDialogOpen: false,
  setIsScheduleDialogOpen: undefined,
  scheduleDataContextProps: {},
});
