import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";

interface InfoBadgeProps {
  text: string;
}

export function InfoBadge({ text }: InfoBadgeProps) {
  return <div css={rootCSS}>{text}</div>;
}

const rootCSS = css`
  display: flex;
  width: fit-content;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 0.6px solid #fff;
  background: linear-gradient(
    93deg,
    rgba(242, 255, 168, 0.06) -5.88%,
    rgba(224, 245, 105, 0.33) 94.78%
  );
  color: ${colors.gray25};
  ${typography.mobile.body2};
`;
