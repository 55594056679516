import { TrainerMyInfoDTO } from "../trainer/me/meTypes";
import { LessonTrainerLessonSummaryDTO } from "../trainer/lessons/lessonsTypes";
import { LessonUserLessonDetailDTO } from "../trainer/users/usersTypes";
import { Gender, ReportType } from "../common/commonEnumType";
import { AttachFileDTO } from "../common/commonTypes";

export type GetTrainersLessonsListResponse = ListWrapperTrainerLessonDTO;

export type ListWrapperTrainerLessonDTO = {
  content: AdminTrainerLessonDTO[];
};
export type AdminTrainerLessonDTO = {
  trainer: TrainerMyInfoDTO;
  lessons: LessonTrainerLessonSummaryDTO[];
};

export type GetTrainersLessonsLessonIdResponse = LessonUserLessonDetailDTO;

export type GetTrainersLessonListLessonIdRequest = {
  trainerId: string;
  userId: string;
  workoutId: string;
};

export type GetTrainersResponse = ListWrapperTrainerMyInfo;

export type ListWrapperTrainerMyInfo = {
  content: TrainerMyInfoDTO[];
};

export type GetTraineesResponse = ListWrapperMyInfo;

export type ListWrapperMyInfo = {
  content: MyInfoDTO[];
};

export interface MyInfoDTO {
  name?: string;
  birthdate?: string;
  phone?: string;
  gender?: Gender;
  email: string;
  lessonResultType: ReportType;
  isVerified: boolean;
}

export type CenterDTO = {
  name?: string;
  tel?: string;
  address?: string;
  additionalInfo?: string;
};

export type TrainerDetailDTO = {
  id: number;
  name?: string;
  speciality: TrainerType;
  mainImage?: AttachFileDTO;
  images: AttachFileDTO[];
  event?: string;
  selfIntroduction?: string;
  workoutTags: WorkoutTagType[];
  qualifications: QualificationsDto[];
  careers?: CareerDto[];
  prices: PriceDTO;
  centerInfo?: CenterDTO;
};

type TrainerType = "health" | "pilates";

type WorkoutTagType =
  | "improved_performance"
  | "guide_workout"
  | "body_profile"
  | "correction_pain_relief"
  | "muscle_fat_control"
  | "physical_strength"
  | "prepare_contest";

type QualificationsDto = {
  id: number;
  qualificationType: QualificationType;
  title?: string;
  degree?: DegreeType;
  university?: string;
  department?: string;
  isRelatedDepartment?: boolean;
  image?: string;
  verified: boolean;
};

type PriceDTO = {
  prices: PricePriceDTO[];
};

type PricePriceDTO = {
  cycle: number;
  price: number;
};

type CareerDto = {
  id: number;
  centerName: string;
  startDate: string;
  endDate: string;
};

type QualificationType = "certification" | "education" | "awards";

type DegreeType = "bachelors_degree" | "master" | "doctors_degree";

export type GetTrainersTrainerIdResponse = TrainerDetailDTO;

export const degreeTypeInKR = (degree: DegreeType) => {
  switch (degree) {
    case "bachelors_degree":
      return "학사";
    case "master":
      return "석사";
    case "doctors_degree":
      return "박사";
  }
};

export const centerDistanceWithUnit = (distance: number) => {
  if (distance < 1000) {
    return `${distance}m`;
  }
  return `${(distance / 1000).toFixed(1)}km`;
};
