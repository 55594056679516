import {
  RepeatType,
  ReportType,
  ScheduleType,
  TicketStatus,
  WorkoutParts,
} from "../../common/commonEnumType";
import { WorkoutDetailDTO, WorkoutSetDTO } from "../users/usersTypes";
import { AttachFileDTO } from "../../common/commonTypes";
import { WorkoutCategoryItemlDTO } from "../workouts/workoutsTypes";

export type GetSchedulesRequest = {
  year: number;
  month: number;
};

export type GetSchedulesResponse = { content: TrainerScheduleDTO[] };

export type GetSchedulesScheduleSlotIdRequest = {
  scheduleSlotId: number;
};

export type GetSchedulesScheduleSlotIdResponse = TrainerScheduleDTO;

export type PutSchedulesScheduleSlotIdRequest = {
  scheduleSlotId: number;
  title?: string;
  startAt: string;
  endAt: string;
  repeatType?: RepeatType;
  memo?: string;
  location?: string;
};

export type DeleteSchedulesScheduleSlotIdRequest = {
  scheduleSlotId: number;
};

export type DeleteSchedulesScheduleSlotIdFollowingRequest = {
  scheduleSlotId: number;
};

export type PostSchedulesScheduleSlotIdNoshowRequest = {
  scheduleSlotId: number;
  userId: number;
  message?: string;
};

export type PostSchedulesScheduleSlotIdLessonsRequest =
  RequestSaveLessonRequestDTO & {
    scheduleSlotId: number;
  };

export type PostSchedulesScheduleSlotIdLessonsResponse = {
  lessonId: number;
};

export type GetSchedulesScheduleSlotIdUsersUserIdTicketResponse =
  TicketTicketInfoDTO;

export type GetSchedulesScheduleSlotIdUsersUserIdTicketRequest = {
  scheduleSlotId: number;
  userId: number;
};

export type PostSchedulesRequest = {
  userId?: number;
  ticketId?: number;
  title?: string;
  memo?: string;
  startAt: string;
  endAt: string;
  scheduleType: ScheduleType;
  repeatType?: RepeatType;
  location?: string;
};

export interface TrainerScheduleDTO {
  scheduleSlotId: number;
  userId?: number;
  traineeName?: string;
  ticketId?: number;
  lessonId?: number;
  scheduleAt: string;
  orders: number;
  title: string;
  memo?: string;
  scheduleType: ScheduleType;
  startAt: string;
  endAt: string;
  location?: string;
  repeatType?: RepeatType;
  hasTemporaryLesson: boolean;
  isRead: boolean;
  isNoShow: boolean;
  traineeSignImageUrl?: string;
}

export interface TicketTicketInfoDTO {
  id: number;
  trainerId: number;
  traineeName: string;
  startAt: string;
  endAt?: string;
  centerName?: string;
  totalCount: number;
  usedCount: number;
  status: TicketStatus;
  price?: number;
  otherInfo?: string;
  code?: string;
}

export interface RequestSaveLessonRequestDTO {
  userId: number;
  ticketId: number;
  lessonResultType: ReportType;
  workoutTime?: number;
  totalFeedback?: string;
  workoutDetails?: RequestSaveLessonRequestWorkoutDetailDTO[];
}

export interface RequestSaveLessonRequestWorkoutDetailDTO {
  id: number;
  isCustom?: boolean;
  workoutName: string;
  part: WorkoutParts;
  sets: WorkoutSetDTO[];
  images: string[];
  videos: string[];
  feedback: string;
}

/*
예외적으로 섞어씀
 */
export function workoutDetailTORequestSaveLessonRequestWorkoutDetailDTO(
  detail: WorkoutDetailDTO,
): MixedWorkoutDetail {
  return {
    id: detail.id,
    isCustom: detail.isCustom,
    workoutName: detail.workoutName, // 대소문자 수정 필요
    part: detail.part,
    sets: detail.sets,
    images: detail.images.map((image) => image.url), // AttachFileDTO의 filePath로 변환
    videos: detail.videos.map((video) => video.url), // AttachFileDTO의 filePath로 변환
    feedback: detail.feedback || "",
    thumbnail: detail.thumbnail,
    imagesDetail: detail.images,
    videosDetail: detail.videos,
  };
}

export function workoutCategoriesDetailDTOTOMixedWorkoutDetail(
  workout: WorkoutCategoryItemlDTO,
): MixedWorkoutDetail {
  return {
    id: workout.id,
    isCustom: workout.part == "custom",
    workoutName: workout.name,
    sets: [],
    thumbnail: workout.thumbnail,
    feedback: "",
    part: workout.part,
    videosDetail: [],
    imagesDetail: [],
    images: [],
    videos: [],
  };
}

export type MixedWorkoutDetail = RequestSaveLessonRequestWorkoutDetailDTO & {
  thumbnail?: string;
  // 이미지와 비디오를 업로드할때 필요로 하는 데이터
  imagesDetail: CustomAttachFileDTO[];
  videosDetail: CustomAttachFileDTO[];
};

export type CustomAttachFileDTO = AttachFileDTO & { file?: File };

export type PostSchedulesCheckRequest = PostSchedulesRequest;
