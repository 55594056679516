import { css } from "@emotion/react";
import scheduleContext from "crm/schedule/scheduleContext";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { useContext } from "react";

export function ScehduleTitle() {
  const { setScheduleType, setSidebarState } = useContext(scheduleContext);

  return (
    <div css={containerCSS}>
      <span css={titleCSS}>스케줄</span>
      <div css={buttonContainerCSS}>
        <button
          css={buttonCSS}
          onClick={() => {
            setScheduleType("PT");
            setSidebarState("create");
          }}
        >
          PT 일정 추가
        </button>
        <button
          css={buttonCSS}
          onClick={() => {
            setScheduleType("ETC");
            setSidebarState("create");
          }}
        >
          기타 일정 추가
        </button>
      </div>
    </div>
  );
}

const containerCSS = css`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const titleCSS = css`
  ${typography.Tab};
  color: ${colors.gray900};
`;

const buttonContainerCSS = css`
  display: flex;
  gap: 8px;
`;

const buttonCSS = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  ${typography.mobile.body3};
  color: ${colors.gray900};
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid ${colors.gray900};

  // TODO: 버튼 hover 스타일 개선안 @sello
  /* :hover {
    background-color: ${colors.gray800};
    color: ${colors.gray25};
  } */
`;
