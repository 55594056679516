import { css } from "@emotion/react";
import {
  fontWeight,
  typography,
} from "design-system/styles/typography/typography";
import { colors } from "design-system/styles/colors";

import Button from "design-system/components/Button/Button";
import { useContext } from "react";
import scheduleContext from "crm/schedule/scheduleContext";
import IcClose from "design-system/components/atom/IconResource/Icon/IcClose";

import { useQuery } from "@tanstack/react-query";
import { getSchedulesScheduleSlotId } from "api/trainer/schedules/schedules";
import { getUsersUserId } from "api/trainer/users/users";
import { queryKeys } from "libs/react-query";
import { format } from "date-fns";
import { useNavigate } from "react-router";
import { NoshowDialog } from "./component/NoshowDialog/NoshowDialog";
import { DeleteScheduleDialog } from "./component/DeleteScheduleDialog/DeleteScheduleDialog";
import { tagCSS } from "../../../user-manage/component/LessonListDialog/LessonListDialog";
import { ImageView } from "design-system/components/ImageView/ImageView";

export function ReadSidebar() {
  const navigate = useNavigate();
  const { setSidebarState, scheduleSlotId = 0 } = useContext(scheduleContext);

  const { data: schedule } = useQuery({
    queryFn: () => getSchedulesScheduleSlotId({ scheduleSlotId }),
    queryKey: queryKeys.getSchedulesScheduleSlotId({ scheduleSlotId }),
    enabled: scheduleSlotId !== undefined,
  });

  const { data: user } = useQuery({
    queryFn: () => getUsersUserId(schedule?.userId ?? 0),
    queryKey: queryKeys.getUsersUserId(schedule?.userId ?? 0),
    enabled:
      schedule !== undefined && schedule.scheduleType.toUpperCase() === "PT",
  });

  return (
    <div css={containerCSS}>
      {schedule && (
        <>
          <div css={contentContainerCSS}>
            <div css={closeButtonContainerCSS}>
              <Button
                variant="icon"
                onClick={() => {
                  setSidebarState(undefined);
                }}
              >
                <IcClose />
              </Button>
            </div>
            <div css={scheduleContainerCSS}>
              {user && schedule.scheduleType.toUpperCase() === "PT" ? (
                <>
                  <div css={contentTextContainerCSS}>
                    <div css={headerContainerCSS}>
                      <div css={contentTitleTextCSS}>{user.name}</div>
                      <div css={tagCSS("black", "white")}>
                        {schedule.traineeSignImageUrl
                          ? "사인완료"
                          : "사인미완료"}
                      </div>
                    </div>
                    <div css={bodyContainerCSS}>
                      <div css={bodyTextContainerCSS}>
                        <div
                          css={contentTitleTextCSS}
                        >{`${schedule.orders} 회차`}</div>
                        <span css={contentDescTextCSS}>
                          {format(schedule.scheduleAt, "yyyy.MM.dd")}
                        </span>
                        <span
                          css={contentDescTextCSS}
                        >{`${format(schedule.startAt, "HH:mm")}-${format(schedule.endAt, "HH:mm")}`}</span>
                        <span css={contentDescTextCSS}>
                          {schedule.location}
                        </span>
                        <span css={contentDescTextCSS}>{schedule.memo}</span>
                      </div>
                      <div>
                        {schedule.traineeSignImageUrl && (
                          <div css={signContainerCSS}>
                            <ImageView
                              src={schedule.traineeSignImageUrl}
                              css={signImageCSS}
                              variant={"square"}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {schedule.isNoShow ? (
                    <div css={noShowBadgeCSS}>결석</div>
                  ) : (
                    <Button
                      variant="primary"
                      styles={createReportButtonCSS}
                      onClick={() => {
                        !!schedule.lessonId && !schedule.hasTemporaryLesson
                          ? navigate(
                              `/crm/report?userId=${user.userId}&lessonId=${schedule.lessonId ?? 0}&scheduleSlotId=${scheduleSlotId}&&signImage=${schedule?.traineeSignImageUrl}`,
                            )
                          : navigate(
                              `/crm/report/create/?type=${user.lessonResultType}&userId=${user.userId}&lessonId=${schedule.lessonId ?? 0}&ticketId=${schedule.ticketId}&scheduleSlotId=${scheduleSlotId}&&signImage=${schedule?.traineeSignImageUrl}`,
                            );
                      }}
                    >
                      {!!schedule.lessonId
                        ? schedule.hasTemporaryLesson
                          ? "이어서 작성하기"
                          : "일지 확인하기"
                        : "일지 작성하기"}
                    </Button>
                  )}
                </>
              ) : (
                <div css={contentTextContainerCSS}>
                  <div css={contentTitleTextCSS}>{schedule.title}</div>
                  <div css={contentDescTextCSS}>
                    {format(schedule.scheduleAt, "yyyy.MM.dd")}
                  </div>
                  <div
                    css={contentDescTextCSS}
                  >{`${format(schedule.startAt, "HH:mm")}-${format(schedule.endAt, "HH:mm")}`}</div>
                  <div css={contentDescTextCSS}>{schedule.location}</div>
                  <div css={contentDescTextCSS}>{schedule.memo}</div>
                </div>
              )}
            </div>
          </div>
          {!schedule.lessonId &&
            !schedule.hasTemporaryLesson &&
            !schedule.isNoShow && (
              <div css={buttonContainerCSS}>
                <div css={leftButtonContainerCSS}>
                  <DeleteScheduleDialog />
                  <NoshowDialog />
                </div>
                {!schedule.repeatType && (
                  <Button
                    variant="text"
                    onClick={() => {
                      setSidebarState("update");
                    }}
                  >
                    수정하기
                  </Button>
                )}
              </div>
            )}
        </>
      )}
    </div>
  );
}

const bodyTextContainerCSS = css`
  display: flex;
  flex-direction: column;
`;

const bodyContainerCSS = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const signImageCSS = css`
  border: solid 1px ${colors.gray200};
  width: 60px;
  height: 60px;
  padding: 5px;
`;

const signContainerCSS = css`
  display: flex;
  width: 100%;
  gap: 15px;
  color: ${colors.gray600};
  ${typography.mobile.body2};
`;

const headerContainerCSS = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const containerCSS = css`
  position: relative;
  width: 280px;
  flex-shrink: 0;
  border-left: 1px solid #e9eff2;
  background: ${colors.gray25};
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
`;

const contentContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
  padding: 24px;
`;

const closeButtonContainerCSS = css`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const scheduleContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const contentTextContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`;

const contentTitleTextCSS = css`
  color: ${colors.gray900};
  ${typography.mobile.body2};
  font-weight: ${fontWeight.SemiBold};
`;

const contentDescTextCSS = css`
  color: ${colors.gray600};
  ${typography.mobile.body2};
`;

const noShowBadgeCSS = css`
  display: flex;
  width: 53px;
  height: 40px;
  flex-shrink: 0;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: ${colors.gray600};
  color: ${colors.gray25};
  ${typography.mobile.body3};
  font-weight: ${fontWeight.SemiBold};
`;

const createReportButtonCSS = css`
  height: 40px;
  padding: 13.5px 16px;
  flex-shrink: 0;
  ${typography.mobile.body3};
  font-weight: ${fontWeight.SemiBold};
`;

const buttonContainerCSS = css`
  position: fixed;
  bottom: 0;
  display: flex;
  width: inherit;
  height: 68px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 24px;
  flex-shrink: 0;
  border: 1px solid ${colors.gray50};
  background: ${colors.lightGray};
`;

const leftButtonContainerCSS = css`
  display: flex;
  gap: 8px;
`;
