import * as Dialog from "design-system/components/Dialog/Dialog";
import { ReactNode, useState } from "react";
import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";
import { useMutation } from "@tanstack/react-query";
import Button from "design-system/components/Button/Button";
import { Textarea } from "design-system/components/Textarea/Textarea";
import {
  workoutEquipments,
  WorkoutEquipments,
  workoutEquipmentsInKR,
  workoutParts,
  WorkoutParts,
  workoutPartsInKR,
} from "../../../../../api/common/commonEnumType";
import * as Select from "design-system/components/Select/Select";
import { queryClient, queryKeys } from "../../../../../libs/react-query";
import {
  postWorkoutsCustom,
  postWorkoutsWorkoutIdImages,
} from "../../../../../api/trainer/workouts/workouts";
import { colors } from "design-system/styles/colors";
import { ImageSelector } from "design-system/components/ImageSelector/ImageSelector";
import IcPlus from "design-system/components/atom/IconResource/Icon/IcPlus";
import IcDelete from "design-system/components/atom/IconResource/Icon/IcDelete";
import { RedDot } from "design-system/components/RedDot/RedDot";

export interface AddCustomWorkoutDialogProps {
  trigger: ReactNode;
}

export function AddCustomWorkoutDialog({
  trigger,
}: AddCustomWorkoutDialogProps) {
  const [workoutName, setWorkoutName] = useState<string>();
  const [workoutPart, setWorkoutPart] = useState<WorkoutParts>();
  const [workoutEquipment, setWorkoutEquipment] = useState<WorkoutEquipments>();
  const [descriptionList, setDescriptionList] = useState<string[]>([""]);
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);

  const { mutate: registerCustomWorkoutMutate, isLoading } = useMutation({
    mutationFn: postWorkoutsCustom,
    onSuccess: ({ workoutId }) => {
      if (imageFile) {
        const formData = new FormData();
        formData.append("image", imageFile);
        uploadCustomImage({ workoutId, image: formData });
      } else {
        queryClient.invalidateQueries(queryKeys.getWorkouts());
      }
    },
  });

  const { mutate: uploadCustomImage, isLoading: isImageUploadLoading } =
    useMutation({
      mutationFn: postWorkoutsWorkoutIdImages,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.getWorkouts());
      },
    });

  const onAddCustomWorkoutClick = async () => {
    try {
      // 두 Mutation을 Promise 형태로 실행
      workoutName &&
        workoutPart &&
        registerCustomWorkoutMutate({
          workoutName,
          workoutPart,
          workoutEquipment,
          steps: descriptionList.filter((desc) => !!desc),
        });
    } catch (error) {
      console.error("Error in mutations:", error);
      // 에러 처리 로직 추가 가능
    }
  };

  const reset = () => {
    setWorkoutName(undefined);
    setWorkoutPart(undefined);
    setWorkoutEquipment(undefined);
    setDescriptionList([""]);
    setImageFile(undefined);
  };

  return (
    <Dialog.Root variant={"page"}>
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <Dialog.Content onAnimationEndCapture={() => reset()}>
        <Dialog.Header>
          <Dialog.Title>{"커스텀 운동 추가"}</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body styles={dialogBodyCSS}>
          <div css={requireInputTextCSS}>
            <RedDot />
            {"는 필수 입력칸입니다"}
          </div>
          <div css={contentContainerCSS}>
            <div>
              {"운동 이름"}
              <RedDot />
            </div>
            <Textarea
              placeholder="운동 이름 입력"
              value={workoutName}
              onChange={(e) => setWorkoutName(e.target.value)}
              isAutoHeight={false}
              rows={1}
              isPreventNewline
              styles={textareaCSS}
            />
          </div>
          <div css={contentContainerCSS}>
            {"운동 사진"}
            <ImageSelector
              isWorkoutItem={false}
              variant={"square"}
              onImageRemove={() => {
                setImageFile(undefined);
              }}
              onChange={(event) => {
                if (event?.target?.files && event.target.files[0]) {
                  setImageFile(event.target.files[0]);
                }
              }}
            />
          </div>
          <div css={contentRowContainerCSS}>
            <div css={contentContainerCSS}>
              <div>
                {"운동 부위"}
                <RedDot />
              </div>
              <Select.Root
                value={workoutPart}
                onValueChange={(part: WorkoutParts) => setWorkoutPart(part)}
              >
                <Select.Trigger>
                  <Select.Value placeholder={"운동 부위를 선택해주세요"} />
                </Select.Trigger>
                <Select.Content>
                  {workoutParts
                    .filter((workoutPart) => workoutPart !== "custom")
                    .map((item) => (
                      <Select.Item value={item} key={item}>
                        {workoutPartsInKR(item)}
                      </Select.Item>
                    ))}
                </Select.Content>
              </Select.Root>
            </div>
            <div css={contentContainerCSS}>
              <div>
                {"운동 기구"}
                <RedDot />
              </div>
              <Select.Root
                value={workoutEquipment}
                onValueChange={(part: WorkoutEquipments) =>
                  setWorkoutEquipment(part)
                }
              >
                <Select.Trigger>
                  <Select.Value placeholder={"운동 기구를 선택해주세요"} />
                </Select.Trigger>
                <Select.Content>
                  {workoutEquipments.map((item) => (
                    <Select.Item value={item} key={item}>
                      {workoutEquipmentsInKR(item)}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </div>
          </div>
          <div css={contentContainerCSS}>
            {"운동법 추가"}
            {descriptionList.map((description, index) => {
              return (
                <div css={workoutDescriptionContainerCSS}>
                  <div css={indexTextCSS}>{index + 1}.</div>
                  <Textarea
                    value={description}
                    onChange={(event) => {
                      setDescriptionList(
                        descriptionList.map((innerDescription, innerIndex) => {
                          if (index == innerIndex) {
                            return event.target.value;
                          } else {
                            return innerDescription;
                          }
                        }),
                      );
                    }}
                  />
                  <Button
                    variant={"icon"}
                    onClick={() => {
                      setDescriptionList(
                        descriptionList.filter(
                          (_, innerIndex) => innerIndex !== index,
                        ),
                      );
                    }}
                  >
                    <IcDelete />
                  </Button>
                </div>
              );
            })}
            <div>
              <Button
                size={40}
                css={addDescriptionButtonCSS}
                onClick={() => {
                  setDescriptionList([...descriptionList, ""]);
                }}
              >
                <IcPlus />
              </Button>
            </div>
          </div>
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.Close
            variant="primary"
            disabled={!workoutName || !workoutPart || !workoutEquipment}
            // isLoading={isLoading || isImageUploadLoading}
            onClick={() => {
              onAddCustomWorkoutClick();
            }}
          >
            {"커스텀 운동 추가하기"}
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
}

const dialogBodyCSS = css`
  padding-top: 16px;
  padding-bottom: 31px;
  gap: 16px;
  align-items: flex-start;
`;

const requireInputTextCSS = css`
  ${typography.mobile.body3}
`;

const contentContainerCSS = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  ${typography.mobile.body1};
`;

const contentRowContainerCSS = css`
  display: flex;
  width: 100%;
  gap: 16px;
`;

const textareaCSS = css`
  height: 46px;
  justify-content: center;
`;

const indexTextCSS = css`
  ${typography.mobile.body1};
  width: 20px;
  color: ${colors.gray600};
`;
const workoutDescriptionContainerCSS = css`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const addDescriptionButtonCSS = css`
  background: ${colors.gray50};
  width: 100%;
  border-radius: 8px;
`;
