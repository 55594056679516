import * as Dialog from "design-system/components/Dialog/Dialog";
import {
  UserInfoDTO,
  LessonUserLessonDetailDTO,
} from "../../../../api/trainer/users/usersTypes";
import Button from "design-system/components/Button/Button";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getUsersUserId } from "../../../../api/trainer/users/users";
import { queryKeys } from "../../../../libs/react-query";
import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";
import { colors } from "design-system/styles/colors";
import copyImage from "design-system/components/atom/IconResource/ic_copy.svg";
import { handleCopyToClipboard } from "../../../../hoock/useCopy";
import { workoutPartsInKR } from "../../../../api/common/commonEnumType";
import { getSchedulesScheduleSlotIdUsersUserIdTicket } from "../../../../api/trainer/schedules/schedules";
import {
  EventName,
  ScreenName,
  useAmplitude,
} from "../../../../hoock/useAmplitude";

interface ReportShareDialogProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  lessonData?: LessonUserLessonDetailDTO;
  userId?: number;
  scheduleSlotId?: number;
  screenName: ScreenName;
  onClose?: () => void;
}

export function ReportShareDialog({
  isOpen,
  setIsOpen,
  lessonData,
  userId = 0,
  scheduleSlotId = 0,
  screenName,
  onClose,
}: ReportShareDialogProps) {
  const { sendClickEvent } = useAmplitude();

  const { data: userInfo } = useQuery({
    queryFn: () => getUsersUserId(userId),
    queryKey: queryKeys.getUsersUserId(userId),
    enabled: !!userId,
  });

  const { data: ticketInfo } = useQuery({
    queryFn: () =>
      getSchedulesScheduleSlotIdUsersUserIdTicket({ userId, scheduleSlotId }),
    queryKey: queryKeys.getSchedulesScheduleSlotIdUsersUserIdTicket({
      userId,
      scheduleSlotId,
    }),
    enabled: !!userId && !!scheduleSlotId,
  });

  const lessonDataToText = (
    userInfo: UserInfoDTO | undefined,
    data?: LessonUserLessonDetailDTO,
  ): string => {
    const date = data?.lessonAt ? new Date(data.lessonAt) : undefined;
    const dateString = date
      ? `${date.getFullYear()}.${String(date.getMonth() + 1).padStart(2, "0")}.${String(date.getDate()).padStart(2, "0")}`
      : "날짜 정보 없음";
    const title = `⦿ ${dateString}  [${data?.orders ?? 0}/${data?.totalCount}회] PT일지`;
    const summary = `◼︎ 트레이너: ${data?.trainerName ?? "정보 없음"}\n◼︎ 회원: ${userInfo?.name ?? "정보 없음"}\n◼︎ 운동시간: ${data?.workoutSummary?.totalTime ?? 0}분`;
    const lessonDetail =
      lessonData?.workoutDetails.length === 0
        ? ""
        : "\n\n" +
          lessonData?.workoutDetails
            .map((item) => {
              // 총 볼륨 계산
              const totalVolume = item.sets.reduce(
                (acc, set) => acc + set.weight * set.count,
                0,
              );
              const volumeText =
                item.sets.length > 0 ? `• 총 볼륨: ${totalVolume}kg` : ""; // 세트가 있을 때만 총 볼륨 표시

              const resourceText =
                item.videos.length + item.images.length > 0
                  ? `\n• 사진 및 영상 피드백:  ${item.videos.length + item.images.length}개`
                  : "";

              const feedback =
                item.feedback && item.feedback?.length > 0
                  ? `\n• 트레이너 피드백: ${item.feedback}`
                  : "";

              return `▶${item.workoutName}(${workoutPartsInKR(item.part)})\n${volumeText}${resourceText}${feedback}`;
            })
            .join("\n");

    const totalFeedback = `▶종합 피드백\n⦿${data?.totalFeedback}`;
    const bottomText = `\n나의 PT 운동 영상 및 성장 기록을 보고 싶으신가요\n지금 바로 핏츠유 다운로드 하고 아래 코드를 입력해보세요.\n앱 설치 링크: https://litt.ly/fitsyou\nPT 일지 코드: ${ticketInfo?.code}`;
    return `${title}\n\n${summary}${lessonDetail}\n\n${totalFeedback}\n${bottomText}`;
  };

  const lessonContentText = lessonDataToText(userInfo, lessonData);

  return (
    <div>
      <Dialog.Root open={isOpen}>
        <Dialog.Content css={dialogContentCSS}>
          <div css={titleContainerCSS}>
            <div css={titleTextCSS}>일지 전송 완료</div>
            <div css={subTitleTextCSS}>
              아직 앱을 설치하지 않은 회원님께
              <br />
              일지 내용을 복사하여 카톡으로 전달할 수 있어요
            </div>
          </div>
          <div css={contentContainerCSS}>
            <div css={lessonContentTextContainerCSS}>{lessonContentText}</div>
            <div>
              <Button
                css={copyButtonCSS}
                onClick={async () => {
                  sendClickEvent({
                    screenName: screenName,
                    eventName:
                      EventName.web_copy_share_pt_diary_text_button_clicked,
                  });
                  await handleCopyToClipboard(lessonContentText);
                }}
              >
                <img src={copyImage}></img>
                복사하기
              </Button>
            </div>
          </div>
          <div css={buttonContainerCSS}>
            <Button
              size={46}
              css={buttonCSS}
              variant={"primary"}
              onClick={() => {
                setIsOpen(false);
                onClose?.();
              }}
            >
              닫기
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Root>
    </div>
  );
}

const lessonContentTextContainerCSS = css`
  overflow-y: auto;
  max-height: 300px;
`;

const copyButtonCSS = css`
  width: 100%;
  background: ${colors.gray100};
  height: 40px;
`;

const contentContainerCSS = css`
  border-radius: 8px;
  background-color: ${colors.lightGray};
  ${typography.mobile.body1};
  color: ${colors.gray600};
  padding: 12px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

const titleTextCSS = css`
  ${typography.mobile.heading3};
  width: 100%;
`;
const subTitleTextCSS = css`
  ${typography.mobile.body1};
  color: ${colors.gray600};
  width: 100%;
`;

const titleContainerCSS = css`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 8px;
`;

const dialogContentCSS = css`
  padding: 24px 16px 0 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 450px;
`;

const buttonCSS = css`
  width: 100%;
`;

const buttonContainerCSS = css`
  padding: 16px 4px 20px 4px;
`;
