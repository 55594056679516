import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";

export const RedDot = () => {
  return (
    <span
      css={css`
        color: ${colors.orange};
      `}
    >
      *
    </span>
  );
};
