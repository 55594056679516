import { customFetch } from "../../../libs/fetch/fetch";
import {
  DeleteWorkoutsWorkoutIdImagesReuest,
  GetWorkoutsResponse,
  GetWorkoutsWorkoutIdRequest,
  GetWorkoutsWorkoutIdResponse,
  PostWorkoutCustomRequest,
  PostWorkoutRequestRequest,
  PostWorkoutsCustomResponse,
  PostWorkoutsWorkoutIdDescriptionRequest,
  PostWorkoutsWorkoutIdImagesRequest,
  PutWorkoutsWorkoutIdCustomRequest,
  PutWorkoutsWorkoutIdDescriptionRequest,
  PutWorkoutsWorkoutIdImagesRequest,
} from "./workoutsTypes";

export async function putWorkoutsWorkoutIdImages({
  image,
  workoutId,
}: PutWorkoutsWorkoutIdImagesRequest): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/workouts/${workoutId}/images`,
    },
    {
      method: "PUT",
      body: image,
    },
  );
}

/**
 * 커스텀운동의 운동 이미지
 */
export async function postWorkoutsWorkoutIdImages({
  image,
  workoutId,
}: PostWorkoutsWorkoutIdImagesRequest): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/workouts/${workoutId}/images`,
    },
    {
      method: "POST",
      body: image,
    },
  );
}

export async function deleteWorkoutsWorkoutIdImages({
  workoutId,
}: DeleteWorkoutsWorkoutIdImagesReuest): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/workouts/${workoutId}/images`,
    },
    {
      method: "DELETE",
    },
  );
}

export async function putWorkoutsWorkoutIdDescriptions({
  workoutId,
  ...request
}: PutWorkoutsWorkoutIdDescriptionRequest): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/workouts/${workoutId}/descriptions`,
    },
    {
      method: "PUT",
      body: JSON.stringify({
        ...request,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}

export async function postWorkoutsWorkoutIdDescriptions({
  workoutId,
  ...request
}: PostWorkoutsWorkoutIdDescriptionRequest): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/workouts/${workoutId}/descriptions`,
    },
    {
      method: "POST",
      body: JSON.stringify({
        ...request,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}

export async function postWorkoutsWorkoutIdBookmarks(
  workoutId: number,
): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/workouts/${workoutId}/bookmarks`,
    },
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}

export async function deleteWorkoutsWorkoutIdBookmarks(
  workoutId: number,
): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/workouts/${workoutId}/bookmarks`,
    },
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}

export async function getWorkouts(): Promise<GetWorkoutsResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/workouts`,
  });
}

export async function getWorkoutsWorkoutId({
  workoutId,
}: GetWorkoutsWorkoutIdRequest): Promise<GetWorkoutsWorkoutIdResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/workouts/${workoutId}`,
  });
}

export async function postWorkoutsRequest({
  ...request
}: PostWorkoutRequestRequest): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/workouts/request`,
    },
    {
      method: "POST",
      body: JSON.stringify({
        ...request,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}

export async function postWorkoutsCustom({
  ...request
}: PostWorkoutCustomRequest): Promise<PostWorkoutsCustomResponse> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/workouts/custom`,
    },
    {
      method: "POST",
      body: JSON.stringify({
        ...request,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}

export async function putWorkoutsWorkoutIdCustom({
  workoutId,
  ...request
}: PutWorkoutsWorkoutIdCustomRequest): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/workouts/${workoutId}/custom`,
    },
    {
      method: "PUT",
      body: JSON.stringify({
        ...request,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}
