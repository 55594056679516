import { useEffect } from "react";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { getProfilesDetail } from "api/trainer/profiles/profiles";
import { queryKeys } from "libs/react-query";

import { css } from "@emotion/react";
import { MainHeader } from "common/Header/MainHeader";
import { TrainerProfileInfo } from "./component/TrainerProfileInfo/TrainerProfileInfo";
import { PriceInfo } from "./component/PriceInfo/PriceInfo";
import { getWidthMediaQuery } from "../../styles/mediaQueries";

export function TrainerDetailPage() {
  const { trainerId } = useParams();

  const { data: trainerDetail } = useQuery({
    queryFn: () => getProfilesDetail({ trainerId: Number(trainerId) }),
    queryKey: queryKeys.getProfilesDetail({ trainerId: Number(trainerId) }),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div css={containerCSS}>
      <MainHeader />
      <div css={contentContainerCSS}>
        <TrainerProfileInfo />
        {!!trainerDetail?.trainer.prices.prices.length && <PriceInfo />}
      </div>
    </div>
  );
}

const containerCSS = css`
  display: flex;
  flex-direction: column;
  background: black;
  width: 100%;
`;

const contentContainerCSS = css`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  gap: 7px;

  ${getWidthMediaQuery("pc")} {
    padding: 100px 200px;
    gap: 70px;
  }
`;
