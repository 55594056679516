import { ReportTypeCheck } from "../RoportTypeCheck/ReportTypeCheck";
import { Separator } from "../Separator/Separator";
import {
  ReportType,
  reportTypeInKR,
} from "../../../../../api/common/commonEnumType";
import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { WorkoutTime } from "../WorkoutTime/WorkoutTime";
import { WorkoutList } from "../Workout/WorkoutList";
import Button from "design-system/components/Button/Button";
import plusIcon from "design-system/components/atom/IconResource/ic_plus.svg";
import { TotalFeedback } from "../TotalFeecback/TotalFeedback";
import React, { useContext, useState } from "react";
import {
  CrmContentHorizontalPadding,
  CrmContentVerticalPadding,
} from "../../../../component/crmCSS";
import CreateContext from "../../createContext";
import { useQuery } from "@tanstack/react-query";
import { getUsersUserIdLessonsLessonId } from "../../../../../api/trainer/users/users";
import { queryKeys } from "../../../../../libs/react-query";
import { useSearchParams } from "react-router-dom";
import {
  EventName,
  ScreenName,
  useAmplitude,
} from "../../../../../hoock/useAmplitude";
import { AddWorkoutDialog } from "crm/report/component/AddWorkoutDialog/AddWorkoutDialog";
import { LessonSign } from "../../../component/LessonSign/LessonSign";

export function ReportWriteTemplate() {
  const { sendClickEvent } = useAmplitude();
  const [searchParams] = useSearchParams();
  const [lessonId, setLessonId] = useState(
    parseInt(searchParams.get("lessonId") ?? "0", 10),
  );

  const {
    data: reportData,
    saveTempRetort,
    sendTempRetort,
    signImagUrl,
  } = useContext(CreateContext);

  /**
   *  작성중인 케이스에 대해서 데이터 불러오기
   */
  const { data: lessonData } = useQuery({
    queryFn: () =>
      getUsersUserIdLessonsLessonId({
        lessonId,
        userId: reportData?.userId ?? 0,
      }),
    queryKey: queryKeys.getUsersUserIdLessonsLessonId({
      lessonId,
      userId: reportData?.userId ?? 0,
    }),
    enabled: lessonId !== 0 && !!reportData?.userId,
  });

  const preferredType =
    ((searchParams.get("type") ||
      "feedback_workout_set_weight") as ReportType) ||
    "feedback_workout_set_weight";

  const onAddWorkoutButtonClick = () => {
    sendClickEvent({
      eventName: EventName.web_add_workout_button_clicked,
      screenName: ScreenName.web_pt_report_write,
    });
  };

  return (
    <div css={contentContainerCSS}>
      <ReportTypeCheck
        reportType={lessonData?.lessonResultType || preferredType}
      />
      <Separator />
      <div css={reportDescriptionContainerCSS}>
        {reportData && (
          <div>{reportTypeInKR(reportData.lessonResultType)} 작성</div>
        )}
        <div
          css={css`
            color: ${colors.orange};
            ${typography.mobile.body2}
          `}
        >
          *는 필수 항목입니다
        </div>
      </div>
      <WorkoutTime />
      {reportData?.lessonResultType != "feedback_only" && <WorkoutList />}
      {reportData?.lessonResultType != "feedback_only" && (
        <AddWorkoutDialog
          trigger={
            <Button css={buttonCSS} onClick={onAddWorkoutButtonClick}>
              <img src={plusIcon} />
              운동 추가하기
            </Button>
          }
        />
      )}
      <TotalFeedback />
      <LessonSign signImageUrl={signImagUrl} />
      <div css={buttonContainerCSS}>
        <Button variant={"secondary"} size={46} onClick={saveTempRetort}>
          임시저장
        </Button>
        <Button variant={"primary"} size={46} onClick={sendTempRetort}>
          PT 일지 전송
        </Button>
      </div>
    </div>
  );
}

const contentContainerCSS = css`
  padding: ${CrmContentVerticalPadding}px ${CrmContentHorizontalPadding}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-height: 100vh;
  height: auto;
`;

const buttonContainerCSS = css`
  padding-top: 40px;
  display: flex;
  gap: 10px;
  justify-content: right;
`;

const reportDescriptionContainerCSS = css`
  display: flex;
  gap: 20px;
  align-items: baseline;
`;

const buttonCSS = css`
  background: black;
  color: white;
  border-radius: 200px;
  padding: 16px 22px;
  display: flex;
  width: fit-content;
  align-self: center;
`;
