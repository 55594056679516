import { createContext, Dispatch, SetStateAction } from "react";

interface TrainerSearchListContext {
  address?: string;
  setAddress: Dispatch<SetStateAction<string | undefined>>;
}

export default createContext<TrainerSearchListContext>({
  address: undefined,
  setAddress: () => {},
});
