import React, { useContext, useEffect } from "react";
import { ListCheckBox } from "design-system/components/Checkbox/ListCheckBox";
import { css } from "@emotion/react";
import customImage from "design-system/components/atom/IconResource/customImage.svg";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import WorkoutSelectContext from "./workoutSelectContext";
import { workoutCategoriesDetailDTOTOMixedWorkoutDetail } from "../../../../api/trainer/schedules/schedulesTypes";

export function WorkoutItemWithCheckbox() {
  const {
    workout,
    editedWorkoutList,
    setEditedWorkoutList,
    handleCheckboxChange,
  } = useContext(WorkoutSelectContext);

  // `isChecked`는 `editedWorkoutList`에 기반하여 동적으로 계산
  const isChecked =
    editedWorkoutList?.some((workoutItem) => workout?.id === workoutItem.id) ||
    false;

  useEffect(() => {
    if (workout && isChecked) {
      const newWorkout =
        workoutCategoriesDetailDTOTOMixedWorkoutDetail(workout);
      if (!editedWorkoutList?.some((item) => item.id === newWorkout.id)) {
        setEditedWorkoutList((prev) => [...prev, newWorkout]);
      }
    } else if (workout && !isChecked) {
      setEditedWorkoutList((prev) =>
        prev.filter((item) => item.id !== workout.id),
      );
    }
  }, [isChecked]);

  return (
    <div key={workout?.id + (workout?.part || "0")} css={dialogItemCSS}>
      <ListCheckBox
        checked={isChecked} // `checked`로 상태를 동적으로 반영
        onCheckedChange={(checked) => {
          if (workout) {
            handleCheckboxChange?.(workout, !!checked);
          }
        }}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            width: 100%;
            gap: 8px;
          `}
        >
          <img
            src={workout?.thumbnail ?? customImage}
            css={css`
              height: 65px;
              width: 66px;
            `}
          />
          <div
            css={css`
              color: ${colors.gray800};
              ${typography.mobile.body2}
            `}
          >
            {workout?.name}
          </div>
        </div>
      </ListCheckBox>
    </div>
  );
}

const dialogItemCSS = css`
  display: flex;
  gap: 10px;
  align-items: center;
`;
