import { WorkoutEquipments, WorkoutParts } from "../../common/commonEnumType";
import { UserInfoDTO } from "../users/usersTypes";

export type PutWorkoutsWorkoutIdImagesRequest = {
  workoutId: number;
  image: FormData;
};

export type PostWorkoutsWorkoutIdImagesRequest =
  PutWorkoutsWorkoutIdImagesRequest;

export type DeleteWorkoutsWorkoutIdImagesReuest = {
  workoutId: number;
};

export type PutWorkoutsWorkoutIdDescriptionRequest = {
  userId?: number;
  steps: string[];
  workoutId: number;
};

export type PostWorkoutsWorkoutIdDescriptionRequest =
  PutWorkoutsWorkoutIdDescriptionRequest;

export type GetWorkoutsResponse = WorkoutCategoriesDTO;

export type GetWorkoutsWorkoutIdRequest = {
  workoutId: number;
};

export type GetWorkoutsWorkoutIdResponse =
  WorkoutTrainerWorkoutCategoryDetailDTO;

export type PostWorkoutRequestRequest = {
  workoutName: string;
  workoutPart: WorkoutParts;
  equipment?: WorkoutEquipments;
};

export type PostWorkoutCustomRequest = RequestSaveCustomWorkoutRequestDTO;

export type PostWorkoutsCustomResponse = {
  workoutId: number;
};

export type PutWorkoutsWorkoutIdCustomRequest = {
  workoutId: number;
  workoutName: string;
  workoutPart: WorkoutParts;
  workoutEquipment?: WorkoutEquipments;
};

export interface WorkoutCategoriesDTO {
  lowerBody: WorkoutCategoryItemlDTO[];
  chest: WorkoutCategoryItemlDTO[];
  shoulders: WorkoutCategoryItemlDTO[];
  back: WorkoutCategoryItemlDTO[];
  arms: WorkoutCategoryItemlDTO[];
  abs: WorkoutCategoryItemlDTO[];
  etc: WorkoutCategoryItemlDTO[];
  custom: WorkoutCategoryItemlDTO[];
}

export type WorkoutTrainerWorkoutCategoryDetailDTO = {
  workout: WorkoutCategoryItemlDTO;
  defaultDescriptions: string[];
  trainerDefaultDescriptions: string[];
  traineeSpecificDescriptions: WorkoutsTrainerWorkoutCategoryDetailTraineeSpecificDescriptionsDTO[];
};

export type WorkoutsTrainerWorkoutCategoryDetailTraineeSpecificDescriptionsDTO =
  {
    userInfo: UserInfoDTO;
    descriptions: string[];
  };

export type WorkoutCategoryItemlDTO = {
  id: number;
  part: WorkoutParts;
  equipment?: WorkoutEquipments;
  name: string;
  imagePath?: string;
  thumbnail?: string;
  isBookmarked: boolean;
  isWorkoutDescriptionCreated: boolean;
  isCustom: boolean;
};

interface RequestSaveCustomWorkoutRequestDTO {
  workoutPart: WorkoutParts;
  workoutName: string;
  workoutEquipment?: WorkoutEquipments;
  steps: string[];
}

export const mapPartToKey = (
  part: WorkoutParts,
): keyof WorkoutCategoriesDTO => {
  const mapping: Record<WorkoutParts, keyof WorkoutCategoriesDTO> = {
    lower_body: "lowerBody",
    chest: "chest",
    back: "back",
    shoulders: "shoulders",
    arms: "arms",
    abs: "abs",
    etc: "etc",
    custom: "custom",
  };

  return mapping[part];
};
