import { createContext, Dispatch, SetStateAction } from "react";
import {
  MixedWorkoutDetail,
  RequestSaveLessonRequestDTO,
} from "../../../api/trainer/schedules/schedulesTypes";
import { WorkoutParts } from "../../../api/common/commonEnumType";

interface CreateContext {
  data?: CreateContextProps;
  setData: Dispatch<SetStateAction<CreateContextProps>>;
  saveTempRetort?: () => {};
  sendTempRetort?: () => {};
  signImagUrl?: string;
}

export default createContext<CreateContext>({
  data: undefined,
  setData: () => {},
  saveTempRetort: undefined,
  sendTempRetort: undefined,
  signImagUrl: undefined,
});

export type CreateContextProps = RequestSaveLessonRequestDTO & {
  mixedWorkoutDetail?: MixedWorkoutDetail[];
};

export type WorkoutItem = {
  workoutName: string;
  part: WorkoutParts;
};
