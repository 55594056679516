import { ButtonHTMLAttributes, ElementRef, forwardRef } from "react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { css, Interpolation, Theme } from "@emotion/react";

export type ButtonVariantType =
  | "primary"
  | "secondary"
  | "tertiary"
  | "outline"
  | "icon"
  | "error"
  | "text";
// export type ButtonSizeType = 26 | 32 | 52 | "auto";
export type ButtonSizeType = 23 | 40 | 46 | "auto";
export type ButtonShapeType = "default" | "round";

export interface ButtonInterface
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariantType;
  size?: ButtonSizeType;
  shape?: ButtonShapeType;
  isLoading?: boolean;
  isFloating?: boolean;
  styles?: Interpolation<Theme>;
}

const Button = forwardRef<ElementRef<"button">, ButtonInterface>(
  (
    {
      variant,
      size,
      shape = "default",
      children,
      disabled,
      isFloating,
      isLoading,
      styles,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        data-floating={isFloating}
        disabled={disabled || isLoading}
        // css={css`
        //   background: black;
        //   height: 100px;
        //   align-self: flex-end;
        // `}
        css={[
          defaultButtonCSS,
          variant && buttonVariantStyles[variant],
          buttonShapes[shape],
          size && buttonSizes[size],
          styles,
        ]}
        {...props}
      >
        {children}
      </button>
    );
  },
);

Button.displayName = "Button";

export default Button;

export const defaultButtonCSS = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
  transition:
    transform 70ms cubic-bezier(0.65, 0, 0.35, 1),
    background-color 70ms cubic-bezier(0.65, 0, 0.35, 1);
  ${typography.Button1};

  &[disabled] {
    cursor: not-allowed;
  }

  &[data-floating="true"] {
    position: fixed;
    z-index: 1;
  }
`;

const primaryCSS = css`
  color: ${colors.black};
  background-color: ${colors.lime600};

  :active:not(:disabled) {
    background: linear-gradient(${colors.Black_a10} 10%), ${colors.lime900};
  }

  &[disabled] {
    color: ${colors.gray300};
    background: ${colors.lime300};
  }
`;

const secondaryCSS = css`
  color: ${colors.black};
  background-color: ${colors.gray50};

  :active:not(:disabled) {
    background: linear-gradient(
        ${colors.Black_a10} 100%,
        ${colors.Black_a10} 100%
      ),
      ${colors.gray300};
  }

  &[disabled] {
    color: ${colors.gray300};
  }
`;

const iconCSS = css`
  background-color: transparent;
  padding: 0;

  :active:not(:disabled) {
    background-color: ${colors.default.Black_a10};
  }

  &[disabled] {
    color: ${colors.default.Gray600};
    background-color: ${colors.default.Gray900};
  }
`;

const tertiaryCSS = css`
  :active:not(:disabled) {
    background-color: ${colors.Black_a10};
  }
`;

const outlineCSS = css`
  background-color: transparent;
  color: ${colors.gray900};
  border: 1px solid ${colors.gray900};

  :active:not(:disabled) {
    background-color: ${colors.Black_a10};
  }
`;

const textCSS = css`
  color: ${colors.gray900};
  background-color: transparent;
  padding: 0;
  ${typography.mobile.body3};

  :active:not(:disabled) {
    color: ${colors.gray200};
  }

  &[disabled] {
    color: ${colors.gray200};
  }
`;

const size23CSS = css`
  height: 26px;
  padding: 0 8px;
  border-radius: 4px;
  ${typography.Body3};
`;

const size40CSS = css`
  height: 32px;
  padding: 0 12px;
  border-radius: 4px;
  gap: 4px;
  ${typography.Button2};
`;

const size46CSS = css`
  height: 52px;
  padding: 0 24px;
  border-radius: 8px;
  gap: 8px;
  ${typography.Button1};
`;

const sizeAutoCSS = css`
  height: 52px;
  width: 100%;
  padding: 0 24px;
  border-radius: 8px;
`;

const defaultShapeCSS = css`
  gap: 8px;
`;

const roundShapeCSS = css`
  padding: 10px 24px;
  border-radius: 22px;
  width: fit-content;
  height: 44px;
  gap: 4px;
`;

export const buttonVariantStyles: Record<
  ButtonVariantType,
  Interpolation<Theme>
> = {
  primary: primaryCSS,
  secondary: secondaryCSS,
  tertiary: tertiaryCSS,
  outline: outlineCSS,
  // TODO text용, error, icon 용 CSS 필요
  icon: iconCSS,
  error: primaryCSS,
  text: textCSS,
} as const;

export const buttonSizes: Record<ButtonSizeType, Interpolation<Theme>> = {
  "23": size23CSS,
  "40": size40CSS,
  "46": size46CSS,
  auto: sizeAutoCSS,
} as const;

export const buttonShapes: Record<ButtonShapeType, Interpolation<Theme>> = {
  default: defaultShapeCSS,
  round: roundShapeCSS,
} as const;
