import * as DataTable from "design-system/components/DataTable/DataTable";
import { useQuery } from "@tanstack/react-query";
import { css } from "@emotion/react";
import { useNavigate } from "react-router";
import { getTrainersLessonList } from "../../../api/admin/admin";
import { queryKeys } from "../../../libs/react-query";

export function AdminReportListContent() {
  const navigate = useNavigate();

  const { data: report } = useQuery({
    queryFn: () => getTrainersLessonList(),
    cacheTime: 0,
    staleTime: 0,
    queryKey: queryKeys.getTrainersLessonList(),
  });

  return (
    <div css={containerCSS}>
      <DataTable.Root>
        <DataTable.Table
          onRowClick={(original) => {
            navigate(
              `/crm/fitsyou-admin/report/detail?userId=${original.info.traineeId}&lessonId=${original.info?.lessonId ?? 0}&scheduleSlotId=${original.info.scheduleSlotId}`,
            );
          }}
          data={
            report?.content.flatMap(({ trainer, lessons }) =>
              lessons.map((lesson) => ({
                ...lesson,
                trainer,
              })),
            ) || []
          }
          columns={[
            {
              cell: ({ row }) => row.original.info.scheduleSlotId,
              header: "스케줄 아이디",
              size: 120,
            },
            {
              cell: ({ row }) => row.original.info.lessonId,
              header: "레슨 아이디",
              size: 120,
            },
            {
              cell: ({ row }) => row.original.trainer.name,
              header: "트레이너명",
              size: 170,
            },
            {
              cell: ({ row }) => row.original.info.traineeName,
              header: "회원명",
              size: 170,
            },
            {
              cell: ({ row }) => row.original.info.startAt,
              header: "스케줄 날짜",
              size: 170,
            },
            {
              cell: ({ row }) => {
                console.log(JSON.stringify(row.original, null, 2));
                return (
                  row.original.info.orders +
                  " / " +
                  row.original.info.totalCount
                );
              },
              header: "회차 정보",
              size: 150,
            },
            {
              cell: ({ row }) => row.original.hasReview.toString(),
              header: "리뷰 유무",
              size: 150,
            },
          ]}
        ></DataTable.Table>
      </DataTable.Root>
    </div>
  );
}

const containerCSS = css`
  gap: 24px;
  display: flex;
  flex-direction: column;
`;
