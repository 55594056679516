import * as Dialog from "design-system/components/Dialog/Dialog";
import { css } from "@emotion/react";
import {
  WorkoutEquipments,
  workoutParts,
  WorkoutParts,
} from "../../../../api/common/commonEnumType";
import Button from "design-system/components/Button/Button";
import { ReactNode, useContext, useMemo, useState } from "react";
import { typography } from "design-system/styles/typography/typography";
import CreateContext from "../../create/createContext";
import {
  mapPartToKey,
  WorkoutCategoryItemlDTO,
} from "../../../../api/trainer/workouts/workoutsTypes";
import { useQuery } from "@tanstack/react-query";

import { queryKeys } from "../../../../libs/react-query";
import {
  MixedWorkoutDetail,
  workoutCategoriesDetailDTOTOMixedWorkoutDetail,
} from "../../../../api/trainer/schedules/schedulesTypes";
import { colors } from "design-system/styles/colors";
import { getWorkouts } from "../../../../api/trainer/workouts/workouts";
import PfDelete from "design-system/components/atom/IconResource/Icon/PfDelete";
import { WorkoutItemWithCheckbox } from "./WorkoutItemWithCheckbox";
import WorkoutSelectContext from "./workoutSelectContext";
import WorkoutListContext from "crm/workout-list/workoutListContext";
import { WorkoutSearchFilter } from "crm/workout-list/component/WorkoutSearchFilter/WorkoutSearchFilter";
import { AddCustomWorkoutDialog } from "crm/report/create/component/AddCustomWorkoutDialog/AddCustomWorkoutDialog";

interface AddWorkoutDialogProps {
  trigger: ReactNode;
}

export function AddWorkoutDialog({ trigger }: AddWorkoutDialogProps) {
  const [workoutId, setWorkoutId] = useState<number>();
  const [workoutName, setWorkoutName] = useState<string>();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isDescriptionRequired, setIsDescriptionRequired] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [workoutPart, setWorkoutPart] = useState<WorkoutParts | "total">(
    "total",
  );
  const [workoutEquipment, setWorkoutEquipment] = useState<
    WorkoutEquipments | "total"
  >("total");

  const { data: workouts } = useQuery({
    queryFn: () => getWorkouts(),
    queryKey: queryKeys.getWorkouts(),
  });

  const filteredWorkouts = useMemo(
    () =>
      workoutPart === "total"
        ? isCustom
          ? workouts?.custom
          : workoutParts.flatMap(
              (workoutPart) => workouts?.[mapPartToKey(workoutPart)],
            )
        : [
            ...((!isCustom && workouts?.[mapPartToKey(workoutPart)]) || []),
            ...(workouts?.custom.filter(
              (workout) => workout.part === workoutPart,
            ) || []),
          ],
    [workoutPart, isCustom, workouts],
  );

  const { setData: setReportData, data: reportData } =
    useContext(CreateContext) || {};

  const [editedWorkoutList, setEditedWorkoutList] = useState<
    MixedWorkoutDetail[]
  >([]);

  const handleCheckboxChange = (
    workout: WorkoutCategoryItemlDTO,
    isChecked: boolean,
  ) => {
    const newWorkout = workoutCategoriesDetailDTOTOMixedWorkoutDetail(workout);
    if (isChecked) {
      setEditedWorkoutList((prev) => [...prev, newWorkout]);
    } else {
      setEditedWorkoutList((prev) =>
        prev.filter((item) => item.id !== newWorkout.id),
      );
    }
  };

  const handleSaveInAddWorkoutDialog = () => {
    if (reportData) {
      setReportData({
        ...reportData,
        mixedWorkoutDetail: editedWorkoutList,
      });
    }
  };

  const eraseItem = (workout: MixedWorkoutDetail) => {
    setEditedWorkoutList((prev) => {
      const updatedList = prev.filter(
        (workoutItem) => workoutItem.id !== workout.id,
      );
      console.log("log:", updatedList);
      return updatedList;
    });
  };

  const resetFilter = () => {
    setWorkoutName(undefined);
    setIsBookmarked(false);
    setIsDescriptionRequired(false);
    setIsCustom(false);
    setWorkoutPart("total");
    setWorkoutEquipment("total");
    setEditedWorkoutList(reportData?.mixedWorkoutDetail || []);
  };

  return (
    <WorkoutListContext.Provider
      value={{
        workoutId,
        setWorkoutId,
        workoutName,
        setWorkoutName,
        isBookmarked,
        setIsBookmarked,
        isDescriptionRequired,
        setIsDescriptionRequired,
        isCustom,
        setIsCustom,
        workoutPart,
        setWorkoutPart,
        workoutEquipment,
        setWorkoutEquipment,
      }}
    >
      <Dialog.Root variant={"page"}>
        <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
        <Dialog.Content
          onAnimationEndCapture={resetFilter}
          styles={dialogContentCSS}
        >
          <Dialog.Header>
            <Dialog.Title>{"운동 추가하기"}</Dialog.Title>
          </Dialog.Header>
          <Dialog.Body styles={dialogBodyCSS}>
            <WorkoutSearchFilter />
            <div css={dialogBodyContentCSS}>
              {filteredWorkouts
                ?.filter(
                  (filteredWorkout) =>
                    (!isBookmarked || filteredWorkout?.isBookmarked) &&
                    (!isDescriptionRequired ||
                      !filteredWorkout?.isWorkoutDescriptionCreated) &&
                    (workoutEquipment === "total" ||
                      filteredWorkout?.equipment === workoutEquipment) &&
                    (!workoutName ||
                      filteredWorkout?.name
                        .replace(/\s/g, "")
                        .toLowerCase()
                        .includes(
                          workoutName.replace(/\s/g, "").toLowerCase(),
                        )),
                )
                .map((workoutItem) => (
                  <WorkoutSelectContext.Provider
                    key={workoutItem?.id}
                    value={{
                      workout: workoutItem,
                      editedWorkoutList: editedWorkoutList,
                      handleCheckboxChange: handleCheckboxChange,
                      setEditedWorkoutList: setEditedWorkoutList,
                    }}
                  >
                    <WorkoutItemWithCheckbox />
                  </WorkoutSelectContext.Provider>
                ))}
            </div>
            <div css={controlBoxContainerCSS}>
              {editedWorkoutList?.map((workout) => {
                return (
                  <div css={controlBoxItemCSS} key={workout.id}>
                    <div>{workout.workoutName}</div>
                    <Button variant={"icon"} onClick={() => eraseItem(workout)}>
                      <PfDelete
                        css={css`
                          color: ${colors.gray600};
                          height: 25px;
                          width: 25px;
                        `}
                      />
                    </Button>
                  </div>
                );
              })}
            </div>
          </Dialog.Body>
          <Dialog.Footer styles={dialogFooterCSS}>
            <Dialog.Close asChild>
              <AddCustomWorkoutDialog
                trigger={
                  <Button
                    variant="secondary"
                    size={"auto"}
                    onClick={() => {
                      handleSaveInAddWorkoutDialog();
                    }}
                  >
                    커스텀 운동 추가
                  </Button>
                }
              />
            </Dialog.Close>
            <Dialog.Close
              variant="primary"
              onClick={handleSaveInAddWorkoutDialog}
            >
              {"저장하기"}
            </Dialog.Close>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>
    </WorkoutListContext.Provider>
  );
}

const dialogContentCSS = css`
  width: 700px;
`;

const dialogBodyCSS = css`
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 10px;
  align-items: flex-start;
`;

const dialogBodyContentCSS = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 450px;
  overflow-y: auto;
  max-height: 350px;
`;

const dialogFooterCSS = css`
  gap: 12px;
`;

const controlBoxContainerCSS = css`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;

const controlBoxItemCSS = css`
  display: flex;
  ${typography.mobile.body2};
  gap: 6px;
  align-items: center;
`;
