export type ReportType =
  | "feedback_only"
  | "feedback_workout"
  | "feedback_workout_set_weight";

export type ReportWrittenType = "not_written" | "writing" | "completed";

export type LessonResultType = ReportWrittenType | "not_completed" | "no_show";

export const workoutParts = [
  "lower_body",
  "chest",
  "back",
  "shoulders",
  "arms",
  "abs",
  "etc",
  "custom",
] as const;

export type WorkoutParts = (typeof workoutParts)[number];

export type WorkoutIntensity =
  | "very_easy"
  | "easy"
  | "normal"
  | "hard"
  | "very_hard";

export const workoutEquipments = [
  "bodyweight",
  "bodyweight_weight",
  "cardio",
  "dumbbell",
  "barbell",
  "smith_machine",
  "machine",
  "cable",
  "kettlebell",
] as const;

export type WorkoutEquipments = (typeof workoutEquipments)[number];

export type Gender = "male" | "female" | "else";

export type YearsOfExperience =
  | "one_to_two"
  | "three_to_five"
  | "six_to_ten"
  | "ten_over";

export type TicketStatus = "active" | "suspend" | "expired";

export type ScheduleType = "PT" | "ETC";
export type RepeatType = "daily" | "weekly" | "monthly";

export const ticketStatusInKR = (status: TicketStatus) => {
  if (status == "active") {
    return "활성";
  } else if (status == "suspend") {
    return "임박";
  } else {
    return "만료";
  }
};

export const reportTypeInKR = (type: ReportType) => {
  if (type == "feedback_only") {
    return "간단 일지";
  } else if (type == "feedback_workout") {
    return "기본 일지";
  } else return "상세 일지";
};

export const workoutPartsInKR = (parts: WorkoutParts) => {
  if (parts == "lower_body") {
    return "하체";
  } else if (parts == "chest") {
    return "가슴";
  } else if (parts == "back") {
    return "등";
  } else if (parts == "shoulders") {
    return "어깨";
  } else if (parts == "arms") {
    return "팔";
  } else if (parts == "abs") {
    return "복근";
  } else if (parts == "etc") {
    return "기타";
  } else {
    return "커스텀";
  }
};

export const workoutIntensityInKR = (intensity: WorkoutIntensity) => {
  if (intensity == "very_easy") {
    return "아주 쉬웠어요";
  } else if (intensity == "very_hard") {
    return "아주 힘들었어요";
  } else if (intensity == "hard") {
    return "힘들었어요";
  } else if (intensity == "easy") {
    return "쉬웠어요";
  } else {
    return "적당했어요";
  }
};

export const workoutEquipmentsInKR = (parts: WorkoutEquipments) => {
  if (parts == "bodyweight") {
    return "맨몸";
  } else if (parts == "bodyweight_weight") {
    return "맨몸중량";
  } else if (parts == "cardio") {
    return "유산소";
  } else if (parts == "dumbbell") {
    return "덤벨";
  } else if (parts == "barbell") {
    return "바벨";
  } else if (parts == "smith_machine") {
    return "스미스머신";
  } else if (parts == "machine") {
    return "머신";
  } else if (parts == "cable") {
    return "케이블";
  } else if (parts == "kettlebell") {
    return "케틀벨";
  }
};
