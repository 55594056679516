import { customFetch } from "../../libs/fetch/fetch";
import {
  GetTraineesResponse,
  GetTrainersLessonListLessonIdRequest,
  GetTrainersLessonsLessonIdResponse,
  GetTrainersLessonsListResponse,
  GetTrainersResponse,
  GetTrainersTrainerIdResponse,
} from "./adminTypes";
import { ListWrapperWorkoutDetailAndDateDTO } from "../trainer/users/usersTypes";

export async function getTrainersLessonList(): Promise<GetTrainersLessonsListResponse> {
  return await customFetch({
    app: "admin",
    version: "v1",
    path: `/trainers/lessons/list`,
  });
}

export async function getTrainersLessonListLessonId(
  lessonId: number,
): Promise<GetTrainersLessonsLessonIdResponse> {
  return await customFetch({
    app: "admin",
    version: "v1",
    path: `/trainers/lessons/${lessonId}`,
  });
}

export async function getTrainersTrainerIdUsersUserIdWorkoutsWorkoutId({
  trainerId,
  userId,
  workoutId,
}: GetTrainersLessonListLessonIdRequest): Promise<ListWrapperWorkoutDetailAndDateDTO> {
  return await customFetch({
    app: "admin",
    version: "v1",
    path: `/trainers/${trainerId}/users/${userId}/workouts/${workoutId}`,
  });
}

export async function getTrainees(): Promise<GetTraineesResponse> {
  return await customFetch({
    app: "admin",
    version: "v1",
    path: `/trainees`,
  });
}

export async function getTrainers(): Promise<GetTrainersResponse> {
  return await customFetch({
    app: "admin",
    version: "v1",
    path: `/trainers`,
  });
}

export async function getTrainersTrainerId(
  trainerId: number,
): Promise<GetTrainersTrainerIdResponse> {
  return await customFetch({
    app: "admin",
    version: "v1",
    path: `/trainers/${trainerId}`,
  });
}
