import * as Dialog from "design-system/components/Dialog/Dialog";
import Button from "design-system/components/Button/Button";
import React from "react";
import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";
import { colors } from "design-system/styles/colors";
import infoImage from "design-system/components/atom/IconResource/info.png";

interface DialogPopupProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  title: string;
  body?: string;
  onCloseAction?: () => void;
}

export function DialogPopup({
  isOpen,
  setIsOpen,
  title,
  body,
  onCloseAction,
}: DialogPopupProps) {
  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Content css={errorPopupCSS}>
        <div css={errorBodyCSS}>
          <img src={infoImage} css={infoImageCSS} />
          <div css={errorContentContainerCSS}>
            <div css={errorTitleCSS}>{title}</div>
            {body && <span css={errorSubTitleCSS}>{body}</span>}
          </div>
        </div>
        <Button
          variant={"primary"}
          size={46}
          css={errorButtonCSS}
          onClick={() => {
            setIsOpen(false);
            onCloseAction?.();
          }}
        >
          확인
        </Button>
      </Dialog.Content>
    </Dialog.Root>
  );
}

const infoImageCSS = css`
  height: 40px;
  width: 40px;
  object-fit: contain;
`;

const errorTitleCSS = css`
  ${typography.mobile.heading3}
`;

const errorSubTitleCSS = css`
  color: ${colors.gray600};
  ${typography.mobile.body1};
  text-align: center;
`;

const errorContentContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const errorBodyCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const errorButtonCSS = css`
  margin-top: auto;
`;

const errorPopupCSS = css`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 450px;
  gap: 30px;
  padding: 30px;
  padding-top: 40px;
`;
