import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useMemo } from "react";
import workoutListContext from "crm/workout-list/workoutListContext";
import { queryClient, queryKeys } from "libs/react-query";
import { getMe } from "api/trainer/me/me";
import {
  deleteWorkoutsWorkoutIdBookmarks,
  getWorkoutsWorkoutId,
  postWorkoutsWorkoutIdBookmarks,
} from "api/trainer/workouts/workouts";
import { workoutPartsInKR } from "api/common/commonEnumType";

import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import customImage from "design-system/components/atom/IconResource/customImage.svg";
import bookmarkIcon from "design-system/components/atom/IconResource/ic_bookmark_vari.svg";
import bookmarkBlackIcon from "design-system/components/atom/IconResource/ic_bookmark_vari_black.svg";
import editIcon from "design-system/components/atom/IconResource/ic_edit-02.svg";

import Button from "design-system/components/Button/Button";
import * as Select from "design-system/components/Select/Select";
import { getUsers } from "api/trainer/users/users";
import { CustomWorkoutUpdateDialog } from "../CustomWorkoutUpdateDialog/CustomWorkoutUpdateDialog";
import { CustomWorkoutDescriptionDialog } from "../CustomWorkoutDescriptionDialog/CustomWorkoutDescriptionDialog";

export function WorkoutDetailSidebar() {
  const {
    workoutId = 0,
    userId = 0,
    setUserId,
  } = useContext(workoutListContext);

  const { data: trainer } = useQuery({
    queryFn: () => getMe(),
    queryKey: queryKeys.getMe(),
  });

  const { data: users } = useQuery({
    queryFn: () => getUsers(),
    queryKey: queryKeys.getUsers(),
  });

  const { data: workout } = useQuery({
    queryFn: () => getWorkoutsWorkoutId({ workoutId }),
    queryKey: queryKeys.getWorkoutsWorkoutId({ workoutId }),
    enabled: !!workoutId,
  });

  const { mutate: addBookmark, isLoading: isAddBookmarkLoading } = useMutation({
    mutationFn: postWorkoutsWorkoutIdBookmarks,
    onSuccess: ({}) => {
      queryClient.invalidateQueries(queryKeys.getWorkouts());
      queryClient.invalidateQueries(
        queryKeys.getWorkoutsWorkoutId({ workoutId }),
      );
    },
  });

  const { mutate: removeBookmark, isLoading: isRemoveBookmarkLoading } =
    useMutation({
      mutationFn: deleteWorkoutsWorkoutIdBookmarks,
      onSuccess: ({}) => {
        queryClient.invalidateQueries(queryKeys.getWorkouts());
        queryClient.invalidateQueries(
          queryKeys.getWorkoutsWorkoutId({ workoutId }),
        );
      },
    });

  const descriptions = useMemo(() => {
    const specificDescriptions = workout?.traineeSpecificDescriptions.find(
      ({ userInfo }) => userInfo.userId === userId,
    )?.descriptions;
    if (!!specificDescriptions?.length) {
      return specificDescriptions;
    }
    if (!!workout?.trainerDefaultDescriptions.length) {
      return workout.trainerDefaultDescriptions;
    }
    if (!!workout?.defaultDescriptions.length) {
      return workout.defaultDescriptions;
    }
  }, [userId, workout]);

  return (
    <div css={containerCSS}>
      {workout && (
        <div css={contentContainerCSS}>
          <div css={headerCSS}>
            <div css={headerUpperContainerCSS}>
              <div css={partBadgeCSS}>
                {workoutPartsInKR(workout.workout.part)}
              </div>
              {workout.workout.isBookmarked ? (
                <Button
                  variant="icon"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeBookmark(workout.workout.id);
                  }}
                  disabled={isRemoveBookmarkLoading}
                >
                  <img src={bookmarkBlackIcon} css={bookmarkIconCSS} />
                </Button>
              ) : (
                <Button
                  variant="icon"
                  onClick={(event) => {
                    event.stopPropagation();
                    addBookmark(workout.workout.id);
                  }}
                  disabled={isAddBookmarkLoading}
                >
                  <img src={bookmarkIcon} css={bookmarkIconCSS} />
                </Button>
              )}
            </div>
            <div css={workoutNameTextCSS}>{workout?.workout.name}</div>
          </div>
          <img
            src={workout.workout.thumbnail ?? customImage}
            css={thumnailImageCSS}
          />
          <div css={workoutButtonContainerCSS}>
            <CustomWorkoutDescriptionDialog
              trigger={
                <Button
                  size={"auto"}
                  styles={descriptionAddButtonCSS}
                  onClick={() => setUserId?.(0)}
                >
                  {"운동법 추가하기"}
                </Button>
              }
            />
            {workout.workout.isCustom && <CustomWorkoutUpdateDialog />}
          </div>
          <div css={descriptionContainerCSS}>
            <div
              css={descriptionTitleCSS}
            >{`${trainer?.name} 트레이너님의 운동법`}</div>
            {descriptions ? (
              <>
                <div css={useDropdownContainerCSS}>
                  <Select.Root
                    size="small"
                    value={userId.toString()}
                    onValueChange={(value) => setUserId?.(Number(value))}
                  >
                    <Select.Trigger>
                      <Select.Value placeholder="공통" />
                    </Select.Trigger>
                    <Select.Content>
                      {[
                        { id: 0, name: "공통" },
                        ...(users?.content.map(({ userInfo }) => {
                          return { id: userInfo.userId, name: userInfo.name };
                        }) ?? []),
                      ].map((user) => (
                        <Select.Item value={user.id.toString()} key={user.id}>
                          {user.name}
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select.Root>
                  <CustomWorkoutDescriptionDialog
                    trigger={
                      <Button styles={editButtonCSS}>
                        <img src={editIcon} css={editIconCSS} />
                      </Button>
                    }
                  />
                </div>
                <ol css={descriptionListCSS}>
                  {descriptions.map((description, i) => (
                    <li key={i}>{description}</li>
                  ))}
                </ol>
              </>
            ) : (
              <div css={descriptionPlaceholderCSS}>
                {`${trainer?.name} 트레이너님만의 운동법을 작성해주세요`}
              </div>
            )}

            <div></div>
          </div>
        </div>
      )}
    </div>
  );
}

const containerCSS = css`
  width: 400px;
  flex-shrink: 0;
  background: ${colors.gray25};
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
`;

const contentContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  padding: 30px 15px;
`;

const headerCSS = css`
  width: 100%;
  border-radius: 8px;
  background: ${colors.lightGray};
  padding: 10px;
  gap: 4px;
  display: flex;
  flex-direction: column;
`;

const headerUpperContainerCSS = css`
  display: flex;
  justify-content: space-between;
`;

const partBadgeCSS = css`
  padding: 4px 6px;
  border-radius: 2px;
  color: ${colors.gray25};
  background-color: ${colors.black};
  ${typography.mobile.body2};
`;

const bookmarkIconCSS = css`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;

const workoutNameTextCSS = css`
  ${typography.mobile.heading2}
`;

const thumnailImageCSS = css`
  width: 100%;
  aspect-ratio: 1;
`;

const workoutButtonContainerCSS = css`
  display: flex;
  width: 100%;
  gap: 10px;
`;

const descriptionAddButtonCSS = css`
  color: ${colors.White};
  background-color: ${colors.black};
  ${typography.Button1};
`;

const descriptionContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const descriptionTitleCSS = css`
  ${typography.mobile.heading3};
`;

const useDropdownContainerCSS = css`
  display: flex;
  gap: 3px;
`;

const editButtonCSS = css`
  color: ${colors.black};
  background-color: ${colors.gray50};
`;

const editIconCSS = css`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;

const descriptionListCSS = css`
  list-style-type: decimal;
  list-style-position: inside;
  color: ${colors.gray600};
  ${typography.mobile.body2};
`;

const descriptionPlaceholderCSS = css`
  width: 100%;
  border-radius: 8px;
  padding: 12px;
  color: ${colors.black};
  background-color: ${colors.gray50};
  ${typography.mobile.body2};
`;
