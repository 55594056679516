import { useContext, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import CreateContext from "../../createContext";
import DirectCreateContext from "./directCreateContext";
import { format } from "date-fns";

import { getUsers } from "../../../../../api/trainer/users/users";
import { UserInfoOfTrainerDTO } from "../../../../../api/trainer/users/usersTypes";

import Button from "design-system/components/Button/Button";
import * as Dialog from "design-system/components/Dialog/Dialog";
import { FitsYouDatePicker } from "design-system/components/FitsYouDatePicker/FitsYouDatePicker";
import SelectSearchBar from "react-select";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import {
  fontWeight,
  typography,
} from "design-system/styles/typography/typography";
import leftArrowIcon from "design-system/components/atom/IconResource/ic_arrow_left.svg";
import rightArrowIcon from "design-system/components/atom/IconResource/ic_arrow_right.svg";
import cardIcon from "design-system/components/atom/IconResource/ic_card.svg";
import calendarIcon from "design-system/components/atom/IconResource/ic_calender.svg";
import infoIcon from "design-system/components/atom/IconResource/info.png";
import { queryKeys } from "libs/react-query";
import { postSchedulesCheck } from "api/trainer/schedules/schedules";
import { CustomFetchError } from "libs/fetch/fetch";
import { DialogPopup } from "design-system/components/DialogPopup/DialogPopup";

export function ScheduleAddPopup() {
  const today = new Date();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const { scheduleDataContextProps } = useContext(DirectCreateContext);
  const { setData, data } = useContext(CreateContext);
  const {
    userId: userIdContextData,
    setUserId,
    ticketId: ticketIdContextData,
    setTicketId,
    startAt = today,
    setStartAt,
    endAt = today,
    setEndAt,
  } = scheduleDataContextProps;

  const { data: users } = useQuery({
    queryFn: getUsers,
    queryKey: queryKeys.getUsers(),
  });

  const { setIsScheduleDialogOpen, isScheduleDialogOpen } =
    useContext(DirectCreateContext);

  const CustomInput = (props: React.HTMLProps<HTMLInputElement>) => {
    return (
      <div css={datePickerInputContainerCSS}>
        <img src={calendarIcon} alt={"calendar"} css={calendarIconCSS} />
        <input css={datePickerInputCSS} {...props} />
      </div>
    );
  };

  const [searchUser, setSearchUser] = useState<
    UserInfoOfTrainerDTO | null | undefined
  >(
    users?.content.find(
      ({ userInfo }) => userInfo.userId === userIdContextData,
    ),
  );
  const [selectedDate, setSelectedDate] = useState<Date | null>(today);
  const [selectedTicketIdx, setSelectedTicketIdx] = useState(
    users?.content
      .find(({ userInfo }) => userInfo.userId === userIdContextData)
      ?.tickets.findIndex(({ id }) => id === ticketIdContextData) ?? 0,
  );

  const { mutate: checkScheduleTime } = useMutation({
    mutationFn: postSchedulesCheck,
    onSuccess: () => {
      if (data && userIdContextData && ticketIdContextData) {
        setStartAt?.(
          new Date(
            `${format(selectedDate ?? "", "yyyy-MM-dd")}T${format(startAt ?? "", "HH:mm")}`,
          ),
        );
        setEndAt?.(
          new Date(
            `${format(selectedDate ?? "", "yyyy-MM-dd")}T${format(endAt ?? "", "HH:mm")}`,
          ),
        );
        setData?.({
          ...data,
          userId: userIdContextData,
          ticketId: ticketIdContextData,
        });
        setIsScheduleDialogOpen?.(false);
      }
    },
    onError: (error: CustomFetchError) => {
      if (error.data?.code === 409102) {
        setIsErrorDialogOpen(true);
      }
    },
    useErrorBoundary: (error: CustomFetchError) => error.status !== 409,
  });

  return (
    <>
      <Dialog.Root variant="page" open={isScheduleDialogOpen}>
        <Dialog.Content styles={dialogContentCSS}>
          <Dialog.Header>
            <Dialog.Title>{"PT 일정 추가"}</Dialog.Title>
          </Dialog.Header>
          <Dialog.Body styles={dialogBodyCSS}>
            <div css={infoContainerCSS}>
              <span css={infoTitleCSS}>
                회원명<span style={{ color: `${colors.orange}` }}>*</span>
              </span>
              <SelectSearchBar
                className="select-search-bar"
                classNamePrefix="select"
                isSearchable
                placeholder="회원이름을 기입해주세요"
                value={searchUser}
                onChange={(user) => {
                  setSearchUser(user);
                  setUserId?.(user?.userInfo.userId ?? 0);
                  setSelectedTicketIdx(0);
                  setTicketId?.(user?.tickets[0].id ?? 0);
                }}
                options={users?.content}
                getOptionLabel={(option) =>
                  `${option.userInfo.name} (${option.userInfo.phone})`
                }
                getOptionValue={(option) => option.userInfo.userId.toString()}
              />
            </div>
            <div css={infoContainerCSS}>
              <span css={infoTitleCSS}>
                수강권 선택
                <span style={{ color: `${colors.orange}` }}>*</span>
              </span>
              <div css={traineeTicketBoxContainerCSS}>
                {searchUser?.tickets?.length ? (
                  <>
                    {selectedTicketIdx > 0 && (
                      <Button
                        variant="icon"
                        onClick={() => {
                          setTicketId?.(
                            searchUser.tickets[selectedTicketIdx - 1].id,
                          );
                          setSelectedTicketIdx(selectedTicketIdx - 1);
                        }}
                      >
                        <div css={traineeTicketBoxLeftArrowContainerCSS}>
                          <img
                            src={leftArrowIcon}
                            alt={"left arrow"}
                            css={traineeTicketBoxArrowIconCSS}
                          />
                        </div>
                      </Button>
                    )}
                    <div
                      css={traineeTicketCarouselCSS}
                      style={{ translate: `-${410 * selectedTicketIdx}px` }}
                    >
                      {searchUser?.tickets.map((ticket) => (
                        <div css={traineeTicketBoxCSS}>
                          <div css={traineeTicketCSS}>
                            <div css={traineeTicketActivityBadgeCSS}>
                              {ticket.status === "active" ? "활성" : "만료"}
                            </div>
                            <div css={traineeTickeDescContainerCSS}>
                              <span css={traineeTicketDescCountTextCSS}>
                                {`${ticket.usedCount}/${ticket.totalCount}회`}
                              </span>
                              <span css={traineeTicketDescDateTextCSS}>
                                {`${ticket.startAt} ~ ${ticket.endAt || "종료 제한 없음"}`}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {selectedTicketIdx < searchUser?.tickets.length - 1 && (
                      <Button
                        variant="icon"
                        onClick={() => {
                          setTicketId?.(
                            searchUser.tickets[selectedTicketIdx + 1].id,
                          );
                          setSelectedTicketIdx(selectedTicketIdx + 1);
                        }}
                      >
                        <div css={traineeTicketBoxRightArrowContainerCSS}>
                          <img
                            src={rightArrowIcon}
                            alt={"right arrow"}
                            css={traineeTicketBoxArrowIconCSS}
                          />
                        </div>
                      </Button>
                    )}
                  </>
                ) : (
                  <div css={traineeTicketPlaceHolderBoxCSS}>
                    <div css={traineeTicketIconContainerCSS}>
                      <img
                        src={cardIcon}
                        alt={"card icon"}
                        css={traineeTicketIconCSS}
                      />
                    </div>
                    <span css={traineeTicketPlaceHolderDescCSS}>
                      회원을 먼저 선택해주세요
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div css={infoContainerCSS}>
              <span css={infoTitleCSS}>
                날짜
                <span style={{ color: `${colors.orange}` }}>*</span>
              </span>
              <FitsYouDatePicker
                isCalendarOpen={isCalendarOpen}
                setIsCalendarOpen={setIsCalendarOpen}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                onChange={setSelectedDate}
                customInput={<CustomInput />}
                onSelect={() => setIsCalendarOpen(false)}
              />
            </div>
            <div css={rowInfoContainerCSS}>
              <div css={infoContainerCSS}>
                <span css={infoTitleCSS}>
                  시작 시간
                  <span style={{ color: `${colors.orange}` }}>*</span>
                </span>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimeField
                    value={startAt}
                    onChange={(date) => date && setStartAt?.(date)}
                  />
                </LocalizationProvider>
              </div>
              <div css={infoContainerCSS}>
                <span css={infoTitleCSS}>
                  종료 시간
                  <span style={{ color: `${colors.orange}` }}>*</span>
                </span>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimeField
                    value={endAt}
                    onChange={(date) => date && setEndAt?.(date)}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </Dialog.Body>
          <Dialog.Footer>
            <Dialog.Close
              variant="primary"
              disabled={
                !selectedDate ||
                !startAt ||
                !endAt ||
                !searchUser ||
                !searchUser.tickets.length ||
                new Date(startAt) >= new Date(endAt)
              }
              onClick={() =>
                checkScheduleTime({
                  startAt: `${format(selectedDate ?? "", "yyyy-MM-dd")}T${format(startAt ?? "", "HH:mm")}`,
                  endAt: `${format(selectedDate ?? "", "yyyy-MM-dd")}T${format(endAt ?? "", "HH:mm")}`,
                  scheduleType: "PT",
                })
              }
            >
              {"다음"}
            </Dialog.Close>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>
      <Dialog.Root variant="page" open={isErrorDialogOpen} overlay={1}>
        <Dialog.Content>
          <Dialog.Header styles={errorDialogHeaderCSS}>
            <img src={infoIcon} css={errorDialogInfoIconCSS} />
            <div>
              <Dialog.Title>{"기존 스케줄과 겹치는 시간이에요"}</Dialog.Title>
              <Dialog.Description>
                {"날짜 및 시간을 다시 확인해주세요"}
              </Dialog.Description>
            </div>
          </Dialog.Header>
          <Dialog.Footer>
            <Dialog.Close
              variant="primary"
              onClick={() => setIsErrorDialogOpen(false)}
            >
              {"확인"}
            </Dialog.Close>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}

const calendarIconCSS = css`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: ${colors.black};
`;

const datePickerInputContainerCSS = css`
  display: flex;
  width: 100%;
  height: 46px;
  padding: 11px 10px;
  align-items: center;
  align-self: stretch;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid ${colors.default.Gray600};
  background: ${colors.gray25};

  .react-datepicker__input-container {
    width: 100%;
  }
`;

const datePickerInputCSS = css`
  color: ${colors.gray900};
  ${typography.mobile.body2};
  border: none;
`;

const dialogContentCSS = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const dialogBodyCSS = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
`;

const infoContainerCSS = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container > div > input {
    cursor: pointer;
  }

  .select-search-bar {
    width: 100%;
    color: ${colors.gray900};
    ${typography.mobile.body2};
  }

  .select__value-container {
    height: 100%;
  }

  .select__indicators {
    display: none;
  }

  .select__control {
    display: flex;
    height: 46px;
    align-items: center;
    align-self: stretch;
    gap: 2px;
    border-radius: 8px;
    border: 1px solid ${colors.default.Gray600};
    background: ${colors.gray25};
  }

  .select__value-container {
    height: 46px;
    padding: 0 10px;
  }

  .select__menu {
    display: flex;
    /* padding: 1px 12px; */
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    border-radius: 10px;
    border: 1px solid ${colors.gray100};
    background: ${colors.gray25};
    box-shadow: 0px 5px 10px 0px rgba(16, 16, 16, 0.2);
    margin-top: 2px;
    margin-bottom: 0;
  }

  .select__menu-list {
    width: 100%;
    padding: 0;
    border-radius: 10px;
  }

  .select__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: ${colors.gray25};
    color: ${colors.gray900};

    :hover {
      background-color: ${colors.gray100};
    }
  }

  .select__option--is-selected {
    background-color: ${colors.gray100};
    font-weight: 600;
  }
`;

const infoTitleCSS = css`
  align-self: stretch;
  color: ${colors.gray900};
  ${typography.mobile.body2};
  font-weight: ${fontWeight.SemiBold};
`;

const rowInfoContainerCSS = css`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiOutlinedInput-root {
    display: flex;
    height: 46px;
    padding: 12px 10px;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid ${colors.gray100};
    background: ${colors.gray25};
    ${typography.mobile.body2}
  }

  .MuiOutlinedInput-input {
    padding: 0;
  }
`;

const traineeTicketBoxContainerCSS = css`
  position: relative;
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
`;

const traineeTicketBoxLeftArrowContainerCSS = css`
  position: absolute;
  display: flex;
  z-index: 1;
  left: -20px;
  width: 20px;
  height: 20px;
  padding: 4.5px 4px 3.5px 4px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  background: ${colors.White};
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.05);
`;

const traineeTicketBoxRightArrowContainerCSS = css`
  position: absolute;
  display: flex;
  z-index: 1;
  right: -24px;
  width: 20px;
  height: 20px;
  padding: 4.5px 4px 3.5px 4px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  background: ${colors.White};
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.05);
`;

const traineeTicketBoxArrowIconCSS = css`
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  color: ${colors.black};
`;

const traineeTicketCarouselCSS = css`
  position: absolute;
  display: flex;
  gap: 8px;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
  transition: 0.3s ease-in-out;
`;

const traineeTicketBoxCSS = css`
  display: flex;
  width: 402px;
  height: 80px;
  padding: 10.755px 15.364px;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(302deg, #b5f42e -23.35%, #e9ff6e 57.13%), #fff;
  border-radius: 8px;
  // TODO: 그림자 css 적용 @sello
  /* box-shadow: 0px 0px 7.682px 3.073px rgba(0, 0, 0, 0.05); */
`;

const traineeTicketCSS = css`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const traineeTicketActivityBadgeCSS = css`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  padding: 3.073px 4.609px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: ${colors.black};
  color: ${colors.gray25};
  ${typography.mobile.body2};
`;

const traineeTickeDescContainerCSS = css`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;

const traineeTicketDescCountTextCSS = css`
  color: ${colors.black};
  ${typography.mobile.body1};
`;

const traineeTicketDescDateTextCSS = css`
  color: ${colors.black};
  ${typography.mobile.body3};
`;

const traineeTicketPlaceHolderBoxCSS = css`
  display: flex;
  width: 402px;
  height: 80px;
  padding: 14px 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 8px;
  background: ${colors.gray50};
`;

const traineeTicketIconContainerCSS = css`
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${colors.gray100};
  align-items: center;
  justify-content: center;
`;

const traineeTicketIconCSS = css`
  width: 18.286px;
  height: 18.286px;
  flex-shrink: 0;
  filter: invert(66%) sepia(7%) saturate(0%) hue-rotate(149deg) brightness(91%)
    contrast(88%);
`;

const traineeTicketPlaceHolderDescCSS = css`
  color: ${colors.gray400};
  ${typography.mobile.body3};
`;

const errorDialogHeaderCSS = css`
  align-items: center;
  gap: 16px;
`;

const errorDialogInfoIconCSS = css`
  height: 40px;
  width: 40px;
  object-fit: contain;
`;
