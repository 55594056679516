import { WorkoutEquipments, WorkoutParts } from "api/common/commonEnumType";
import { createContext, Dispatch, SetStateAction } from "react";

interface WorkoutListContext {
  workoutId?: number;
  setWorkoutId: Dispatch<SetStateAction<number | undefined>>;
  userId?: number;
  setUserId?: Dispatch<SetStateAction<number | undefined>>;
  workoutName?: string;
  setWorkoutName: Dispatch<SetStateAction<string | undefined>>;
  isBookmarked?: boolean;
  setIsBookmarked: Dispatch<SetStateAction<boolean>>;
  isDescriptionRequired?: boolean;
  setIsDescriptionRequired: Dispatch<SetStateAction<boolean>>;
  isCustom?: boolean;
  setIsCustom: Dispatch<SetStateAction<boolean>>;
  workoutPart: WorkoutParts | "total";
  setWorkoutPart: Dispatch<SetStateAction<WorkoutParts | "total">>;
  workoutEquipment: WorkoutEquipments | "total";
  setWorkoutEquipment: Dispatch<SetStateAction<WorkoutEquipments | "total">>;
}

export default createContext<WorkoutListContext>({
  workoutId: undefined,
  setWorkoutId: () => {},
  userId: undefined,
  setUserId: () => {},
  workoutName: undefined,
  setWorkoutName: () => {},
  isBookmarked: undefined,
  setIsBookmarked: () => {},
  isDescriptionRequired: undefined,
  setIsDescriptionRequired: () => {},
  isCustom: undefined,
  setIsCustom: () => {},
  workoutPart: "total",
  setWorkoutPart: () => {},
  workoutEquipment: "total",
  setWorkoutEquipment: () => {},
});
