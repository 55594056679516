import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient, queryKeys } from "libs/react-query";
import {
  getWorkoutsWorkoutId,
  postWorkoutsWorkoutIdDescriptions,
  putWorkoutsWorkoutIdDescriptions,
} from "api/trainer/workouts/workouts";
import workoutListContext from "crm/workout-list/workoutListContext";

import * as Dialog from "design-system/components/Dialog/Dialog";
import * as RadioGroup from "design-system/components/RadioGroup/RadioGroup";
import * as Select from "design-system/components/Select/Select";
import { Textarea } from "design-system/components/Textarea/Textarea";
import Button from "design-system/components/Button/Button";
import IcDelete from "design-system/components/atom/IconResource/Icon/IcDelete";
import IcPlus from "design-system/components/atom/IconResource/Icon/IcPlus";

import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";
import { colors } from "design-system/styles/colors";
import { getUsers } from "api/trainer/users/users";

interface CustomWorkoutDescriptionDialogProps {
  trigger: ReactNode;
}

type DescriptionType = "common" | "specific";

export function CustomWorkoutDescriptionDialog({
  trigger,
}: CustomWorkoutDescriptionDialogProps) {
  const { workoutId = 0, userId, setUserId } = useContext(workoutListContext);
  const [descriptionList, setDescriptionList] = useState<string[]>([""]);
  const [descriptionType, setDescriptionType] = useState<DescriptionType>();

  const { data: workout } = useQuery({
    queryFn: () => getWorkoutsWorkoutId({ workoutId }),
    queryKey: queryKeys.getWorkoutsWorkoutId({ workoutId }),
    enabled: !!workoutId,
  });

  const { data: users } = useQuery({
    queryFn: () => getUsers(),
    queryKey: queryKeys.getUsers(),
  });

  const { mutate: createWorkoutDescription } = useMutation({
    mutationFn: postWorkoutsWorkoutIdDescriptions,
    onSuccess: () => {
      queryClient.invalidateQueries(
        queryKeys.getWorkoutsWorkoutId({ workoutId }),
      );
    },
  });

  const { mutate: updateWorkoutDescription } = useMutation({
    mutationFn: putWorkoutsWorkoutIdDescriptions,
    onSuccess: () => {
      queryClient.invalidateQueries(
        queryKeys.getWorkoutsWorkoutId({ workoutId }),
      );
    },
  });

  const originDescriptionList = useMemo(() => {
    return descriptionType === "specific"
      ? workout?.traineeSpecificDescriptions.find(
          (item) => item.userInfo.userId === userId,
        )?.descriptions
      : workout?.trainerDefaultDescriptions;
  }, [userId, descriptionType, workout]);

  const selectedUserDescriptionList = useMemo(() => {
    const defaultDesc = workout?.trainerDefaultDescriptions.length
      ? workout?.trainerDefaultDescriptions
      : [""];
    const specificDesc = workout?.traineeSpecificDescriptions.find(
      (item) => item.userInfo.userId === userId,
    )?.descriptions;

    return descriptionType === "specific" && specificDesc?.length
      ? specificDesc
      : defaultDesc;
  }, [userId, descriptionType, workout]);

  const steps = useMemo(() => {
    return descriptionList.filter((desc) => !!desc);
  }, [descriptionList]);

  const saveWorkoutDescription = () => {
    originDescriptionList?.some((desc) => !!desc)
      ? descriptionType === "common"
        ? updateWorkoutDescription({ workoutId, steps })
        : updateWorkoutDescription({ workoutId, userId, steps })
      : descriptionType === "common"
        ? createWorkoutDescription({ workoutId, steps })
        : createWorkoutDescription({ workoutId, userId, steps });
  };

  const reset = () => {
    setDescriptionList(selectedUserDescriptionList);
  };

  useEffect(() => {
    setDescriptionType(!!userId ? "specific" : "common");
  }, [userId]);

  useEffect(reset, [userId, descriptionType]);

  return (
    <Dialog.Root variant="page">
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <Dialog.Content styles={dialogContentCSS} onAnimationStartCapture={reset}>
        <Dialog.Header styles={dialogHeaderCSS}>
          <Dialog.Title styles={dialogTitleCSS}>
            {`${workout?.workout.name} 운동법 수정`}
          </Dialog.Title>
          <div css={dialogHeaderContentCSS}>
            <div>{"운동법 선택"}</div>
            <RadioGroup.Root
              value={descriptionType}
              onValueChange={(value: DescriptionType) => {
                setDescriptionType(value);
                !userId &&
                  !!users?.content.length &&
                  setUserId?.(users.content[0].userInfo.userId);
              }}
              styles={radioGroupRootCSS}
            >
              <RadioGroup.ItemWithLabel value="common">
                <div css={radioGroupItemLabelTitleCSS}>{"공통"}</div>
                <div css={radioGroupItemLabelDescCSS}>
                  {"모든 회원님께 제공되는 운동법이에요."}
                </div>
              </RadioGroup.ItemWithLabel>
              <RadioGroup.ItemWithLabel
                value="specific"
                disabled={!users?.content.length}
              >
                <div css={radioGroupItemLabelTitleCSS}>{"특정회원"}</div>
                <div css={radioGroupItemLabelDescCSS}>
                  {
                    "공통운동이 존재하더라도 개별회원을 위한 운동법을 제공할 수 있어요."
                  }
                </div>
              </RadioGroup.ItemWithLabel>
            </RadioGroup.Root>
          </div>
        </Dialog.Header>
        <Dialog.Body styles={dialogBodyCSS}>
          {descriptionType === "common" ? (
            <div>{"기본 운동법"}</div>
          ) : (
            <div css={dialogBodyTitleCSS}>
              <div>
                {`${users?.content.find(({ userInfo }) => userInfo.userId === userId)?.userInfo.name}님을 위한 운동법`}
              </div>
              <Select.Root
                size="small"
                value={userId?.toString()}
                onValueChange={(value) => setUserId?.(Number(value))}
              >
                <Select.Trigger>
                  <Select.Value />
                </Select.Trigger>
                <Select.Content>
                  {users?.content.map(({ userInfo }) => (
                    <Select.Item
                      value={userInfo.userId.toString()}
                      key={userInfo.userId}
                    >
                      {userInfo.name}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </div>
          )}
          <div css={workoutDescriptionContainerCSS}>
            {`${workout?.workout.name} 운동법 예시`}
            {descriptionList.map((description, index) => {
              return (
                <div css={workoutDescriptionItemContainerCSS} key={index}>
                  <div css={indexTextCSS}>{index + 1}.</div>
                  <Textarea
                    value={description}
                    onChange={(event) => {
                      setDescriptionList(
                        descriptionList.map((innerDescription, innerIndex) => {
                          if (index == innerIndex) {
                            return event.target.value;
                          } else {
                            return innerDescription;
                          }
                        }),
                      );
                    }}
                  />
                  <Button
                    variant={"icon"}
                    onClick={() => {
                      setDescriptionList(
                        descriptionList.filter(
                          (_, innerIndex) => innerIndex !== index,
                        ),
                      );
                    }}
                  >
                    <IcDelete />
                  </Button>
                </div>
              );
            })}
            <Button
              size={40}
              onClick={() => {
                setDescriptionList([...descriptionList, ""]);
              }}
            >
              <IcPlus />
            </Button>
          </div>
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.Close
            variant="primary"
            onClick={saveWorkoutDescription}
            disabled={
              JSON.stringify(selectedUserDescriptionList) ===
              JSON.stringify(steps)
            }
          >
            {"저장하기"}
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
}

const dialogContentCSS = css`
  background-color: ${colors.lightGray};
  ${typography.mobile.heading2};
`;

const dialogHeaderCSS = css`
  color: ${colors.gray25};
  background-color: ${colors.black};
`;

const dialogTitleCSS = css`
  color: ${colors.gray25};
`;

const dialogHeaderContentCSS = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px 0;
`;

const radioGroupRootCSS = css`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background-color: ${colors.gray800};
`;

const radioGroupItemLabelTitleCSS = css`
  color: ${colors.gray25};
  ${typography.mobile.heading4};
`;

const radioGroupItemLabelDescCSS = css`
  color: ${colors.gray100};
  ${typography.mobile.body2};
`;

const dialogBodyCSS = css`
  align-items: flex-start;
  padding-top: 24px;
  padding-bottom: 24px;
  gap: 26px;
`;

const dialogBodyTitleCSS = css`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const workoutDescriptionContainerCSS = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  ${typography.mobile.heading4};
`;

const workoutDescriptionItemContainerCSS = css`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const indexTextCSS = css`
  ${typography.mobile.body1};
  width: 20px;
  color: ${colors.gray600};
`;
